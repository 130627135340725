import React from "react";
import { getTextColour } from "../../utils";
import {
	Autocomplete,
	Box,
	Chip,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridContext } from "../../../../context/DataGridContext";
import { Colours } from "../../../../assets/global/Theme-variable";
import ReactHookFormMultiSelectChip from "../../../../components/ReactHookFormMultiSelectChip";

export default function NewSiteForm({ methods, gridList, table }) {
	const { setValue, control, formState } = useFormContext();
	const { data } = React.useContext(DataGridContext);
	console.log(data.priceband);
	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						// <Autocomplete
						// 	key={`autocomplete_`}
						// 	options={data?.priceband}
						// 	multiple
						// 	value={
						// 		// console.log(value)
						// 		// data.pricebanda.find(
						// 		// 	(i) =>
						// 		// 		parseInt(i.col) === parseInt(layout?.cols) &&
						// 		// 		parseInt(i.row) === parseInt(layout?.rows)
						// 		// )
						// 		value.map((val) =>
						// 			data.priceband.find(
						// 				(i) => parseInt(i.priceband_number) === parseInt(val)
						// 			)
						// 		)
						// 		// value
						// 		// console.log(value)
						// 	}
						// 	// defaultValue={mainlayouts[0]}
						// 	sx={{ width: "100%" }}
						// 	size="small"
						// 	getOptionLabel={(o) => o?.priceband_name}
						// 	// onChange={(e, newValue) => {
						// 	// 	setValue("list_details[cols]", newValue.col);
						// 	// 	setValue("list_details[rows]", newValue.row);
						// 	// }}
						// 	renderTags={(value, getTagProps) =>
						// 		value?.map((option, index) => {
						// 			console.log(option);
						// 			return (
						// 				<Chip
						// 					variant="filled"
						// 					label={option?.priceband_name}
						// 					size="medium"
						// 					{...getTagProps({ index })}
						// 					deleteIcon={<CloseIcon size={10} />}
						// 					sx={{
						// 						borderRadius: "6px",
						// 						backgroundColor: Colours.gsblue,
						// 						padding: "2px 4px 2px 8px",
						// 						color: "white",
						// 						borderColor: Colours.gsblue,
						// 						// height: "24px",
						// 						// margin: "2px",
						// 						// lineHeight: "22px",
						// 						fontSize: 10,

						// 						"& svg": {
						// 							fontSize: "14px",
						// 							cursor: "pointer",
						// 							// padding: "4px",
						// 							color: "white !important",
						// 						},
						// 						"& .MuiChip-deleteIcon": {
						// 							fontSize: "12px",
						// 						},
						// 					}}
						// 				/>
						// 			);
						// 		})
						// 	}
						// 	onChange={(event, newValue) => {
						// 		console.log(newValue);
						// 		onChange(newValue);
						// 		// setValue(newValue.map((v) => v.priceband_number));
						// 	}}
						// 	isOptionEqualToValue={(option, value) =>
						// 		parseInt(option?.priceband_number) ===
						// 		parseInt(value?.priceband_number)
						// 	}
						// 	renderInput={(params) => (
						// 		<TextField
						// 			// required
						// 			// error={fieldError[item.masterField + "." + item.dataField2]}
						// 			// helperText={
						// 			// 	fieldHelperText[item.masterField + "." + item.dataField2]
						// 			// }

						// 			sx={{ width: "100%", marginTop: 1 }}
						// 			{...params}
						// 			label={"Price Bands"}
						// 			variant={"outlined"}
						// 			InputLabelProps={{
						// 				shrink: true,
						// 			}}
						// 		/>
						// 	)}
						// />
						<ReactHookFormMultiSelectChip
							id="userType_role"
							name="site_details[pricebands]"
							label="Pricebands"
							control={control}
							defaultValue={""}
							selectoptions={data?.priceband?.filter(
								(i) => i?.priceband_isDeleted === 0
							)}
							fieldValueToSave="priceband_ref"
						/>
						// <TextField
						// 	id="category-name"
						// 	label="Name"
						// 	value={value}
						// 	name={name}
						// 	onChange={onChange}
						// 	size="small"
						// 	sx={{ width: "100%" }}
						// 	InputLabelProps={{
						// 		shrink: true,
						// 	}}
						// />
						// <Autocomplete
						// 	// this code maps the content of data rows and pulls back key/value pair from data grid config file
						// 	// you can use this code with any array object as long as the key value exists in all array items
						// 	// this can also be swapped out for an API response (more work needed when available to stop using dummydata)
						// 	key={`autocomplete_${item.field}`}
						// 	options={item.dataRows.map((list) => list[item.dataField])}
						// 	sx={{ width: "100%" }}
						// 	size="medium"
						// 	// defaultValue={
						// 	// 	modalConfig.editRow === -1
						// 	// 		? item.defaultvalue
						// 	// 		: dataGridValues[item.field]
						// 	// }
						// 	freeSolo
						// 	renderInput={(params) => (
						// 		<TextField
						// 			required
						// 			{...params}
						// 			label={item.headerName}
						// 			variant={"outlined"}
						// 			InputLabelProps={{
						// 				shrink: true,
						// 			}}
						// 			{...register(item.field)}
						// 			disabled={!item.editable}
						// 		/>
						// 	)}
						// />
					)}
					// name="plu_name"
					name={`site_details.pricebands`}
					// defaultValue=""
					// rules={{
					// 	required: { value: true, message: "Name is required" },
					// 	pattern: {
					// 		value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
					// 		message: "no emoi's",
					// 	},
					// 	validate: (value) => {
					// 		return (
					// 			!data[`${gridList.gridPage}`]?.some(
					// 				(printer) => printer[`${table}_name`] === value
					// 			) || "A policy with this name already exists"
					// 		);
					// 	},
					// }}
				/>

				{/* {formState?.errors[`${table}_name`] && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors[`${table}_name`].message}
					</Typography>
				)} */}
			</Box>

			<Controller
				name={`site_details.tags`}
				control={control}
				render={({ field: { name, onChange, onBlur, value, ref } }) => (
					<Autocomplete
						multiple
						size="small"
						limitTags={4}
						options={data?.tags
							?.filter((i) => i?.tag_isDeleted === 0)
							.map((tag) => tag.tag_ref)}
						// shrink
						sx={{ width: "100%" }}
						// displayEmpty
						// InputLabelProps={{ shrink: true }}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								label="Tags"
								placeholder="Search"
								inputProps={{
									...params.inputProps,
									autoComplete: "new-", // disable autocomplete and autofill
								}}
							/>
						)}
						renderOption={(props, item) => {
							const option = data?.tags?.find((t) => t?.tag_ref === item);
							return (
								<li {...props} key={option.tag_ref}>
									<ListItemText>{option.tag_name}</ListItemText>
								</li>
							);
						}}
						renderTags={(value, getTagProps) =>
							value?.map((option, index) => {
								const tag = data?.tags?.find((t) => t?.tag_ref === option);
								return (
									<Chip
										label={
											<Typography
												style={{
													whiteSpace: "normal",
													color:
														getTextColour(tag?.tag_details?.styling?.colour)
															?.colour || "black",
													fontSize: 12,
												}}
											>
												{tag?.tag_name}
											</Typography>
										}
										{...getTagProps({ index })}
										deleteIcon={
											<CloseIcon
												color={
													getTextColour(tag?.tag_details?.styling?.colour)
														?.colour || "white"
												}
											/>
										}
										sx={{
											// height: "100%",
											backgroundColor: tag?.tag_details?.styling?.colour,
											padding: "2px 4px 2px 8px",
											borderRadius: 2,

											"& svg": {
												fontSize: "12px",
												cursor: "pointer",
												padding: "4px",
												color: `${
													getTextColour(tag?.tag_details?.styling?.colour)
														?.colour
												} !important`,
											},
										}}
									/>
								);
							})
						}
						onChange={(event, selectedOptions) => {
							onChange(selectedOptions);
						}}
						onBlur={onBlur}
						value={value}
					/>
				)}
			/>
		</>
	);
}
