import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { SiteSelect } from "../../../components/SiteSelect";
import CustomSwitch from "../../../components/Switch";
import { API_ENDPOINTS } from "../../../API/apiConfig";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

export default function ListVariantForm({
	setModalVisible,
	modalVisible,
	contextName,
	// setOpenDropdown,
}) {
	const { list, setPage, setPageArr, setVariantOptions } =
		React.useContext(GridContext);
	const { data, setData, variant, setVariant } = React.useContext(DataGridContext);
	const { getAccessTokenSilently } = useAuth0();
	const [isSaving, setIsSaving] = React.useState(false);
	const {
		register,
		control,
		reset,
		getValues,
		handleSubmit,
		formState,
		formState: { errors, isSubmitSuccessful },
	} = useForm({
		defaultValues: {},
	});

	const [isSite, setIsSite] = React.useState(false);

	const handleSwitch = (event, newValue) => {
		setIsSite(newValue);
	};

	React.useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset();
		}
	}, [formState, reset]);

	const variants = data[contextName]
		.filter(
			(screen) =>
				// screen.list_variant !== 0 &&
				screen?.list_number === list?.list_number &&
				screen?.list_isDeleted === 0
		)
		.sort((a, b) => a.list_variant - b.list_variant);

	const onSubmit = async () => {
		setIsSaving(true);
		const form = getValues();
		let variant = {
			...list,
		};

		delete variant.uid;
		delete variant.list_ref;
		delete variant.ref;
		delete variant.list_uid;

		variant.list_variant = variants.at(-1).list_variant + 1;
		variant.list_name = form.list_name;
		variant.list_isDeleted = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [variant],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

// 		const newSites = [...data.sites];

// 		// const lists = [...data.screens, variant];
// 		if (isSite) {
// 			const sitesToUpdate = [];
// 			form.site.forEach((site) => {
// 				if (!site?.site_details?.screen_variants) {
// 					// console.log("here");
// 					site.site_details.screen_variants = [];
// 				}
// 				const variantObj = new Map([
// 					[list.list_number, variants.at(-1).list_variant + 1],
// 				]);
// 				const obj = Object.fromEntries(variantObj);
// 				site.site_details.screen_variants.push(obj);
// 				sitesToUpdate.push(site);
// 				let foundIndex = newSites.findIndex(
// 					(x) => x.site_number === site.site_number
// 				);
// 				newSites[foundIndex] = site;

// 				return site;
// 			});

// 			const siteUpdate = {
// 				updates: sitesToUpdate,
// 			};
// console.log('list list variant form',list)
// 			axios
// 				.post(API_ENDPOINTS.site, siteUpdate, config) // using endpoint from config
// 				.then(function (response) {
// 					console.log(response);
// 					// setData({
// 					// 	...data,
// 					// 	sites: newSites,
// 					// });
// 					// console.log("sites res", response);
// 					// sites = [...data?.sites, response?.data?.site[0]];
// 					// setTimeout(() => {
// 					// 	setIsSaving(false);
// 					// }, 2000);
// 				})
// 				.catch(function (error) {
// 					// console.log("res", error);
// 				});
// 		}

		axios
			.post(API_ENDPOINTS.list, newRowData, config) // using endpoint from config
			.then(function (response) {
				let newList = response?.data?.list[0];
				newList.uid = response?.data?.list[0].list_uid;

				let newscreens = [...data[contextName], newList];

				setData({
					...data,
					[contextName]: newscreens,
					// sites: newSites,
				});

				// screens = [...data?.screens, response?.data?.list[0]];
				// console.log(
				// 	newscreens.filter(
				// 		(screen) =>
				// 			screen.list_number === list.list_number &&
				// 			screen.list_isDeleted === 0
				// 	)
				// );
				setVariant(newList);
				setVariantOptions(
					newscreens.filter(
						(screen) =>
							parseInt(screen.list_number) === parseInt(list.list_number) &&
							screen.list_isDeleted === 0
					)
				);
				setPage(1);
				setPageArr(
					Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
				);
				setTimeout(() => {
					setIsSaving(false);
				}, 1000);
			})
			.then(() => 	
				setModalVisible(!modalVisible)
			
			)
			.catch(function (error) {
				// console.log("res", error);
			});
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "20px",
		width: 400,
		// minHeight: 400,
		bgcolor: "white",
		boxShadow: 24,
		p: 4,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		gap: 2,
	};

	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h3">New Variant</Typography>

					<IconButton
						aria-label="close"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box sx={{ width: "100%", mt: 2 }}>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<TextField
								label="Variant Name"
								size="small"
								type="variant"
								name="name"
								sx={{ width: "100%" }}
								InputLabelProps={{
									shrink: true,
								}}
								value={value}
								onChange={onChange}
							/>
						)}
						name="list_name"
						rules={{
							required: true,
							pattern: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
						}}
						defaultValue={""}
					/>

					{errors["list_name"] && errors["list_name"].type === "required" && (
						<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
							* Name is required.
						</Typography>
					)}
					{errors["list_name"] && errors["list_name"].type === "pattern" && (
						<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
							Name cannot contain special characters{" "}
						</Typography>
					)}
				</Box>
				{/* <Box sx={{ width: "100%", alignSelf: "flex-start" }}>
					<CustomSwitch
						label="Site variant?"
						value={isSite}
						onChange={handleSwitch}
					/>
				</Box> */}
				{/* {isSite && (
					<Box sx={{ width: "100%", mb: 2 }}>
						<Controller
							control={control}
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<SiteSelect
									onChange={(e, data) => onChange(data)}
									options={data?.sites?.filter((site) => {
										if (
											site?.site_details?.screen_variants?.some((obj) =>
												obj?.hasOwnProperty(JSON.parse(list?.list_number))
											) &&
											site.site_isDeleted === 0
										) {
											return null;
										}
										return site;
									})}
									value={value}
									ref={ref}
								/>
							)}
							name="site"
							defaultValue={[]}
						/>
					</Box>
				)} */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Button
						variant="outlined"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => reset()}
					>
						Reset form
					</Button>
					<LoadingButton
						size="small"
						onClick={handleSubmit(onSubmit)}
						loading={isSaving}
						variant={!isSaving ? "contained" : "outlined"}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						disabled={isSaving}
					>
						<span>Save</span>
					</LoadingButton>
				</Box>
			</Box>
		</Modal>
	);
}
