import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import { Button, Input, InputAdornment, OutlinedInput } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { Colours } from "../assets/global/Theme-variable";
import { Box } from "@mui/material";
import { FiX } from "react-icons/fi";

export default function CustomSearch({ onChange, value, onClear }) {
	const [clicked, setClicked] = React.useState(false);
	const textInput = React.useRef(null);
	return (
		<Box
			style={{
				border: `1px solid rgba(42, 158, 197, 0.5)`,
				// maxWidth: "fit-content",
				borderRadius: "6px",
				padding: "3px 7px",
			}}
			elevation={0}
		>
			<Stack direction="row" className="searchBar">
				<Collapse orientation="horizontal" in={clicked} sx={{display:'flex', flexDirection:'row'}}>
					<InputBase
						sx={{
							fontSize: "0.875rem",
							ml: 2,
							mt: 0,
							// "& .css-yz9k0d-MuiInputBase-input": {
							// 	padding: "0px",
							// },
							// "& .MuiButtonBase-root": {
							// 	padding: "0px",
							// },
						}}
						value={value}
						onChange={onChange}
						inputProps={{
							"aria-label": "search",
						}}
						placeholder="Search"
						inputRef={textInput}
						endAdornment={<IconButton
							onClick={onClear}
							size="small"
							color="primary"
							type="button"
							aria-label="search"
						>
							<FiX/>
						</IconButton>}
					/>
						
				</Collapse>
				<IconButton
					onClick={() => {
						setClicked(!clicked);
						setTimeout(() => {
							textInput.current.focus();
						}, 100);
					}}
					size="small"
					color="primary"
					type="button"
					aria-label="search"
				>
					<FiSearch color={Colours.gsblue} />
				</IconButton>
			</Stack>
		</Box>
	);
}
