import { Autocomplete, Box, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import { Colours } from "../../../../assets/global/Theme-variable";
import { Controller, useFormContext } from "react-hook-form";
import { DataGridContext } from "../../../../context/DataGridContext";

export default function NewStaffForm({ methods, table }) {
	const { setValue, control, formState } = useFormContext();
	const { data } = React.useContext(DataGridContext);
	const roles = [
		{
			id: 1,
			text: "No Access",
			userLevel: 0,
			trainingServer: false,
			cashDrawer: 0,
		},

		{
			id: 2,

			text: "Server",
			userLevel: 1,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 3,

			text: "Supervisor",
			userLevel: 3,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 4,

			text: "Manager",
			userLevel: 5,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 5,

			text: "Owner",
			userLevel: 7,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 6,

			text: "Training Mode",
			userLevel: 1,
			trainingServer: true,
			cashDrawer: 1,
		},

		// {
		// 	text: "Engineer",
		// 	"server_details.userLevel": "9",
		// 	"server_details.trainingServer": false,
		// 	"server_details.cashDrawer": 1,
		// },
	];


	const staffNumbers = Array.from(Array(9999), (e, i) => JSON.stringify(i + 1));

	const allStaff = data?.staff?.map((server) => 
		server.server_isDeleted === 0 && server.server_number !== '419' && server.server_number	);

	const serverOptions = staffNumbers.filter(
		(number) => !allStaff.includes(number)
	);

	return (
		<>
			<Box sx={{ width: "100%" }}>
				{/* <Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="server-number"
							label="Number"
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`server_number`}
					// defaultValue=""
					rules={{
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Must be a number ",
						},
						required: {
							value: true,
							message: "Number is required",
						},

						validate: (value) => {
							if (
								data?.staff?.some((server) => server.server_isDeleted === 0 && server.server_number === value)
							) {
								return "A server with this number already exists";
							}
							if (value === "419" || value === 419) {
								return "Cannot create a server with this number";
							}
						},
					}}
				/>

				{formState?.errors?.server_number && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_number?.message}
					</Typography>
				)} */}
					<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
					<Controller
					render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
						<Autocomplete
							key={`autocomplete_`}
							options={serverOptions}
							// freeSolo
							value={
value || null
							}
							isOptionEqualToValue={(o, v)=> o === v}
							// disableClearable
							sx={{ width: "100%" }}
							size="small"
							// getOptionLabel={(o) => o ?? ''}
							onChange={(e, newValue) => {
								setValue("server_number", newValue);
							
							}}							renderInput={(params) => (
								<TextField
									required
									sx={{ width: "100%", marginTop: 1 }}
									{...params}
									label={"Number"}
									variant={"outlined"}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					)}
					name={`server_number`}
					rules={{
						required: {
							value: true,
							message: "Server Number is required",
						},
					}}
				/>
				{formState?.errors?.server_number && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_number?.message}
					</Typography>
				)}
				</Box>
				{/* <TextField
					label={"Number"}
					size="small"
					type="text"
					sx={{ width: "100%" }}
					InputLabelProps={{
						shrink: true,
					}}
					{...methods.register(`server_number`, {
						required: {
							value: true,
							message: "Server number is required",
						},

						validate: (value) => {
							console.log(value);
							return (
								!data?.staff?.some(
									(server) => server.server_number === value
								) || "A server with this number already exists"
							);
						},
					})}
					// defaultValue={`server_name` && `server_name`}
				/>
				{formState?.errors?.server_number && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_number?.message}
					</Typography>
				)} */}
			</Box>
			<Box sx={{ width: "100%" }}>
				{/* <TextField
					label={"Name"}
					size="small"
					type="text"
					sx={{ width: "100%" }}
					InputLabelProps={{
						shrink: true,
					}}
					{...methods.register(`server_name`, {
						required: {
							value: true,
							message: "Server name is required",
						},

						// validate: (value) =>
						// 	!data?.printers?.some(
						// 		(printer) => printer.printer_name === value
						// 	) || "A policy with this name already exists",
					})}
					// defaultValue={`server_name` && `server_name`}
				/> */}

				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="server-name"
							label="Name"
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`server_name`}
					// defaultValue=""
					rules={{
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Name cannot contain emojis or special characters",
						},
						required: {
							value: true,
							message: "Server Name is required",
						},
					}}
				/>

				{formState?.errors?.server_name && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_name.message}
					</Typography>
				)}
			</Box>
			<Box sx={{ width: "100%" }}>
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="server-displayName"
							label="Display Name"
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`server_details.displayName`}
					// defaultValue=""
					rules={{
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Name cannot contain emoji's or special characters",
						},
						required: {
							value: true,
							message: "Display Name is required",
						},
					}}
				/>
				{formState?.errors?.server_details?.displayName && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_details?.displayName.message}
					</Typography>
				)}
			</Box>
			<Box sx={{ width: "100%" }}>
				<Controller
					render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
						<Autocomplete
							key={`autocomplete_`}
							options={roles}
							freeSolo
							value={

								 roles.find(
											(role) =>
												parseInt(role.userLevel) ===
													parseInt(value.userLevel) &&
												parseInt(role.cashDrawer) ===
													parseInt(value.cashDrawer) &&
												role.trainingServer === value.trainingServer
									  )
									|| null
							}
							isOptionEqualToValue={(o, v)=> o.userLevel === v.userLevel}
							disableClearable
							sx={{ width: "100%" }}
							size="small"
							getOptionLabel={(o) => o.text ?? ''}
							onChange={(e, newValue) => {
								setValue("server_details[userLevel]", newValue["userLevel"]);
								setValue(
									"server_details[trainingServer]",
									newValue["trainingServer"]
								);
								setValue("server_details[cashDrawer]", newValue["cashDrawer"]);
							}}
							renderInput={(params) => (
								<TextField
									required
									sx={{ width: "100%", marginTop: 1 }}
									{...params}
									label={"Role"}
									variant={"outlined"}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					)}
					name={`server_details`}
					rules={{
						required: {
							value: true,
							message: "Server Name is required",
						},
					}}
				/>
				{formState?.errors?.server_details && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_details?.message}
					</Typography>
				)}
			</Box>
			<Box sx={{ width: "100%" }}>
				{/* <TextField
					label="Pin"
					// {...params}
					{...methods.register(`server_details.pin`, {
						required: {
							value: true,
							message: "Pin is required",
						},
						pattern: {
							value: /^(?!419$)\d{0,4}$/,
							message: "Pin must be a 1 to 4 digit number", // JS only: <p>error message</p> TS only support string
						},
					})}
					InputLabelProps={{
						shrink: true,
					}}
					size="small"
					sx={{
						"& input": {
							textSecurity: "disc",
							"-moz-text-security": "disc",
							"-webkit-text-security": "disc",
						},
						border: "none",
					}}
				/> */}
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="server-number"
							label="Pin"
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{
								width: "100%",
								"& input": {
									textSecurity: "disc",
									MozTextSecurity: "disc",
									// webkitTextSecurity: "disc",
									WebkitTextSecurity: "disc",
									// "-moz-text-security": "disc",
									// "-webkit-text-security": "disc",
								},
								border: "none",
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`server_details.pin`}
					// defaultValue=""
					rules={{
						pattern: {
							value: /^(?!419$)\d{0,4}$/,
							message: "Pin must be a 1 to 4 digit number", // JS only: <p>error message</p> TS only support string
						},
						required: {
							value: true,
							message: "Pin is required",
						},
					}}
				/>
				{formState?.errors?.server_details?.pin && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.server_details?.pin?.message}
					</Typography>
				)}
			</Box>
		</>
	);
}
