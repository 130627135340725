import React from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	Collapse,
	IconButton,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import ReactHookFormMultiSelectChip from "../../../../components/ReactHookFormMultiSelectChip";
import { DataGridContext } from "../../../../context/DataGridContext";
import { Colours } from "../../../../assets/global/Theme-variable";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const ArrayFields = () => {
	const { control, getValues, watch } = useFormContext();

	const { data, setData, setSnackbarMessage, setSnackbarOpen, setNewData } =
		React.useContext(DataGridContext);
	const { fields, append, remove } = useFieldArray({
		control,
		name: "modalValues",
	});

	const watchFieldArray = watch("modalValues");
	const controlledFields =
		fields &&
		watchFieldArray &&
		fields?.map((field, index) => {
			return {
				...field,
				...watchFieldArray[index],
			};
		});

	const [expanded, setExpanded] = React.useState(
		controlledFields ? controlledFields?.map((i) => i.id) : []
	);

	const handleAppend = (value) => {
		append(value);
		console.log("add: ", getValues());
	};

	const handleRemove = (index) => {
		remove(index);
		console.log("remove: ", getValues());
	};

	const ExpandMore = styled((props) => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	const handleExpandClick = (element) => {
		if (expanded?.includes(element)) {
			const newIndexes = expanded?.filter((i) => i !== element);
			setExpanded(newIndexes);
		} else {
			setExpanded([...expanded, element]);
		}
	};

	const printerOptions = [
		{ value: 1, text: "Printer 1" },
		{ value: 2, text: "Printer 2" },
		{ value: 3, text: "Printer 3" },
		{ value: 4, text: "Printer 4" },
		{ value: 5, text: "Printer 5" },
		{ value: 6, text: "Printer 6" },
		{ value: 7, text: "Printer 7" },
		{ value: 8, text: "Printer 8" },
		{ value: 9, text: "Printer 9" },
		{ value: 10, text: "Printer 10" },
		{ value: 11, text: "Printer 11" },
		{ value: 12, text: "Printer 12" },
		{ value: 13, text: "Printer 13" },
		{ value: 14, text: "Printer 14" },
		{ value: 15, text: "Printer 15" },
		{ value: 16, text: "Printer 16" },
	];
	return (
		<>
			{/* <ul> */}
			{controlledFields &&
				controlledFields?.map((item, index) => {
					return (
						// <li key={item.id} className="input-container">
						<Card
							variant="outlined"
							elevation={0}
							key={item.id}
							sx={{ backgroundColor: "none", pl: 2, pr: 2, width: "100%" }}
						>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									// height: 60,
									mb: expanded?.includes(item?.id) && 2,
								}}
							>
								<Typography sx={{ pl: 2 }}>Site {index + 1}</Typography>

								<ExpandMore
									expand={expanded?.includes(item)}
									onClick={() => handleExpandClick(item.id)}
									aria-expanded={expanded?.includes(item.id)}
									aria-label="show more"
								>
									<ExpandMoreIcon />
								</ExpandMore>
							</Box>
							<Collapse
								in={expanded?.includes(item.id)}
								timeout="auto"
								// unmountOnExit
							>
								<Stack sx={{ width: "100%" }} gap={2}>
									<ReactHookFormSelect
										id="site"
										// name={`fieldArray.${index}.sites`}
										name={`modalValues[${index}].Site`}
										label="Site"
										control={control}
										width="100%"
										// errors={errors}
										rules={{
											required: "Site is required",
										}}
										defaultValue={data?.sites?.find(
											(i) => parseInt(i.site_number) === parseInt(item.Site)
										)}
									>
										{data?.sites
											?.filter((i) => i.site_isDeleted === 0)
											.map((role) => (
												<MenuItem
													key={role?.site_number}
													value={role?.site_ref}
												>
													{role?.site_name}
												</MenuItem>
											))}
									</ReactHookFormSelect>
									<Controller
										render={({
											field: {
												name,
												onChange,
												defaultValue,
												onBlur,
												value,
												ref,
											},
										}) => (
											<Autocomplete
												multiple
												filterSelectedOptions
												options={printerOptions}
												getOptionLabel={(option) => option.text}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														InputLabelProps={{
															shrink: true,
														}}
														label={"Printers"}
													/>
												)}
												freeSolo
												onChange={(e, data) =>
													onChange(data?.map((v) => v[`value`]))
												}
												// {...props}
												limitTags={5}
												size="small"
												value={ value.map((printer) => {
													return printerOptions.find(
														(i) => parseInt(i.value) === parseInt(printer)
													)
												})}
												isOptionEqualToValue={(o, value) => {
													// value.forEach((v) => o.value === v);
													// console.log(o, value);
													return o.value === value.value;
												}}
											/>
										)}
										name={`modalValues[${index}].Printers`}
										control={control}
										// defaultValue={item?.Printers}
									/>
									
								</Stack>
								<Box sx={{width:'100%',display:'flex', justifyContent:'flex-end',pt:1}}>
								<IconButton
										color={Colours.gsblue}
										sx={{  color: Colours.gsblue,}}
										onClick={() => handleRemove(index)}
									>
										<DeleteIcon />
									</IconButton>
									</Box>
							</Collapse>
						</Card>
						// </li>
					);
				})}

			<Button
				size="medium"
				sx={{ alignSelf: "flex-end" }}
				onClick={() => handleAppend({ Site: "", Printers: [] })}
			>
				<AddIcon style={{ fontSize: "20px" }} /> Add New Site
			</Button>
		</>
	);
};

export default ArrayFields;
