import React, { useState } from "react";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import colours from "../data/colours";
import { makeHex } from "../../datagrids/utils";
import { DataGridContext } from "../../../context/DataGridContext";
import axios from "axios";

export default function PopUpColourPicker({
	handleColourChange,
	setValue,
	selectedColour,
	selectedTextColour,
	type,
	label,
	ref,
}) {
	const _ = require("lodash");

	const { data } = React.useContext(DataGridContext);

	return (
		<>
			<Box
				sx={{
					bgcolor: "background.paper",
					display: "flex",
					flexWrap: "wrap",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 1,
					p: 1,
					width: 180,
				}}
			>
				{data.colours[0].palette_details.colours.map((item, index) => (
					// {colourstopost.map((item) => (
					<Box
						sx={{
							backgroundColor: item.colour,
							width: 20,
							height: 20,
							borderRadius: 1,
							margin: 0.3,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							border:
								item.colour === "#ffffff"
									? "1px solid #c4c4c4"
									: item.colour === "white"
									? "1px solid #c4c4c4"
									: null,
						}}
						onClick={() => handleColourChange(item.colour)}
						// onClick={postcolours}
						key={index}
						ref={ref ? ref : null}
					>
						{item.colour === selectedColour ? (
							<Box
								sx={{
									backgroundColor: "white",
									width: 14,
									height: 14,
									borderRadius: 0.5,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									border:
										selectedColour === "#ffffff"
											? "1px solid #c4c4c4"
											: selectedColour === "white"
											? "1px solid #c4c4c4"
											: null,
								}}
							>
								<Box
									sx={{
										backgroundColor: item.colour,
										width: 10,
										height: 10,
										borderRadius: 0.5,
									}}
								></Box>
							</Box>
						) : null}
					</Box>
				))}
			</Box>
		</>
	);
}
