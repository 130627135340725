import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useBoxShadow } from "./useBoxShadow";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DataGridContext } from "../context/DataGridContext";
import EditIcon from "@mui/icons-material/Edit";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import { Close } from "@mui/icons-material";
import { Input } from "@mui/material";
import { useState } from "react";
import { DisguisedTextField } from "./DisguisedTextField";
import { SortableList } from "./SortableList";
import { checkPermissions } from "../views/AccessControl";

export const Modifier = ({
	item,
	removeItem,
	modifiers,
	dragConstraints,
	setItem,
	setModifiers,
	mod,
}) => {
	const y = useMotionValue(0);
	const { data, setData, userPermission } = React.useContext(DataGridContext);

	const index = modifiers.findIndex((modifier) => modifier.id === mod.id);
	const [focused, setFocused] = React.useState(false);

	const changePrice = (event) => {
		mod.modifier = parseFloat(event.target.value);
	};

	return (
		<Box
			sx={{
				listStyle: "none",
				padding: 0,
				display: "flex",
				flexDirection: "row",
				width: "100%",
				mb: 2,
			}}
		>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					alignItems: "center",
					backgroundColor: Colours.fieldBackground,
					// backgroundColor: "grey",
					justifyContent: "space-between",
					padding: 1,
					borderRadius: 1,

					// margin: 4,
				}}
			>
				<Box
					sx={{
						// width: "70%",
						display: "flex",
						flexDirection: "row",
						// backgroundColor: Colours.fieldBackground,
						flex: 1,
					}}
				>
					<Typography
						sx={{
							fontSize: 12,
							color: Colours.gsblue,
							ml: 2,
							userSelect: "none",
						}}
					>
						{index + 1}
					</Typography>
					<Typography
						sx={{
							fontSize: 12,
							color: Colours.gsblue,
							ml: 2,
							userSelect: "none",
						}}
					>
						{
							data?.sites?.find((site) => site?.site_ref === mod?.ref)
								?.site_name
						}
						{mod.priceBand &&
							"- " +
								data?.priceband?.find(
									(band) => band.priceband_ref === mod?.priceBand
								)?.priceband_name}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						flexDirection: "row",
						width: "30%",
						mr: 5,
					}}
				>
					<DisguisedTextField
						value={mod?.modifier}
						disabled={
							!checkPermissions(userPermission, {
								page: "items",
								// allow: false,
								strict: false,

								action: "edit",
							})
						}
						focused={focused}
						setFocused={setFocused}
						onChange={changePrice}
					/>
				</Box>
				{/* <Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						width: "5%",
					}}
				>
					{/* <DragIndicatorIcon
						style={{
							cursor: "move",
							color: Colours.gsblue,
							padding: 0,
						}}
						onPointerDown={(e) => controls.start(e)}
					/> */}

				{checkPermissions(userPermission, {
					page: "items",
					// allow: false,
					strict: false,

					action: "edit",
				}) === true && <SortableList.DragHandle />}
				{/* </Box> */}
			</Box>
			{checkPermissions(userPermission, {
				page: "items",
				// allow: false,
				strict: false,

				action: "edit",
			}) === true && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: 55,
					}}
				>
					<Close
						style={{
							color: Colours.gsblue,
							padding: 0,
							fontSize: "medium",
						}}
						onClick={() => removeItem(mod.id)}
					/>
				</Box>
			)}
		</Box>
		// </Reorder.Item>
	);
};
