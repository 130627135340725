import {
	Autocomplete,
	Box,
	Button,
	InputAdornment,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useContext } from "react";
import OptionSelect from "../views/grid/components/OptionSelect";
import { useForm, useFormContext } from "react-hook-form";
import axios from "axios";
import { nanoid } from "nanoid";
import { DataGridContext } from "../context/DataGridContext";
import { Colours } from "../assets/global/Theme-variable";
import { NumberFormatCustom } from "../views/datagrids/utils";
// import { NumericFormat } from "react-number-format";

// export default function NumberFormatCustom(props) {
// 	const {inputRef, onChange, ...other} = props;

// 	return (
// 		<NumericFormat
// 			{...other}
// 			getInputRef={inputRef}
// 			onValueChange={(values) => {
// 				onChange({
// 					target: {
// 						name: props.name,
// 						value: values.value,
// 					},
// 				});
// 			}}
// 			thousandSeparator
// 			decimalScale={2}
// 			// isNumericString
// 		/>
// 	);
// }

export default function NewModifierModal({
	modalVisible,
	setModalVisible,
	modifiers,
	setModifiers,
	item,
	setItem,
	error,
	setError,
}) {
	const { data, setData } = useContext(DataGridContext);

	const { handleSubmit, reset, register, setValue } = useForm({});

	const [modType, setModType] = React.useState("Site");
	const [ref, setRef] = React.useState(data.sites[0]);
	const [priceBand, setPriceBand] = React.useState("");
	const [price, setPrice] = React.useState(item?.plu_details?.price);
	const [errorText, setErrorText] = React.useState("");
	const handleModType = (event) => {
		setModType(event);
		event === "Site" && setRef(data?.sites[0]);
		event === "Price Band" && setRef(data?.priceband[0]);
	};

	const onSubmit = () => {
		const newModifier = {
			type: modType,
			ref: ref?.site_ref,
			modifier: parseFloat(price),
			priceBand: priceBand.priceband_ref,
		};

		item.plu_details.priceModifiers = [
			...item.plu_details.priceModifiers,
			newModifier,
		];

		const mutatedMods = modifiers.map((mod) => {
			return {
				modifier: mod?.modifier,
				type: mod?.type,
				ref: mod?.ref,
				priceBand: mod?.priceBand,
			};
		});
		mutatedMods.push(newModifier);

		setModifiers((items) => [...items, { id: nanoid(), ...newModifier }]);
		setItem({
			...item,
			plu_details: { ...item.plu_details, priceModifiers: mutatedMods },
		});
		setPriceBand("");
		setPrice("");

		setModalVisible(!modalVisible);
		console.log(item);
		// }
	};

	const handleModChange = (event) => {
		setRef(modType === "Site" ? event.target.value : event.target.value);
	};

	const handlePriceBand = (event) => {
		setPriceBand(event.target.value);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "20px",
		width: 410,
		minHeight: 200,
		bgcolor: "white",
		boxShadow: 24,
		p: 4,
		display: "flex",
		justifyContent: "space-around",
		flexDirection: "column",
	};
	return (
		<Modal open={modalVisible}>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Box
					sx={style}
					// component="form"
					onSubmit={handleSubmit(onSubmit)}
					// noValidate
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 2,
						}}
					>
						<Typography
							variant="h5"
							sx={{ mb: 0, color: Colours.gsblue, justifySelf: "center" }}
						>
							New Price Modifier
						</Typography>
						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => setModalVisible(!modalVisible)}
						>
							X
						</Button>
					</Box>

					<OptionSelect
						options={data?.sites.filter((i) => i.site_isDeleted === 0)}
						value={ref}
						label={modType}
						onChange={handleModChange}
						type={modType === "Site" ? "site" : "priceBand"}
						width={"100%"}
						mb={2}
					/>
					<Box sx={{ mt: 2 }}>
						<OptionSelect
							options={data?.priceband?.filter(
								(band) =>
									band?.priceband_details?.isActive === true &&
									band?.priceband_isDeleted === 0 &&
									!item?.plu_details?.priceModifiers?.find(
										(p) =>
											p?.type === "Site" &&
											p?.ref === ref.ref &&
											p?.priceBand === band?.priceband_number
									)
							)}
							value={priceBand}
							label={"Price Band"}
							onChange={handlePriceBand}
							type="priceBand"
							width={"100%"}
							mb={2}
						/>
					</Box>
					<Typography
						// variant="h5"
						sx={{
							fontSize: 12,
							mb: 0,
							textAlign: "right",
							color: Colours.gsblue,
							justifySelf: "center",
							mb: 2,
						}}
					>
						{errorText}
					</Typography>

					<TextField
						label={"Price"}
						size="small"
						type="text"
						sx={{ width: 350, mb: 2 }}
						InputLabelProps={{
							shrink: true,
						}}
						required={true}
						// error="Price is required"
						// defaultValue={price}
						value={price}
						onChange={(event) => setPrice(event.target.value)}
						InputProps={{
							inputComponent: NumberFormatCustom,

							inputMode: "numeric",
							pattern: "[0-9]*",
							startAdornment: (
								<InputAdornment position="start">£</InputAdornment>
							),
						}}
					/>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<Button
							variant="outlined"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => reset()}
						>
							Reset form
						</Button>

						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							type="submit"
							disabled={!priceBand || !ref || !price}
						>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Modal>
	);
}
