import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	OutlinedInput,
	Switch,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { modifiers } from "../../../grid/data/modifiers";
import RadioButtonsGroup from "../../../../components/RadioGroup";
import { Colours } from "../../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../../context/DataGridContext";
import {
	NumberFormatCustom,
	generatePLUNumber,
	getTextColour,
} from "../../utils";
import ReactHookFormTagField from "../../../../components/ReactHookFormTagField";

export default function NewItemForm({ methods }) {
	const [isMod, setIsMod] = React.useState("Item");
	const { data, setData } = React.useContext(DataGridContext);
	const { control, getValues, errors, formState, watch } = useFormContext();
	const [warning, setWarning] = React.useState("");
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "plus",
	});

	const [applyTags, setApplyTags] = React.useState(false);

	const watchFieldArray = watch("plus");
	const controlledFields =
		fields &&
		watchFieldArray &&
		fields?.map((field, index) => {
			return {
				...field,
				...watchFieldArray[index],
			};
		});

	const [plu, setPLU] = React.useState({
		plu_name: "",
		plu_details: {
			salesGroup: "",
			tags: [],
			price: "",
			priceModifiers: [],
		},
		plu_isDeleted: 0,
		plu_number: "",
		plu_ref: "",
		plu_uid: "",
		ref: "",
		uid: "",
		modifier: "",
		selectedMods: {},
	});

	const lastPLU = data["items"].reduce((a, b) =>
		parseInt(a.plu_number) > parseInt(b.plu_number) ? a : b
	).plu_number;

	const pluName = methods.watch(`plus[${0}].plu_name`, false);
	const price = methods.watch(`plus[${0}].plu_details.price`, false);
	const salesGroup = methods.watch(`plus[${0}].plu_details.salesGroup`, false);
	const tags = methods.watch(`plus[${0}].plu_details.tags`, false);

	const mods = methods.watch(`plus[${0}].selectedMods`, false);


	const handleApplyTags = (event) => {
		setApplyTags(event.target.checked);

	if(event.target.checked === false) {
			controlledFields.map((item, index) => {
				if (index > 0) {
			update(index, {
				...controlledFields[index],
				plu_details: {...controlledFields[index].plu_details, tags:[]} 
			})}
			})
		} else {
		
				controlledFields.map((item, index) => {
					if (index > 0) {
				update(index, {
					...controlledFields[index],
					plu_details: {...controlledFields[index].plu_details, tags:tags} 
				})}
				})
			
		}
	}

	const handlePLU = (event, newValue) => {
		event.preventDefault();
		if(newValue){
		const newPLU = {
			plu_name: newValue?.plu_name,
			// new_name: "",
			plu_number: newValue?.plu_number,
			plu_details: newValue?.plu_details,
		};
		setPLU(newPLU);

		update(0, {
			...controlledFields[0],
			plu_name: newValue?.plu_name,
			plu_number: newValue?.plu_number,
			plu_details: newValue?.plu_details,
		});
	}
	};

	React.useEffect(() => {
		data?.items?.some((item) => item.plu_isDeleted === 0 && item?.plu_name === pluName)
			? setWarning("Warning - an item with this name already exists")
			: setWarning(null);

controlledFields.map((item, index) => {
	if (index > 0) {
update(index, {
	...controlledFields[index],
	plu_name: `${mods[index - 1]?.prefix} ${controlledFields[0].plu_name}`,
})}
})
	}, [pluName]);

	React.useEffect(() => {
	
controlledFields.map((item, index) => {
	if (index > 0) {
update(index, {
	...controlledFields[index],
	plu_details: {...controlledFields[index].plu_details, price:parseFloat(price)} 
})}
})
	}, [price]);

	React.useEffect(() => {
	
		controlledFields.map((item, index) => {
			if (index > 0) {
		update(index, {
			...controlledFields[index],
			plu_details: {...controlledFields[index].plu_details, tags: applyTags ? tags : []} 
		})}
		})
			}, [tags]);
	// const modifiersFiltered = modifiers(data?.modifier)?.filter(
	// 	(mod) =>
	// 		// band.priceband_details.isActive === true &&
	// 		!data?.items?.find(
	// 			(i) =>
	// 				i?.plu_number?.slice(0, -1) === plu?.plu_number?.slice(0, -1) &&
	// 				mod?.number === parseInt(i?.plu_number?.slice(-1))
	// 		)
	// );

	useEffect(() => {
		methods.reset();
	}, [isMod]);

	const handleSwitch = (event, newValue) => {
		setIsMod(newValue);
		if (newValue === "ItemWithMod") {
			update(0, { ...fields[0], plu_number: generatePLUNumber(lastPLU, 0) });
		}
		if (!newValue) {
			console.log("here");
		}
	};

	const handleMod = (event, newValue, field) => {
		update(0, {
			...controlledFields[0],
			plu_name: `${newValue.prefix} ${plu?.plu_name}`,
			plu_number: JSON.stringify(
				parseInt(plu?.plu_number) + parseInt(newValue.number)
			),
			modifier: newValue.number,
		});
	};


	const handleChangeSelectedMods = (newValue, field) => {
		update(0, {
			...controlledFields[0],
			selectedMods: newValue,
		});

		let missingModifier = newValue?.find((obj) =>
			mods.every((s) => s.number !== obj.number)
		);

		let removedModifier = mods?.find((obj) =>
			newValue.every((s) => s.number !== obj.number)
		);

		if (missingModifier) {
			let mod = mapFn(missingModifier?.number);
			append(mod);
		}
		if (removedModifier) {
			const field2 = controlledFields.findIndex((f) =>
				f?.plu_number?.endsWith(parseInt(removedModifier.number))
			);
			remove(field2);
		}
	};

	function mapFn(value) {
		const modObj = modifiers(data.modifier).find(
			(mod) => mod.number === parseInt(value)
		);
		const newPLU = {};
		newPLU.plu_name = `${modObj?.prefix} ${controlledFields[0].plu_name}`;
		newPLU.plu_number = generatePLUNumber(lastPLU, modObj?.number);
		newPLU.plu_details = {
			price: parseFloat(price),
			salesGroup: salesGroup,
			tags: applyTags ? tags : [],
			priceModifiers: [],
		};

		return newPLU;
	}

	return (
		<Box
			sx={{
				mt: 2,
				mb: 2,
				display: "flex",
				gap: 2,
				flexDirection: "column",
				height: "100%",
				width: "100%",
			}}
		>
			<RadioButtonsGroup value={isMod} onChange={handleSwitch} />

			{isMod === "Modifier" && (
				<>
					<Controller
						// name={`plu_details.salesGroup`}
						name={`plus[${0}]`}
						control={control}
						rules={{ required: true }}
						render={({ field: { name, onChange, onBlur, value, ref } }) => (
							<Autocomplete
								id="sales-group"
								options={data?.items?.filter(
									(option) =>
										option?.plu_number?.at(-1) === "0" &&
										option.plu_isDeleted === 0
								)}
								isOptionEqualToValue={(o,value) => o.plu_number === value}
								value={
									// console.log(
										value || null
										// data.items.find(
										// 	(m) =>
										// 		parseInt(m.plu_number) === parseInt(value.plu_number)
										// )
									// )
									// value
									// 	? // controlledFields[0].plu_number
									// 	  data.items.find((m) => m.plu_number === value.plu_number)
									// 	: null
									// value
								}
								// getOptionLabel={(option) => option.plu_name}
								// displayEmpty
								// disableClearable
								freeSolo
								size="small"
								// name={name}
								// onChange={onChange}
								// defaultValue={value ? value : null}
								// onBlur={onBlur}
								ref={ref}
								// onChange={onChange}
								onChange={handlePLU}
								// onChange={(e, newValue) => handleMod(e, newValue, fields[0])}
								renderInput={(params) => (
									<TextField
										{...params}
										label={"Item"}
										variant={"outlined"}
										InputLabelProps={{
											shrink: true,
										}}
										placeholder="Select Item"
									/>
								)}
								renderOption={(props, item) => (
									<li {...props} key={item.plu_number}>
										{item?.plu_name}
									</li>
								)}
								getOptionLabel={(option) => option?.plu_name || ""}
							/>
						)}
					/>
					<Box sx={{ width: "100%" }}>
						<Controller
							// name={`plu_details.salesGroup`}
							name={`plus[${0}].modifier`}
							control={control}
							rules={{ required: true }}
							render={({ field: { name, onChange, onBlur, value, ref } }) => (
								<Autocomplete
									id="sales-group"
									options={modifiers(data?.modifier)?.filter(
										(mod) =>
											// band.priceband_details.isActive === true &&
											!data?.items?.find(
												(i) =>
													i?.plu_number?.slice(0, -1) === plu?.plu_number?.slice(0, -1) &&
													mod?.number === parseInt(i?.plu_number?.slice(-1))
											)
									)}
									disableClearable
									defaultValue={

										// console.log(value)
										value ? value : null
										// value
										// 	? modifiersFiltered.find(
										// 			(m) => parseInt(m.number) === parseInt(value.number)
										// 	  )
										// 	: null
									}
									getOptionLabel={(option) => option.name}
									isOptionEqualToValue={(option,value) =>
									{
										return parseInt(option?.number) === parseInt(value.number)
									}
									}
									// displayEmpty
									size="small"
									name={name}
									// onChange={onChange}
									// defaultValue={value ? value : null}
									// onBlur={onBlur}
									ref={ref}
									onChange={(e, newValue) => handleMod(e, newValue, fields[0])}
									renderInput={(params) => (
										<TextField
											{...params}
											label={"Modifier"}
											variant={"outlined"}
											placeholder="Select"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
							)}
						/>
						{formState?.errors?.plus?.[0].modifier &&
							formState?.errors?.plus?.[0].modifier?.type === "required" && (
								<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
									Modifier is required.
								</Typography>
							)}
					</Box>
				</>
			)}
			<Box sx={{ width: "100%" }}>
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="modifier-name"
							label="Name"
							value={pluName}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`plus[${0}].plu_name`}
					// defaultValue=""
					rules={{
						required: { value: true, message: "Name is required" },
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Name cannot contain emoji's or special characters",
						},
						maxLength: {
							value: 40,
							message: "Name must be less than 10 characters",
						},
						// validate: (value) => {
						// 	return (
						// 		!data?.items?.some((item) => item?.plu_name === value) ||
						// 		"An item with this name already exists"
						// 	);
						// },
					}}
				/>

				{formState?.errors?.plus?.[0].plu_name && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.plus?.[0].plu_name.message}
					</Typography>
				)}
				{warning && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.gsblue }}>
						{warning}
					</Typography>
				)}
			</Box>
			<Box sx={{ width: "100%" }}>
				<Controller
					name={`plus[${0}].plu_details.price`}
					control={control}
					render={({ field: { name, onChange, onBlur, value, ref } }) => (
						<TextField
							label={"Base Price"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							value={price}
							name={name}
							onChange={(event) => {
								event.target.value && onChange(parseFloat(event.target.value));
							}}
							InputProps={{
								inputComponent: NumberFormatCustom,

								inputMode: "numeric",
								pattern: "[0-9]*",
								startAdornment: (
									<InputAdornment position="start">£</InputAdornment>
								),
							}}
						/>
					)}
					rules={{
						required: true,
					}}
				/>

				{formState?.errors?.plus?.[0].plu_details?.price &&
					formState?.errors?.plus?.[0].plu_details?.price?.type ===
						"required" && (
						<Typography sx={{ fontSize: 12, color: Colours.red }}>
							Price is required.
						</Typography>
					)}
			</Box>
			<Box sx={{ width: "100%" }}>
				<Controller
					// name={`plu_details.salesGroup`}
					name={`plus[${0}].plu_details.salesGroup`}
					control={control}
					rules={{ required: true }}
					render={({ field: { name, onChange, onBlur, value, ref } }) => (
						<Autocomplete
							id="sales-group"
							options={data?.categories?.filter(
								(i) => i?.salesGroup_isDeleted === 0
							)}
							getOptionLabel={(option) =>
								option.salesGroup_number + " - " + option.salesGroup_name
							}
							isOptionEqualToValue={(option, value) =>{
								// console.log(option,value)
								return parseInt(option?.salesGroup_number) === parseInt(value.salesGroup_number)
							}
							}
							// displayEmpty
							size="small"
							value={
								salesGroup
									? data.categories.find(
											(c) =>
												parseInt(c.salesGroup_number) === salesGroup ||
												c.salesGroup_number === salesGroup
									  )
									: null
								// value
							}
							name={name}
							// onChange={onChange}
							// defaultValue={value ? value : null}
							onBlur={onBlur}
							ref={ref}
							onChange={(event, selectedOptions) => {
								selectedOptions &&
									onChange(parseInt(selectedOptions?.salesGroup_number));
								controlledFields.map((i, index) => {
									i.plu_details.salesGroup = parseInt(
										selectedOptions?.salesGroup_number
									);
									// update(index, {
									// 	...controlledFields[0],
									// 	selectedMods: newValue,
									// })
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={"Category"}
									variant={"outlined"}
									placeholder="Select"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					)}
				/>
				{formState?.errors?.plus?.[0].plu_details?.salesGroup &&
					formState?.errors?.plus?.[0].plu_details?.salesGroup?.type ===
						"required" && (
						<Typography sx={{ fontSize: 12, color: Colours.red }}>
							Category is required.
						</Typography>
					)}
			</Box><Box sx={{display:'flex', width:'100%', justifyContent:'flex-end', flexDirection:'column'}}>
				<ReactHookFormTagField
						id="tag_ref"
						name={`plus[${0}].plu_details.tags`}
						label="Tags"
						control={control}
						defaultValue={tags}
						selectoptions={data?.tags?.filter(
							(i) => i.tag_isDeleted === 0
						)}
						fieldValueToSave="tag_ref"
					/>
						{isMod === "ItemWithMod" && tags.length > 0 && (
			
				
							<FormControlLabel
								// value={option.id}
								 labelPlacement="start"
								control={<Checkbox          
									checked={applyTags} onChange={handleApplyTags} />}
								label={<Typography sx={{ fontSize: 14 }}>Apply tags to modifiers?</Typography>}
								// inputRef={register}
							/>
	)}</Box>

			{isMod === "ItemWithMod" && (
				<>
					<Controller
						name={`plus[${0}].selectedMods`}
						control={control}
						render={({ field: { name, onChange, onBlur, value, ref } }) => (
							<Autocomplete
								multiple
								size="small"
								limitTags={4}
								options={modifiers(data.modifier)}
								onChange={(event, selectedOptions) => {
									handleChangeSelectedMods(selectedOptions, fields[0]);
								}}
								disableClearable
								value={controlledFields[0].selectedMods}
								filterSelectedOptions
								isOptionEqualToValue={(o, value) => o.number === value.number}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										label="Modifiers"
										placeholder="Search"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-", // disable autocomplete and autofill
										}}
									/>
								)}
								// renderOption={(props, item) => {
								// 	return (
								// 		<li {...props} key={item.number}>
								// 			<ListItemText>{item.prefix}</ListItemText>
								// 		</li>
								// 	);
								// }}
								getOptionLabel={(o) => o.prefix}
								renderTags={(value, getTagProps) =>
									value?.map((option, index) => {
										const { key, ...rest } = getTagProps({ index });

										return (
											<Chip
												key={key}
												{...rest}
												label={
													<Typography
														style={{
															whiteSpace: "normal",
															// color:
															// 	getTextColour(tag?.tag_details?.styling?.colour)
															// 		?.colour || "black",
															fontSize: 12,
														}}
													>
														{option?.prefix}
													</Typography>
												}
												deleteIcon={
													<CloseIcon
													// color={
													// 	getTextColour(tag?.tag_details?.styling?.colour)
													// 		?.colour || "white"
													// }
													// onClick={handleRemoveMod}
													/>
												}
												sx={{
													// height: "100%",
													// backgroundColor: tag?.tag_details?.styling?.colour,
													padding: "2px 4px 2px 8px",
													borderRadius: 2,

													"& svg": {
														fontSize: "12px",
														cursor: "pointer",
														padding: "4px",
														// color: `${
														// 	getTextColour(tag?.tag_details?.styling?.colour)
														// 		?.colour
														// } !important`,
													},
												}}
											/>
										);
									})
								}
								// onChange={(event, selectedOptions) => {
								// 	handleChangeSelectedMods(selectedOptions, fields[0]);
								// }}
								// onBlur={onBlur}
								// value={controlledFields[0]?.selectedMods}
							/>
						)}
					/>
					<Box sx={{ width: "100%" }}>
						{controlledFields &&
							controlledFields?.map((mod, index) => (
								<div key={mod.number}>
									{index > 0 && (
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
												height: 50,
											}}
											key={mod?.number}
										>
											<Typography sx={{ fontSize: 13 }}>
												{mod?.plu_name}
											</Typography>
											<Controller
												name={`plus[${index}].plu_details.price`}
												control={control}
												render={({
													field: { name, onChange, onBlur, value, ref },
												}) => (
													<TextField
														label={"Base Price"}
														size="small"
														type="text"
														sx={{ width: 90 }}
														InputLabelProps={{
															shrink: true,
														}}
														value={mod.plu_details.price}
														name={name}
														onChange={(event) => {
															event.target.value &&
																onChange(parseFloat(event.target.value));
														}}
														InputProps={{
															inputComponent: NumberFormatCustom,

															inputMode: "numeric",
															pattern: "[0-9]*",
															startAdornment: (
																<InputAdornment position="start">
																	£
																</InputAdornment>
															),
														}}
													/>
												)}
											/>
										</Box>
									)}
								</div>
							))}
					</Box>
				</>
			)}
		</Box>
		// <Box
		// 	sx={{
		// 		display: "flex",
		// 		flexDirection: "row",
		// 		justifyContent: "space-between",
		// 		justifySelf: "flex-end",
		// 	}}
		// >
		// 	<Button
		// 		variant="outlined"
		// 		size="medium"
		// 		sx={{ alignSelf: "flex-end" }}
		// 		onClick={resetForm}
		// 	>
		// 		Reset form
		// 	</Button>
		// 	<LoadingButton
		// 		size="small"
		// 		onClick={handleSubmit(onSubmit)}
		// 		loading={isSaving}
		// 		variant={!isSaving ? "contained" : "outlined"}
		// 		loadingPosition="start"
		// 		startIcon={<SaveIcon />}
		// 		disabled={isSaving}
		// 	>
		// 		<span>Save</span>
		// 	</LoadingButton>
		// </Box>
		// {errors?.root?.serverError?.type === 400 && (
		// 	<Box sx={{ alignSelf: "flex-end", mt: 2 }}>
		// 		<Typography sx={{ fontSize: 12, color: Colours.red }}>
		// 			Unable to save, please try again
		// 		</Typography>
		// 	</Box>
		// )}
		// </Box>
		// </Box>
		// </Modal>
	);
}

const style = {
	position: "absolute",

	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 410,
	maxHeight: "90vh",
	overflow: "scroll",
	backgroundColor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	justifyContent: "flex-start",
	flexDirection: "column",
	"&:focus": {
		outline: "none",
	},
};
