import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItemText,
	Modal,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import GridContext from "../../../context/GridContext";
import layouts from "../data/mainlayouts";
import OptionSelect from "./OptionSelect";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
	NumberFormatCustom,
	generatePLUNumber,
	getTextColour,
} from "../../datagrids/utils";
import { DataGridContext } from "../../../context/DataGridContext";
import { Colours } from "../../../assets/global/Theme-variable";
import { LoadingButton } from "@mui/lab";
import { modifiers } from "../data/modifiers";
import axios from "axios";
import SelectGroup from "../../../components/SelectGroup";
import RadioButtonsGroup from "../../../components/RadioGroup";
import { API_ENDPOINTS } from "../../../API/apiConfig";
import NewItemForm from "../../datagrids/DataGridRefactor/components/NewItemForm";
import { Config } from "../../datagrids/DataGridRefactor/Config";

export default function NewItemModal({
	setNewItemModalOpen,
	newItemModalOpen,
}) {
	const [isMod, setIsMod] = React.useState("Item");
	const { data, setData } = React.useContext(DataGridContext);

	const { getAccessTokenSilently } = useAuth0();
	const { setVariant, variant, openItem } = React.useContext(GridContext);

	const [isSaving, setIsSaving] = React.useState(false);

	// let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
	// 	object
	// ) {
	// 	return object?.id === openItem?.id;
	// });

	// const [plu, setPLU] = React.useState({
	// 	plu_name: "",
	// 	plu_details: {
	// 		salesGroup: "",
	// 		tags: [],
	// 		price: "",
	// 		priceModifiers: [],
	// 	},
	// 	plu_isDeleted: 0,
	// 	plu_number: "",
	// 	plu_ref: "",
	// 	plu_uid: "",
	// 	ref: "",
	// 	uid: "",
	// 	modifier: "",
	// });

	// const {
	// 	register,
	// 	control,
	// 	setError,
	// 	watch,
	// 	getValues,
	// 	resetField,
	// 	reset,
	// 	unregister,
	// 	handleSubmit,
	// 	isSubmitSuccessful,
	// 	setValue,
	// 	formState: { errors },
	// } = useForm({
	// 	defaultValues: React.useMemo(() => {
	// 		return plu;
	// 	}, [plu]),
	// 	shouldUnregister: true,
	// 	criteriaMode: "all",
	// });

	// useEffect(() => {
	// 	reset();
	// }, [isSubmitSuccessful, reset]);

	// const watchMod = watch(isMod);

	// const watchPLU = watch(plu);
	// const pluName = watch("new_name", false);
	// const price = watch(`plu_details.price`, false);
	// const salesGroup = watch(`plu_details.salesGroup`, false);
	// const tags = watch(`plu_details.tags`, false);

	// useEffect(() => {
	// 	reset(plu);
	// }, [plu]);

	// const resetForm = () => {
	// 	setPLU({
	// 		plu_name: "",
	// 		new_name: "",

	// 		plu_details: {
	// 			salesGroup: "",
	// 			tags: [],
	// 			price: "",
	// 			priceModifiers: [],
	// 		},
	// 		modifier: "",
	// 	});
	// 	reset();
	// };
	// const handlePLU = (event, newValue) => {
	// 	event.preventDefault();
	// 	const newPLU = {
	// 		plu_name: newValue?.plu_name,
	// 		// new_name: "",
	// 		plu_number: newValue?.plu_number,
	// 		plu_details: newValue?.plu_details,
	// 	};
	// 	setPLU(newPLU);
	// };

	// const modifiersFiltered = modifiers(data.modifier)?.filter(
	// 	(mod) =>
	// 		// band.priceband_details.isActive === true &&
	// 		!data?.items?.find(
	// 			(i) =>
	// 				i?.plu_number?.slice(0, -1) === plu?.plu_number?.slice(0, -1) &&
	// 				mod?.number === parseInt(i?.plu_number?.slice(-1))
	// 		)
	// );

	// const handleSwitch = (event, newValue) => {
	// 	// Handle the switch (toggling isMod) and reset other data if the switch is turned off.
	// 	resetForm();
	// 	setIsMod(newValue);

	// 	//console.log("dataGridValues - on mod change", dataGridValues);
	// 	if (!newValue) {
	// 		resetForm();
	// 	}
	// };

	// const handleMod = (event, newValue) => {
	// 	console.log(event.target.value, newValue);
	// 	setValue("new_name", `${event?.target?.value?.prefix} ${plu?.plu_name}`);
	// 	setValue(
	// 		"plu_number",
	// 		JSON.stringify(
	// 			parseInt(plu?.plu_number) + parseInt(event?.target.value?.number)
	// 		)
	// 	);
	// 	// const mod = modifiersFiltered.find(
	// 	// 	(m) => m.number === event.target.value?.number
	// 	// );
	// 	setValue("modifier", event.target.value);
	// };

	const onSubmit = async () => {
		setIsSaving(true);

		// setIsLoading(true);
			let form = methods.getValues();

		
		form = form.plus;
		// onst newItem = getValues();

		// const newRow = plu;
		// if (isMod) {
		// 	newRow.plu_number = JSON.stringify(
		// 		parseInt(plu?.plu_number) + parseInt(pluModifier?.number)
		// 	);
		// 	newRow.plu_name = modifierName;
		// }
		// delete newRow.plu_ref;
		// const modCopy = [...mods];

		// newItem.plu_name = newItem.new_name;
		// delete newItem.new_name;
		// delete newItem.modifier;
		// delete newItem.plu_ref;
		// delete newItem.plu_uid;
		// newItem.plu_details.priceModifiers = [];
		const token = await getAccessTokenSilently();
		// // const newData = [...data.items];
console.log(form)

	let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
		object
	) {
		return object?.id === openItem?.id;
	});
	const newRowData = {
		updates: Array.isArray(form) ? form : [form],
	};

		// const uniqueID = JSON.parse(data.items.at(-1).uid) + 1;

		// if (isMod === "ItemWithMod") {
		// 	mods?.map((mod, index) => {
		// 		mod.uid = JSON.stringify(JSON.parse(uniqueID) + (index + 1));
		// 		mod.plu_details.priceModifiers = [];
		// 		// newData.push(mod);
		// 		newRowData.updates.push(mod);
		// 	});
		// }
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.post(API_ENDPOINTS.plu, newRowData, config) // USING ENDPOINT FROM CONFIG
			.then(function (response) {
				let newItem = response.data.plu[0];
				response.data.plu.forEach(
					(plu) => (
						(plu.uid = plu.plu_uid),
						(plu.plu_number = plu.plu_number.toString())
					)
				);

				setData({
					...data,
					items: [...data.items, ...response?.data?.plu],
					// itemsAll: [...data.itemsAll, ...response?.data?.plu],
				});
				setVariant((draft) => {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newItem.plu_number
					);

					draft.list_details.buttons[buttonIndex].functionType = 21;
					draft.list_details.buttons[buttonIndex].text = newItem.plu_name;
				});
				setTimeout(() => {
					setIsSaving(false);
					setNewItemModalOpen(false);
					// setPLU({
					// 	plu_name: "",
					// 	new_name: "",

					// 	plu_details: {
					// 		salesGroup: "",
					// 		tags: [],
					// 		price: "",
					// 		priceModifiers: [],
					// 	},
					// 	modifier: "",
					// });
					// setIsMod("Item");
					// setSelectedMods({});
					// reset();
				}, 2000);
			})
			.catch(function (error) {
				console.log(error);
				setIsSaving(false);
				// setError("root.serverError", {
				// 	type: error?.response?.status,
				// });
			});
	};

	// const [selectedMods, setSelectedMods] = React.useState({});

	// const handleChangeSelectedMods = (event) => {
	// 	setSelectedMods({
	// 		...selectedMods,
	// 		[event.target.name]: event.target.checked,
	// 	});
	// };

	// function mapFn(value) {
	// 	const modObj = modifiers(data.modifier).find(
	// 		(mod) => mod.number === parseInt(value)
	// 	);
	// 	const newPLU = {};
	// 	newPLU.plu_name = `${modObj?.prefix} ${pluName}`;
	// 	const lastPLU = data["itemsAll"].reduce((a, b) =>
	// 		parseInt(a.plu_number) > parseInt(b.plu_number) ? a : b
	// 	).plu_number;
	// 	newPLU.plu_number = generatePLUNumber(lastPLU, modObj?.number);
	// 	newPLU.plu_details = {
	// 		price: parseFloat(price),
	// 		salesGroup: salesGroup,
	// 		tags: tags,
	// 	};

	// 	return newPLU;
	// }

	// function objectMap(obj, fn) {
	// 	return Object.entries(obj).map(([key, value]) => value === true && fn(key));
	// }

	// const mods = objectMap(selectedMods, mapFn).filter(
	// 	(mod) => typeof mod === "object"
	// );

	// const handleModPrice = (mod, event) => {
	// 	mod.plu_details.price = parseFloat(event.target.value);
	// };

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		width: 450,
		maxHeight: "90vh",
		// height: "100%",
		// minHeight:
		// 	// 100 +
		// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
		bgcolor: "white",
		boxShadow: 24,
		// p: 4,
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// padding: 0,
		gap: 2,
		flexDirection: "column",
	};

	const style2 = {
		// position: "absolute",
		// top: "50%",
		// left: "50%",
		// transform: "translate(-50%, -50%)",
		// borderRadius: "20px",
		// width: 450,
		// maxHeight: "90vh",
		height: "100%",
		width: "100%",

		p: 4,
		overflow: "hidden",
		display: "flex",
		justifyContent: "flex-start",
		gap: 2,
		flexDirection: "column",
		/* hide scrollbar but allow scrolling */
		// "*::-webkit-scrollbar": {
		// 	width: "6px",
		// },
		// "*::-webkit-scrollbar-track": {
		// 	backgroundColor: "#f5f5f5",
		// },
		// "-webkit-scrollbar-thumb": {
		// 	borderRadius: "10px",
		// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// 	// backgroundColor: "#f5f5f5",
		// },

		// -ms-overflow-style: 'none', /* for Internet Explorer, Edge */
		// scrollbarWidth: "none" /* for Firefox */,
		overflowY: "scroll",
		// "&-ms-overflow-style:": {
		// 	display: "none", // Hide the scrollbar for IE
		// },
		// "&-webkit-scrollbar": {
		// 	width: "6px",
		// },
		// "&-webkit-scrollbar-track": {
		// 	backgroundColor: "#f5f5f5",
		// },
		// "&-webkit-scrollbar-thumb": {
		// 	borderRadius: "10px",
		// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// 	// backgroundColor: "#f5f5f5",
		// },

		"&::-webkit-scrollbar": {
			width: "6px",
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: "#f5f5f5",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "10px",
			boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
			// backgroundColor: "#f5f5f5",
		},
	};
	let gridList = Config('items');

	const methods = useForm({
		defaultValues: gridList.defaultValues,
	});

	// const onSubmit = async () => {
	// 	let form = methods.getValues();

		
	// 		form = form.plus;
		
	// 	// if (modalConfig.isNew) {
	// 		// console.log("is new");
	// 		// setNewData(true);
	// 	}
	// 	setModalVisible(false);
	// };



	return (
		<FormProvider {...methods}>
		<Modal open={newItemModalOpen}>
		<form onSubmit={methods.handleSubmit(onSubmit)}>
		<Box sx={style}>
							<Box sx={style2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography variant="h3">
										New Item
									</Typography>

									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => {
											// setModalConfig({
											// 	focusField: "",
											// 	editRow: null,
											// 	open: false,
											// });
											setNewItemModalOpen(false);
											methods.reset();
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>

								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={2}
									sx={{ mt: 2, mb: 2 }}
								>
		 <NewItemForm methods={methods}/>
		 </Stack>

<Box
	sx={{
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	}}
>
	<Button
		variant="outlined"
		size="medium"
		sx={{ alignSelf: "flex-end" }}
		onClick={() => methods.reset()}
	>
		Reset form
	</Button>
	<Button
		variant="gradient"
		size="medium"
		sx={{ alignSelf: "flex-end" }}
		type="submit"
	>
		Submit
	</Button>
</Box>
</Box>
</Box>
		 </form>
		</Modal>
		</FormProvider>
	);
}

const style = {
	position: "absolute",

	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 410,
	maxHeight: "90vh",
	overflow: "scroll",
	backgroundColor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	justifyContent: "flex-start",
	flexDirection: "column",
	"&:focus": {
		outline: "none",
	},
};
