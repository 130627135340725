import { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { APP_URL } from "../API/apiConfig";
import SnackbarContext from "./SnackbarContext";
import BakingContext from "./BakingContext";

export const useBaking = (url) => {
	const [loading,setLoading] = useState(true);
	const [error, setError] = useState(null);
	// const [baking, setIsBaking] = useState([]);
const {setIsBaking} = useContext(BakingContext)

	const { getAccessTokenSilently, } = useAuth0();

	const refetch = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			const config = {
				headers: { Authorization: `Bearer ${token}` },
			};

			await axios.get(
                `${APP_URL}/api/pos/getBakeProgress?debug=true`,
                 config)
				.then((response) => {
					console.log("fe res useFetch", response);
					setIsBaking(response.data);
				})
				.catch((error) => {
					console.log("fe err useFetch", error);
				});
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [getAccessTokenSilently]);


	return {
		error,
		loading,
       refetch,
	};
};