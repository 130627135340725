import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	InputLabel,
	Modal,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React, { useContext } from "react";
import CustomTextField from "./CustomTextField";
import { Colours } from "../assets/global/Theme-variable";
import SelectList from "./SelectList";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGridContext } from "../context/DataGridContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import moment from "moment";
import axios from "axios";
import { API_ENDPOINTS } from "../API/apiConfig";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 400,
	minHeight: 400,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export default function IssueModal({ modalVisible, setModalVisible }) {
	const { getAccessTokenSilently } = useAuth0();
	const { loggedInUser } = useContext(DataGridContext);

	const {
		control,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			description: "",
			startsAt: moment().toDate(),
			further_action_req: false,
			note_site: "",
		},
	});

	// const onSubmit = (data) => {
	// 	console.log(data);
	// 	setModalVisible(false);
	// };

	const further_action_req = useWatch({
		control,
		name: "further_action_req",
	});

	// const generalNote = {
	// 	description: formData["Details"],
	// 	startsAt: moment().toDate(),
	// 	note_site: selectedSite,
	// 	further_action_req: formData["Further Action Required?"] === "Yes" ? 1 : 0,
	// };

	const postAction = (body) => {
		const url = API_ENDPOINTS.postActionNotes;
		axios.post(url, body).catch((error) => {
			if (error.response) {
				console.log("save note err", error.response);
				console.log("server responded");
			} else if (error.request) {
				console.log("network error");
			} else {
				console.log("save note err", error);
			}
		});

		// if (isPosting) {
		// // need to add loading icon in button
		// return <LoadingOverlay message="Logging you in..." />;
		// }
		// toggleForm(false);
	};

	const onSubmit = async (data) => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		const values = getValues();
		console.log("values", values);
		console.log("data", data);

		axios
			.post(API_ENDPOINTS.saveNotes, values, config)
			.then((response) => {
				console.log(response);
				const note_uid = response.data[0].note_uid;

				if (values["Further Action Required?"] === true) {
					console.log("here");
					const actionBody = {
						action_note: note_uid,
						action_status: 0,
						action_description: values["Action Required"],
						action_deadline:
							values["Action Required Before"] || moment().toDate(),
						action_owner: loggedInUser.app_metadata.user_ref, //needs to be changed to current user
						action_priority:
							values["Action Priority"] === "High"
								? 3
								: values["Action Priority"] === "Medium"
								? 2
								: 1,
					};
					postAction(actionBody);
				}
			})
			.catch((error) => {
				if (error.response) {
					console.log("post form", error.response);
					console.log("server responded");
				} else if (error.request) {
					console.log("network error");
				} else {
					console.log("post form", error);
				}
			});

		setModalVisible(false);
	};

	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Typography variant="h3" sx={{ colour: Colours.gsblue }}>
						Report Issue
					</Typography>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						X
					</Button>
				</Box>

				<Controller
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, onBlur, value } }) => (
						<CustomTextField
							placeholder="Start typing..."
							type="standard"
							multiline={true}
							value={value}
							// disabled={checkPermissions(userPermission, {
							// 	page: "items",
							// 	allow: false,
							// 	action: "edit",
							// })}
							onBlur={onBlur}
							onChange={onChange}
							label={
								<Typography
									sx={{
										fontSize: 18,
										mb: 0,
										color: Colours.gsblue,
										justifySelf: "center",
									}}
								></Typography>
							}
						/>
					)}
					name="description"
				/>
				{errors.description && <Typography>This is required.</Typography>}
				<Controller
					control={control}
					// rules={{
					// 	required: true,
					// }}
					render={({ field: { onChange, onBlur, value } }) => (
						<FormControlLabel
							control={<Checkbox checked={value} onChange={onChange} />}
							label={
								<Typography
									sx={{
										fontSize: 12,
										mb: 0,
										// color: Colours.gsblue,
										justifySelf: "center",
									}}
								>
									Further action required
								</Typography>
							}
							labelPlacement="left"
						/>
					)}
					name="further_action_req"
				/>

				{/* <SelectList
					options={options}
					setOption={setReason}
					option={reason}
					marginBottom={2}
					onChange={(e) => setReason(e.target.value)}
				/> */}
				{/* <CustomTextField
					placeholder="Start typing..."
					type="standard"
					multiline={true}
					// disabled={checkPermissions(userPermission, {
					// 	page: "items",
					// 	allow: false,
					// 	action: "edit",
					// })}
					// label={
					// 	<Typography
					// 		sx={{
					// 			fontSize: 18,
					// 			mb: 0,
					// 			color: Colours.gsblue,
					// 			justifySelf: "center",
					// 		}}
					// 	>
					// 		Report Issue
					// 	</Typography>
					// }
					onChange={(event) => {}}
				/> */}
				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					We greatly appreciate your feedback, and may use it to improve our
					solution.
				</Typography>
				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					Unfortunately we cannot reply to feedback, if you need to report an
					urgent issue requiring a response please contact us on:
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						support@gs-systems.co.uk
					</Typography>
					or
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						0161 483 5595
					</Typography>
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						justifySelf: "flex-end",
						mt: 2,
					}}
				>
					<Button
						variant="outlined"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(false)}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={handleSubmit(onSubmit)}
					>
						Send
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
