import { createTheme } from "@mui/material/styles";
import typography from "./Typography";
import shadows from "./Shadows";
import colours from "../../views/grid/data/colours";
import { red } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// ##############################

// // // Global Variables
// ##############################

const SidebarWidth = 200;
const TopbarHeight = 0;

const Colours = {
	gsblue: "#2a9ec5",
	gsgrey: "#969799",
	lightorange: "#FBAA68",
	burntorange: "#F97304",
	red: "#e53e3e",
	sage: "#6FADA2",
	teal: "#028A98",
	blue: "#d9ecf4",
	darkerblue: "#2a9ec5",
	white: "#F9F9F9",
	navy: "#02457A",
	midnight: "#001B48",
	lightBlue: "#e6efef",
	darkGrey: "#54595f",
	lighterGrey: "#969799",
	lightGrey: "#D8D8D6",
	fieldBackground: "#F3F6F9",
};

const baseTheme = createTheme({
	direction: "ltr",
	palette: {
		primary: {
			main: "#2a9ec5",
		},
		error: {
			main: "#fc4b6c",
		},
		success: {
			main: green[500],
		},
	},
	// palette: {
	// 	primary: {
	// 		main: "#2a9ec5",
	// 		light: "#e6f4ff",
	// 		error: "orange",
	// 	},
	// 	ochre: {
	// 		main: "#E3D026",
	// 		light: "#E9DB5D",
	// 		dark: "#A29415",
	// 		contrastText: "#242105",
	// 	},
	// secondary: {
	// 	main: "#54595f",
	// },
	// background: {
	// 	default: "#fff",
	// },
	// success: {
	// 	main: "#54595f",
	// 	contrastText: "black",
	// },
	// danger: {
	// 	main: "#fc4b6c",
	// },
	// error: {
	// 	main: "orange",
	// },
	// warning: {
	// 	main: "#fdd43f",
	// 	contrastText: "black",
	// },
	// text: {
	// 	secondary: "#777e89",
	// 	danger: "#54595f",
	// },
	// },
	shape: {
		borderRadius: 5,
	},

	components: {
		MuiCssBaseline: {
			styleOverrides: {
				"*": {
					boxSizing: "border-box",
				},
				html: {
					height: "100%",
				},
				body: {
					margin: 0,
					height: "100%",
					padding: 0,
				},
				"#root": {
					margin: 0,
					height: "100%",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: "",
				},
			},
		},

		MuiContainer: {
			styleOverrides: {
				root: {
					paddingLeft: "0px !important",
					paddingRight: "0px !important",
					flex: 1,
					height: "100%",
					display: "flex",
					overflow: "hidden",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "#2a9ec5",
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: "#fc4b6c",
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#54595f",
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					overflow: "auto !important",
					// "& .MuiBox": {
					// 	overflow: "auto !important",
					// },
				},
			},
		},

		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					overflow: "auto !important",
				},
			},
		},

		MuiInput: {
			styleOverrides: {
				root: {
					color: "#54595f !important",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					color: "#54595f  !important",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: "#54595f  !important",
				},
			},
		},
		MuiNumberInput: {
			styleOverrides: {
				root: {
					color: "#54595f  !important",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					color: "#54595f  !important",
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					color: "#54595f",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					boxShadow: "none",
					cursor: "pointer",
					// color: "#fff",
					// // backgroundSize: "200%",
					// transition: "0.4s",
					// backgroundImage:
					// 	"linear-gradient(to right, #2a9ec5 0%, #77bbd7  51%, #2a9ec5  100%)",
					// backgroundSize: "200% auto",
					minWidth: "10px",
					// // #6FADA2,
					"&:hover": {
						backgroundColor: Colours.gsblue,
						color: "#ffff",
						boxShadow: "0 3px 5px 2px rgba(217, 220, 222, .61)",
					},
				},
			},
			variants: [
				{
					props: { variant: "gradient" },
					style: {
						textTransform: "none",
						boxShadow: "none",
						cursor: "pointer",
						color: "#fff",
						// backgroundSize: "200%",
						transition: "0.4s",
						backgroundImage:
							"linear-gradient(to right, #2a9ec5 0%, #77bbd7  51%, #2a9ec5  100%)",
						backgroundSize: "200% auto",

						// #6FADA2,
						"&:hover": {
							backgroundPosition: "right center",
							boxShadow: "0 3px 5px 2px rgba(217, 220, 222, .61)",
						},
					},
				},
				{
					props: { variant: "bordered" },
					style: {
						textTransform: "none",
						cursor: "pointer",
						color: "#2a9ec5",
						boxShadow:'inset 0px 0px 0px 1px #2a9ec5',
						transition: "0.4s",
						backgroundImage:
							"linear-gradient(to right, #f5f6f7 0%, #fff  51%, #f5f6f7  100%)",
						backgroundSize: "200% auto",
						"&:hover": {
							backgroundPosition: "right center",
							color: "#2a9ec5",
							boxShadow:'inset 0px 0px 0px 1px #2a9ec5',
						},
					},
				},
			],
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "34px",
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				IconComponent: ArrowDropDownIcon,
				//there are tons of props that you can override
			},
			styleOverrides: {
				root: {
					".MuiSvgIcon-root": {
						// color: 'your color',
						color: "#2a9ec5",
					},
				},
			},
		},

		MuiCard: {
			styleOverrides: {
				root: {
					// borderRadius: "20px",
					paddingLeft: "20px",
					paddingRight: "20px",

					// margin: "15px",
					flex: 1,
				},
			},
		},
	},

	mixins: {
		toolbar: {
			color: Colours.gsblue,
			"@media(min-width:1280px)": {
				minHeight: TopbarHeight,
				padding: "0 30px 0px 0px ",
			},
			"@media(max-width:1280px)": {
				minHeight: "64px",
			},
		},
	},

	status: {
		danger: "#fc4b6c",
	},
	typography,
	shadows,
});

export { TopbarHeight, SidebarWidth, baseTheme, Colours };
