import React, { Component, createContext, useState } from "react";
import listOfLists from "../views/grid/data/listOfLists";
import sampleItems from "../views/grid/data/sampleItems";

export function move(array, oldIndex, newIndex) {
	if (newIndex >= array.length) {
		newIndex = array.length - 1;
	}
	array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
	return array;
}

export function moveElement(array, index, offset) {
	const clonedArr = JSON.parse(JSON.stringify(array));
	const newIndex = index + offset;

	console.log("new index", newIndex);
	return move(clonedArr, index, newIndex);
}

// function moveElement(array, index, offset) {
// 	const newIndex = index + offset;

// 	return move(array, index, newIndex);
// }

const GridContext = createContext();

// export class GridProvider extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			items: props.list.config,
// 			moveItem: this.moveItem,
// 			setItems: this.setItems,
// 		};
// 	}

// 	render() {
// 		return (
// 			<GridContext.Provider value={this.state}>
// 				{this.props.children}
// 			</GridContext.Provider>
// 		);
// 	}

// 	setItems = (items) => this.setState({items});
// 	moveItem = (sourceId, destinationId) => {
// 		const sourceIndex = this.state.items.findIndex(
// 			(item) => item.id === sourceId
// 		);
// 		const destinationIndex = this.state.items.findIndex(
// 			(item) => item.id === destinationId
// 		);

// 		if (sourceId === -1 || destinationId === -1) {
// 			return;
// 		}

// 		const offset = destinationIndex - sourceIndex;

// 		this.setState((state) => ({
// 			items: moveElement(state.items, sourceIndex, offset),
// 		}));
// 	};
// }

// export default GridContext;

// const GridContext = React.createContext({items: []});
// export const setItems = (items) => setListItems(items);

// export const GridProvider = (props) => {
// 	const [items, setItems] = useState(listOfLists[0].config);

// 	const setListItems = (items) => {
// 		setItems(items);
// 	};

// 	const moveItem = (sourceId, destinationId) => {
// 		const sourceIndex = items.findIndex((item) => item.id === sourceId);
// 		const destinationIndex = items.findIndex(
// 			(item) => item.id === destinationId
// 		);

// 		if (sourceId === -1 || destinationId === -1) {
// 			return;
// 		}

// 		const offset = destinationIndex - sourceIndex;
// 		setItems(moveElement(items, sourceIndex, offset));
// 	};

// 	// React.useEffect(() => {
// 	// 	setListItems(props.list.config);
// 	// }, [moveItem]);

// 	console.log("context", items);

// 	return (
// 		<GridContext.Provider value={{items, setListItems, moveItem}}>
// 			{props.children}
// 		</GridContext.Provider>
// 	);
// };

// export {GridContext, GridProvider};
export default GridContext;
