import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import MainButton from "../components/Button";
import { MdLockOutline, MdEdit, MdLockOpen } from "react-icons/md";
import { IconButton } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import LinkedAccounts from "./profile/LinkedAccounts";
import { getConfig } from "./profile/config";
import { useFetch } from "./profile/useFetch";
import { linkContext } from "./profile/context";
import Spinner from "../components/Spinner/Spinner";
import RouterBreadcrumbs from "../components/Breadcrumbs";
// import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import ImageUpload from "../components/ImageUpload";
import S3 from "react-aws-s3";
import { FiSave } from "react-icons/fi";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { APP_URL } from "../API/apiConfig";
import { DataGridContext } from "../context/DataGridContext";

export default function Profile({ open, setOpenProfile }) {
	const { loading, error, refetch } = useFetch();
	const { getAccessTokenSilently, getIdTokenClaims, isLoading } = useAuth0();
	const {
		loggedInUser,
		name,
		setName,
		nickname,
		setNickame,
		role,
		setRole,
		email,
		setEmail,
		photo,
		setPhoto,
	} = useContext(DataGridContext);
	const [disabledName, toggleDisabledName] = React.useState(true);
	const [disabledDisplayName, toggleDisabledDisplayName] = React.useState(true);

	const { apiOrigin = APP_URL, audience } =
		getConfig();// using API from configuration
	const [isSaving, setIsSaving] = useState(false);
	const userid = loggedInUser && loggedInUser.user_id;
	// const [name, setName] = useState("");
	// const [nickname, setNickame] = useState("");
	// const [role, setRole] = useState("unknown");
	// const [photo, setPhoto] = useState("");
	// const [email, setEmail] = useState("");
	const [showIcon, setShowIcon] = useState("");

	React.useEffect(() => {
		if (loggedInUser) {
			setNickame(loggedInUser?.nickname);
			setName(loggedInUser?.name);
			loggedInUser?.user_metadata &&
				loggedInUser?.user_metadata.role &&
				setRole(loggedInUser?.user_metadata.role);
			setPhoto(loggedInUser?.picture);
			setEmail(loggedInUser?.email);
		}
	}, [loggedInUser]);

	const saveUserEdits = async () => {
		setIsSaving(true);
		name !== loggedInUser?.name && (await updateName(name));
		nickname !== loggedInUser?.nickname && (await updateNickname(nickname));
		photo !== loggedInUser?.picture && updatePhoto(photo);
		role !== loggedInUser?.user_metadata?.role && updateRole(role);
		refetch();
		setIsSaving(false);
	};

	const updateName = async (name) => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		};

		const data = {
			userid: userid,
			data: { name: name },
		};

		await axios
			.post(`${apiOrigin}/api/pos/updatename`, data, config)
			.then((response) => {
				console.log("fe res", response);
			})
			.catch((error) => {
				console.log("fe err", error);
			});
	};
	const config = {
		bucketName: process.env.REACT_APP_BUCKET_NAME,
		region: process.env.REACT_APP_REGION,
		accessKeyId: process.env.REACT_APP_ACCESS,
		secretAccessKey: process.env.REACT_APP_SECRET,
		GENERATE_SOURCEMAP: false,
	};

	const handleFileInput = (e) => {
		const ReactS3Client = new S3(config);
		const fileName = loggedInUser.user_id;
		ReactS3Client.uploadFile(e.target.files[0], fileName)
			.then((data) => {
				console.log(data);
				setPhoto(data.location);
				updatePhoto(data.location);
			})
			.catch((err) => console.log(err));
	};

	const handleFileDelete = (e) => {
		const ReactS3Client = new S3(config);
		ReactS3Client.deleteFile(loggedInUser.user_id)
			.then((data) => {
				// setPhoto(
				// 	"https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
				// );
				updatePhoto(
					"https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
				);
				setPhoto(
					"https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
				);
			})
			.catch((err) => console.log(err));
		refetch();
	};

	const updatePhoto = async (photo) => {
		const token = await getAccessTokenSilently();
		const body = {
			userid: userid,
			data: {
				picture: photo ? photo : null,
			},
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		await axios
			.post(`${apiOrigin}/api/pos/updateavatar`, body, config)
			.then((response) => {})
			.catch((error) => {
				console.log("fe err", error);
			});

		refetch();
	};

	const updateNickname = async (displayName) => {
		const token = await getAccessTokenSilently();
		const body = {
			userid: userid,
			data: { nickname: nickname },
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		await axios
			.post(`${apiOrigin}/api/pos/updatedisplayname`, body, config)
			.then((response) => {
				console.log("fe res", response);
			})
			.catch((error) => {
				console.log("fe err", error);
			});
	};
	const updateRole = async (role) => {
		const token = await getAccessTokenSilently();
		const body = {
			userid: userid,
			data: { role: role },
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		await axios
			.post(`${apiOrigin}/api/pos/updatemetadata`, body, config)
			.then((response) => {
				console.log("fe res", response);
			})
			.catch((error) => {
				console.log("fe err", error);
			});
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (!loggedInUser) {
		return <Spinner />;
	}

	return (
		<Box
			sx={{
				flex: 1,
				minHeight: "100%",
				width: "100%",
				ml: 4,
				mr: 4,
				pt: 1,
			}}
		>
			<Box
				sx={{
					width: "100%",
					mb: 2,
					mt: 2,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<RouterBreadcrumbs />
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "space-between",
					justifyContent: "space-between",
					mb: 2,
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						mb: 0,
						width: "20%",
					}}
				>
					<ImageUpload
						handleFileInput={handleFileInput}
						handleFileDelete={handleFileDelete}
						item={loggedInUser}
						image={photo}
						setImage={setPhoto}
						variant="round"
					/>
				</Box>
				<Box
					sx={{
						width: "80%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						gap: 1,
					}}
				>
					<Box
						sx={{
							justifyContent: "flex-start",
							display: "flex",
							flexDirection: "row",
							width: "100%",
							alignItems: "center",
							gap: 2,
						}}
						onMouseEnter={() => setShowIcon("name")}
						onMouseLeave={() => setShowIcon("")}
					>
						<Box
							sx={{ width: "30%", display: "flex", justifyContent: "flex-end" }}
						>
							<Typography variant="body1">Full Name</Typography>
						</Box>

						<TextField
							size="small"
							value={name}
							onChange={(event) => {
								setName(event.target.value);
							}}
							fullWidth
							sx={{ width: "90%" }}
							disabled={disabledName}
						/>
						<Box sx={{ width: "10%" }}>
							<IconButton
								aria-label="edit name"
								onClick={() => toggleDisabledName(!disabledName)}
								sx={{
									display: showIcon === "name" ? "flex" : "none",
								}}
							>
								<MdEdit size={20} color={Colours.gsblue} />
							</IconButton>{" "}
						</Box>
					</Box>
					<Box
						sx={{
							justifyContent: "flex-start",
							display: "flex",
							flexDirection: "row",
							width: "100%",
							alignItems: "center",
							gap: 2,
						}}
						onMouseEnter={() => setShowIcon("displayname")}
						onMouseLeave={() => setShowIcon("")}
					>
						<Box
							sx={{ width: "30%", display: "flex", justifyContent: "flex-end" }}
						>
							<Typography>Display Name</Typography>
						</Box>
						<TextField
							size="small"
							fullWidth
							sx={{ width: "90%" }}
							value={nickname}
							onChange={(event) => {
								setNickame(event.target.value);
							}}
							disabled={disabledDisplayName}
						/>
						<Box sx={{ width: "10%" }}>
							<IconButton
								aria-label="edit name"
								onClick={() => toggleDisabledDisplayName(!disabledDisplayName)}
								sx={{
									display: showIcon === "displayname" ? "flex" : "none",
								}}
							>
								<MdEdit size={20} color={Colours.gsblue} />
							</IconButton>
						</Box>
					</Box>

					<Box
						sx={{
							justifyContent: "flex-start",
							display: "flex",
							flexDirection: "row",
							width: "100%",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Box
							sx={{ width: "30%", display: "flex", justifyContent: "flex-end" }}
						>
							<Typography>Role</Typography>
						</Box>
						<TextField
							size="small"
							onChange={(event) => {
								setRole(event.target.value);
							}}
							sx={{ width: "90%" }}
							value={role}
							disabled
							fullWidth
						/>
						<Box sx={{ width: "10%" }}>
							<IconButton
								aria-label="edit name"
								onClick={() => toggleDisabledName(!disabledName)}
								sx={{ width: "10%" }}
								disabled
							>
								<MdEdit size={20} color={"white"} />
							</IconButton>
						</Box>
					</Box>
					<Box
						sx={{
							width: "100%",
							justifyContent: "flex-start",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Box
							sx={{ width: "30%", display: "flex", justifyContent: "flex-end" }}
						>
							<Typography>Email Address</Typography>
						</Box>
						<TextField
							size="small"
							sx={{ width: "90%" }}
							fullWidth
							value={email}
							disabled
						/>
						<Box sx={{ width: "10%" }}></Box>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "100%",
				}}
			>
				<LoadingButton
					size="small"
					onClick={saveUserEdits}
					loading={isSaving}
					variant={!isSaving ? "contained" : "outlined"}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					disabled={isSaving}
				>
					<span>Save</span>
				</LoadingButton>
				<Box sx={{ width: "7%" }}></Box>
			</Box>
			{/* <Box sx={{display: "flex", flexDirection: "column"}}>

					<Button onClick={callProtectedApi}>protected</Button>
					<Button onClick={callPublicApi}>public</Button>
					<Button onClick={callProtectedApi}>scoped</Button>

					<Button onClick={identifyUser}>get user info </Button>
					<Button onClick={updateUser}>update user </Button>
					<Button onClick={updateRole}>update role </Button>			
				</Box> */}
			<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<LinkedAccounts loggedInUser={loggedInUser} />
			</Box>
		</Box>
	);
}
