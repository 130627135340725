// import axios from "axios";
// import React, { useContext, useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
// import { useFetch } from "../views/profile/useFetch";
// import { sorter, sorterPlu, contextDeletedFilter } from "../views/datagrids/utils";
// import { presetServers } from "../views/grid/data/presetServers";
// import { APP_URL } from "../API/apiConfig";
// import SnackbarContext from "./SnackbarContext";

import { createContext } from "react";

// const BakingContext = React.createContext();

// const BakingContextProvider = (props) => {
// 	// const { getAccessTokenSilently } = useAuth0();
// 	// const { error, loggedInUser, isAuthenticated } = useFetch();
//     const [baking, setIsBaking] = React.useState(false)

	
// 	// console.log(userPermission);

// 	// React.useEffect(() => {
//     //     console.log('here', baking)
        
//     //  if(baking === true) {  checkBakeProgress() }
// 	// }, [baking]);

// 	// React.useEffect(() => {
// 	// 	if (userPermission !== null) {
// 	// 		fetchArticlesSequentially();
// 	// 	}
// 	// 	if (newData) {
// 	// 		setNewData(false);
// 	// 	}
// 	// }, [isAuthenticated, newData, userPermission]);

// 	// const checkBakeProgress = async () => {
	
// 	// 	console.log("getting token silently");
// 	// 	const token = await getAccessTokenSilently();
// 	// 	// console.log("gotten token silently");

// 	// 	const config = {
// 	// 		headers: { Authorization: `Bearer ${token}` },
// 	// 	};
// 	// 		// const api = `${APP_URL}/api/pos/getBakeProgress?debug=true`;
// 	// 		// const response = await axios.get(api, config);
// 	// 		// const res = await response.data.rows;
// 	// 		// fetchedDataSets.push(res);
//     //         axios
//     //         .get(
//     //            `${APP_URL}/api/pos/getBakeProgress?debug=true`,
//     //             config
//     //         )
//     //         .then(function (response) {
//     //             console.log("res", response);
//     //            if(response.data.length === 0) { 
//     //             console.log('shouldnt redo')

//     //             setIsBaking(false);
//     //            } else {
//     //             console.log('should redo')
//     //            }

//     //         })
//     //         .catch(function (error) {
//     //             console.log("res", error);
//     //         });

// 	// };
// //     const [isSyncing, setIsSyncing] = useState([]);
// // const {setSnackbar} = useContext(SnackbarContext)
// //     React.useEffect(() => {
// //         if(baking.length > 0 && isSyncing === true) {
// //             setTimeout(() => {
// //             refetch()
// //         }, 5000);
// //         } else if(baking.length === 0 && isSyncing === true) {
// //             setTimeout(() => {
// //                 setIsSyncing(false);
// //                 // setSnackbarOpen(true);
// //                 setSnackbar({
// //                     message: ["Successfully synced with MarketMan"],
// //                     severity: "success",
// //                     open:true,
// //                     duration: 8000,
        
// //                 });
// //             })
// //         }
// //             }, [refetch, baking, setIsBaking]);


// // 	return (
// // 		<BakingContext.Provider
// // 			value={{
// // 				baking, setIsBaking
// // 			}}
// // 		>
// // 			{props.children}
// // 		</BakingContext.Provider>
// // 	);
// // };

// // export { BakingContext, BakingContextProvider };import { createContext} from "react";

const BakingContext = createContext();

export default BakingContext;
