import colours from "../grid/data/colours";
import NumberFormat, { NumericFormat } from "react-number-format";
import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { getGridSingleSelectOperators } from "@mui/x-data-grid";
import { TagConfig } from "./data grid files/TagConfig";
import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { LightTooltip } from "./data grid files/CustomComponents";
import { Colours } from "../../assets/global/Theme-variable";
import ErrorIcon from "@mui/icons-material/Error";
import { StyledTag } from "../../components/StyledTag";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import CustomTextField from "../../components/CustomTextField";
import { checkPermissions } from "../AccessControl";

export const initialColours = [
	"#15499F",
	"#2B74B9",
	"#478ECC",
	"#70B2E2",
	"#96C8ED",
	"#BEDEF5",
	"#0D6064",
	"#1998A7",
	"#1BBDD4",
	"#58C7DA",
	"#88D4E2",
	"#B6E2ED",
	"#0C4E42",
	"#0B7A6A",
	"#14988B",
	"#48B8AC",
	"#7FCCC6",
	"#B3E0DD",
];

Array.prototype.random = function () {
	return this[Math.floor(Math.random() * this.length)];
};

export const fillList = (list) => {
	const cellCount = list.list_details.cols * list.list_details.rows;

	for (let i = 0; i < cellCount; i++) {
		const colour1 = colours.random();
		const colour2 = colours.random();

		list.config.push({
			// id: i,
			// index: i,
			// colour: colour,
			// text: "EMPTY",
			// row: 1,
			// col: 1,
			// buttonSize: "1x1",
			// textAlign: "center",
			// textColour: getTextColor(colour),
			// fontSize: 12,
			// funct: {name: "", number: ""},
			// buttonType: "PLU",

			id: i,
			index: i,
			style: {
				// colour: colour.fieldIndex,
				background: colour1.fieldIndex,
				foreground: colour2.fieldIndex,
				alignmentW: 1,
				alignmentH: 1,
				font: "10B",
			},
			text: "",
			mx: 1,
			my: 1,
			pageNo: 1,
			size: 1,
			// row: 1,
			// col: 1,
			// buttonSize: "1x1",
			// textAlign: "center",
			// textColour: getTextColor(colour),
			// fontSize: 12,
			// funct: {
			// 	name: items[i]?.text !== undefined ? items[i].text : "",
			// 	number: items[i]?.plu_number !== undefined ? items[i].plu_number : "",
			// },
			functionType: "",
			functionValue: "",
			// buttonType: "PLU",
			// boldText: false,
			hideText: 0,
		});
	}
};

export const fillExistingList = (list, variant, maxCells) => {
	if (variant !== undefined) {
		const lastItem = variant.config.at(-1).id + 1;
		for (let i = lastItem; i < maxCells; i++) {
			const colour1 = colours.random();
			const colour2 = colours.random();

			variant.config.push({
				id: i,
				index: i,
				colour: colour1.fieldIndex,
				text: "Empty",
				row: 1,
				col: 1,
				buttonSize: "1x1",
				textAlign: "center",
				textColour: getTextColour(colour1),
				fontSize: 12,
				funct: { name: "", number: "" },
				buttonType: "PLU",
			});
		}
	} else {
		const lastItem = list.config.at(-1).id + 1;
		for (let i = lastItem; i < maxCells; i++) {
			const colour = initialColours.random();
			list.config.push({
				id: i,
				index: i,
				colour: colour,
				text: "Empty",
				row: 1,
				col: 1,
				buttonSize: "1x1",
				textAlign: "center",
				textColour: getTextColour(colour),
				fontSize: 12,
				funct: { name: "", number: "" },
				buttonType: "PLU",
			});
		}
	}
};

export function getTextColour(
	bgColour,
	lightColour = { index: 16, colour: "#FFFFFF" },
	darkColour = { index: 1, colour: "#000000" }
) {
	const getLuminance = function (hexColour) {
		var colour =
			hexColour.charAt(0) === "#" ? hexColour.substring(1, 7) : hexColour;
		var r = parseInt(colour.substring(0, 2), 16); // hexToR
		var g = parseInt(colour.substring(2, 4), 16); // hexToG
		var b = parseInt(colour.substring(4, 6), 16); // hexToB
		var uicolours = [r / 255, g / 255, b / 255];
		var c = uicolours.map((col) =>
			col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4
		);

		return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	};

	var L = getLuminance(bgColour);
	var L1 = getLuminance(lightColour.colour);
	var L2 = getLuminance(darkColour.colour);

	return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05
		? darkColour
		: lightColour;
}

export const autoFill = (list, items, colours) => {
	const cellCount = list.list_details.cols * list.list_details.rows;

	const pages =
		Math.round(items?.length / cellCount) > 0
			? Math.round(items?.length / cellCount)
			: 1;

	let buttonArr = [];
	for (let index = 1; index <= pages; index++) {
		buttonArr.push({
			page: index,
			min: cellCount * (index - 1),
			max: cellCount * index - 1,
		});
	}
	const maxCells = cellCount * pages - 1;

	list.list_details.pages = pages;

	for (let i = 0; i <= maxCells; i++) {
		// const colour1 = colours[0].palette_details.colours.random();
		const colour1 = colours[0].palette_details.colours.find(
			(col) => col.colour === "#000000"
		);

		const colour2 = getTextColour(colour1.colour);

		list.list_details.buttons.push({
			id: i,
			index: i,
			style: {
				background: colour1.index,
				foreground: colour2.index,
				alignmentV: 1,
				alignmentH: 1,
				font: "10B",
			},
			text: items[i]?.plu_name !== undefined ? items[i].plu_name : "",
			mx: 1,
			my: 1,
			pageNo: 1,
			size: 1,
			functionType: items[i]?.plu_number !== undefined ? 21 : 0,
			functionValue:
				items[i]?.plu_number !== undefined ? parseInt(items[i].plu_number) : 0,
			hideText: 0,
		});
	}

	list.list_details.buttons.map((x, index) => {
		const page = buttonArr?.find(
			(button) => index >= button.min && index <= button.max
		);
		x.pageNo = page?.page;
	});

	console.log(list.list_details.buttons);
};

export const newPageFill = (list, items, colours) => {
	const cellCount = list.list_details.cols * list.list_details.rows;
	const pages = list.list_details.pages + 1;

	let buttonArr = [];
	for (let index = 1; index <= pages; index++) {
		buttonArr.push({
			page: index,
			min: cellCount * (index - 1),
			max: cellCount * index - 1,
		});
	}
	console.log("button arr", buttonArr);

	const maxCells = cellCount - 1;
	console.log("max cells", maxCells);

	list.list_details.pages = pages;

	for (let i = 0; i <= maxCells; i++) {
		// const colour1 = colours[0].palette_details.colours.random();
		const colour1 = colours[0].palette_details.colours.find(
			(col) => col.colour === "#000000"
		);
		const colour2 = getTextColour(colour1.colour);
		let lastElement = list.list_details.buttons.length;
		console.log("last element", lastElement);
		list.list_details.buttons.push({
			id: JSON.stringify(lastElement),
			index: lastElement,
			style: {
				background: colour1.index,
				foreground: colour2.index,
				alignmentV: 1,
				alignmentH: 1,
				font: "10B",
			},
			text: items[i]?.plu_name !== undefined ? items[i].plu_name : "",
			mx: 1,
			my: 1,
			pageNo: 1,
			size: 1,
			functionType: items[i]?.plu_number !== undefined ? 21 : 0,
			functionValue:
				items[i]?.plu_number !== undefined ? parseInt(items[i].plu_number) : 0,
			hideText: 0,
		});
	}

	list.list_details.buttons.map((x, index) => {
		const page = buttonArr?.find(
			(button) => index >= button.min && index <= button.max
		);
		x.pageNo = page?.page;
		return x;
	});
};

export function groupBy(data, array, f) {
	let categories = {};
	array.forEach(function (o) {
		var category = JSON.stringify(f(o));
		categories[category] = categories[category] || [];
		categories[category].push(o);
	});
	return Object.keys(categories)
		.map(function (group) {
			let sgNo = JSON.parse(group);
			// console.log("sg", group);
			let majorGroup = data?.categories?.find(
				(group) => JSON.parse(group?.salesGroup_number) === sgNo[0]
			);

			let catName = majorGroup?.salesGroup_name;

			var group2 = {};
			group2["group"] = catName;
			group2["items"] = categories[group];
			// console.log("group2", group2);
			return group2;
		})
		.filter((group) => group.group !== undefined);
}

export const NumberFormatCustom = React.forwardRef((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props?.name,
						value: values?.value,
					},
				});
			}}
			thousandSeparator
			decimalScale={2}
		/>
	);
});

// export default NumberFormatCustom;

export const makeHex = (decimalcolour) => {
	// const decimal = colours.find(
	// 	(col) => col.fieldIndex === decimalcolour
	// ).iValue;
	// console.log(colours.find((col) => col.fieldIndex === decimalcolour).iValue);
	// console.log("dec", decimal);
	var hexColour = decimalcolour.toString(16);
	while (hexColour.length < 6) {
		hexColour = "0" + hexColour;
	}
	hexColour =
		hexColour.substring(4, 6) +
		hexColour.substring(2, 4) +
		hexColour.substring(0, 2);
	return "#" + hexColour;
};

export const turnBack = (hex) => {
	var decimalcolour = hex.replace("#", "");
	decimalcolour =
		decimalcolour.substring(4, 6) +
		decimalcolour.substring(2, 4) +
		decimalcolour.substring(0, 2);

	return parseInt(decimalcolour, 16);
};

export const lerp = (min, max, percentage) =>
	min * (1 - percentage) + max * percentage;

export const debounce = (callback: (...args) => wait) => {
	let timer;

	return (...args) => {
		clearTimeout(timer);
		return new Promise((resolve) => {
			timer = setTimeout(() => resolve(callback(...args)), wait);
		});
	};
};

export const generatePLUNumber = (lastPLU, mod) => {
	// 12230 - 12240 - correct
	// 12231 type half - 12241 - correct
	// 12231 type double - 12242 - correct

	let rounded = Math.ceil((parseInt(lastPLU) + 1) / 10) * 10;
	rounded += mod;
	console.log(lastPLU);
	console.log(rounded);
	// type === "standard"
	// 	? Math.ceil((lastPLU + 1) / 10) * 10
	// 	:
	// type === "half"
	// 	? Math.ceil((lastPLU + 1) / 10) * 10 + 1
	// 	: type === "double"
	// 	? Math.ceil((lastPLU + 1) / 10) * 10 + 2
	// 	: Math.ceil((lastPLU + 1) / 10) * 10;

	return JSON.stringify(rounded);
};

export function isInList(arr, plu_number) {
	return arr.some(
		(arrVal) =>
			plu_number === arrVal.functionValue && arrVal.functionType === 21
	);
}

export const appearsOn = (data, plu_number) => {
	const arr = data?.filter((list) => {
		let opt = isInList(list?.list_details?.buttons, parseInt(plu_number));
		if (opt === true) {
			return list;
		}
	});
	return arr;
};

export const sorter = (arrayToBeSorted, sortByColumn) => {
	arrayToBeSorted.sort((a, b) => {
		if (parseInt(a[sortByColumn]) < parseInt(b[sortByColumn])) return -1;
		if (parseInt(a[sortByColumn]) > parseInt(b[sortByColumn])) return 1;
		return 0;
	});
	return arrayToBeSorted;
};

export const contextDeletedFilter = (
	arrayToBeSorted,
	name,
	apiName,
	permissions
) => {
	const showDeleted = checkPermissions(permissions, {
		page: name,
		// allow: true,
		action: "undelete",
		strict: true,
	});
	let sortedArr =
		showDeleted === true
			? arrayToBeSorted.filter(
					(a) =>
						a[`${apiName}_isDeleted`] === 0 || a[`${apiName}_isDeleted`] === 1
			  )
			: arrayToBeSorted.filter((a) => a[`${apiName}_isDeleted`] === 0);
	// console.log(arrayToBeSorted);
	return sortedArr;
};

export const sorterPlu = (arrayToBeSorted) => {
	arrayToBeSorted.sort((a, b) => {
		if (parseInt(a.plu_details.salesGroup) < parseInt(b.plu_details.salesGroup))
			return -1;
		if (parseInt(a.plu_details.salesGroup) > parseInt(b.plu_details.salesGroup))
			return 1;
		if (
			parseInt(a.plu_details.salesGroup) == parseInt(b.plu_details.salesGroup)
		) {
			if (parseInt(a.plu_number) < parseInt(b.plu_number)) return -1;
			if (parseInt(a.plu_number) > parseInt(b.plu_number)) return 1;
		}

		return 0;
	});
	return arrayToBeSorted;
};

export const deletedFilter = (data, dataType) => {
	return data.filter((item) => item[`${dataType}_isDeleted`] === 0);
};

export const useRouteMatch = (patterns) => {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}
	return null;
};

export const setSubField = (subFieldName1, subFieldName2, subFieldName3) => {
	// The setSubField function updates a nested field with a new value
	return (params) => {
		if (subFieldName3 === undefined) {
			const field = { ...params?.row[subFieldName1] };
			field[subFieldName2] =
				typeof params?.value === "boolean" && params.value === true
					? 1
					: typeof params?.value === "boolean" && params.value === false
					? 0
					: params.value;

			const field2 = { ...params?.row };
			field2[subFieldName1] = field;
			return { ...field2 };
		} else {
			const field = { ...params?.row[subFieldName1] };
			field[subFieldName2][subFieldName3] =
				typeof params?.value === "boolean" && params.value === true
					? 1
					: typeof params?.value === "boolean" && params.value === false
					? 0
					: params.value;

			const field2 = { ...params?.row };
			field2[subFieldName1] = field;
			return { ...field2 };
		}
	};
};

export const setMajorCategory = (subFieldName1, subFieldName2) => {
	// The setSubField function updates a nested field with a new value
	return (params) => {
		// console.log(params.value);
		const field = { ...params?.row[subFieldName1] };
		// console.log(field);
		field[subFieldName2] = parseInt(params?.value);
		const field2 = { ...params?.row };
		// console.log(field2);
		field2[subFieldName1] = field;
		return { ...field2 };
	};
};

export const setUserLevel = (subFieldName1) => {
	// The setSubField function updates a nested field with a new value
	return (params) => {
		// console.log("value", params.value);
		const field = { ...params?.row[subFieldName1] };
		// console.log("field", field);

		// console.log("row", params.row);
		// console.log("subfieldname", subFieldName1);
		// field[subFieldName2] = parseInt(params?.value);
		const field2 = { ...params?.row };
		// field2[subFieldName1] = params.value;
		field2[subFieldName1].userLevel = params.value.userLevel;
		field2[subFieldName1].cashDrawer = params.value.cashDrawer;
		field2[subFieldName1].trainingServer = params.value.trainingServer;

		// console.log("field2", field2);
		// field2[subFieldName1] = field;
		return { ...field2 };
	};
};

export const getSubField = (params) => {
	// console.log(params);
	// The getSubField function extracts the value of a nested field
	const [fieldName, subFieldName, subFieldName2] = params?.field
		?.toString()
		.split(".");
	const field = params?.row[fieldName];
	// console.log("fn, sfn", fieldName, subFieldName);

	// console.log("params", params);
	// console.log(field[subFieldName]);
	return field && subFieldName && subFieldName2
		? field[subFieldName][subFieldName2]
		: field && subFieldName
		? field[subFieldName]
		: null;
};

export const getModVal = (params, row) => {
	// console.log(params, row);
	// The getSubField function extracts the value of a nested field
	const [fieldName, subFieldName] = params?.field?.toString().split(".");
	// const field = params?.row[fieldName];
	// console.log("fn, sfn", fieldName, subFieldName);
	// console.log(row[fieldName][subFieldName]);
	// console.log("val", rrow[fieldName][subFieldName]ow[params.field]);
	// console.log(field[subFieldName]);
	// return field ? field[subFieldName] : null;
	let cols = [];
	if (Array.isArray(row[fieldName][subFieldName])) {
		row[fieldName][subFieldName].forEach((i) => cols.push(i));
		return cols;
	} else {
		return row[fieldName][subFieldName];
	}
};

export const capitalise = (word) => {
	return word.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const getContrastRatio = (backgroundHexColor) => {
	if (backgroundHexColor) {
		// Remove '#' symbol if present
		if (backgroundHexColor.charAt(0) === "#") {
			backgroundHexColor = backgroundHexColor.substr(1);
		}
		// Convert hex color to RGB
		let r = parseInt(backgroundHexColor.substr(0, 2), 16) / 255;
		let g = parseInt(backgroundHexColor.substr(2, 2), 16) / 255;
		let b = parseInt(backgroundHexColor.substr(4, 2), 16) / 255;

		// Calculate relative luminance
		function getRelativeLuminance(color) {
			return color <= 0.03928
				? color / 12.92
				: Math.pow((color + 0.055) / 1.055, 2.4);
		}
		let L1 =
			0.2126 * getRelativeLuminance(r) +
			0.7152 * getRelativeLuminance(g) +
			0.0722 * getRelativeLuminance(b);

		// Calculate contrast ratio with black and white
		let contrastWithBlack = (L1 + 0.05) / 0.05;
		let contrastWithWhite = 1 / ((1 + 0.05) / (L1 + 0.05));

		// Determine which contrast is higher
		if (contrastWithBlack >= contrastWithWhite) {
			// console.log(backgroundHexColor, "black");
			return "black";
		} else {
			// console.log(backgroundHexColor, "white");
			return "white";
		}
	} else {
		// console.log(backgroundHexColor, "failed to convert");
		return "red";
	}
};

export const tagsFilterOperators = getGridSingleSelectOperators()
	.filter((operator) => operator.value === "isAnyOf")
	.map((operator) => {
		const newOperator = { ...operator };
		const newGetApplyFilterFn = (filterItem, column) => {
			// console.log(filterItem);
			if (!Array.isArray(filterItem?.value) || filterItem?.value?.length < 1) {
				// console.log("here tag filter");

				return null;
			}
			return (value) => {
				let isOk = true;
				// console.log("here tag filter");
				// console.log(params.value, filterItem);
				const matching = value?.some((fv) => {
					return filterItem?.value?.includes(fv?.tag_ref);
				});

				return isOk && matching;
			};
		};
		newOperator.getApplyFilterFn = newGetApplyFilterFn;
		return newOperator;
	});

export const getApplyFilterFnTags = (filter, data) => {
	if (!filter) {
		return null;
	}
	return (value) => {
		if (value) {
			const sg = data?.tags?.find((c) => {
				return c?.tag_name?.toLowerCase().includes(filter);
			});

			return value?.some((tag) => {
				return parseInt(tag?.tag_ref) === parseInt(sg?.tag_ref);
			});
		}
		return false;
	};
};

export const getApplyFilterFnTagBehaviour = (filter, data) => {
	// console.log(filter);
	if (!filter) {
		return null;
	}
	return (value) => {
		// console.log(value);
		if (value) {
			// const sg = data?.tags?.find((c) => {
			// 	return c?.tag_name?.toLowerCase().includes(filter);
			// });
			const test = renderTagModules(value, data);
			// console.log(test);
			return value?.some((tag) => {
				const isMatch =
					tag?.behaviour?.toLowerCase().includes(filter?.toLowerCase()) ||
					JSON.stringify(tag?.arguments)
						?.toLowerCase()
						.includes(filter?.toLowerCase()) ||
					test.toLowerCase().includes(filter?.toLowerCase());

				// console.log(isMatch);

				return isMatch;
			});
		}
		return false;
	};
};
export const setGridValue = (params, item) => {
	if (
		!item ||
		typeof item.masterField === "undefined" ||
		item.dataField2 === "undefined"
	) {
		//	console.log("setValue...", "params=", params, "item=", item);
		params.row[item.field] = params.value;
	} else {
		params.row[item.masterField][item.dataField2] = params.value;
	}
	return params.row;
};

export const getNestedValue = (obj, path) => {
	const keys = path.split(".");
	return keys.reduce(
		(acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
		obj
	);
};

export function hasNestedKey(obj, keyToFind) {
	// Check if the current object has the key
	if (obj.hasOwnProperty(keyToFind)) {
		return true;
	}

	// Recursively check nested objects
	for (const prop in obj) {
		if (typeof obj[prop] === "object" && obj[prop] !== null) {
			if (hasNestedKey(obj[prop], keyToFind)) {
				return true;
			}
		}
	}

	// Key not found in the object or its nested properties
	return false;
}

export const getGridValue = (params, item) => {
	const rowValues = JSON.parse(JSON.stringify(params.row));

	if (
		typeof item.masterField === "string" &&
		typeof item.dataField2 === "string"
	) {
		// check on the getter if the nest key exists, otherwise return null
		// this is because sometimes the row data hasn't refreshed yet
		if (hasNestedKey(params.row, item.dataField2)) {
			return rowValues[item.masterField][item.dataField2];
		} else {
			return null;
		}
	} else {
		return params.row[item.field];
	}
};

export const getApplyFilterFnSalesGroup = (searchTerm, data) => {
	if (!searchTerm) {
		return null;
	}
	return (value) => {
		if (value) {
			const sg = data?.categories?.filter((c) => {
				return c?.salesGroup_name?.toLowerCase().includes(searchTerm);
			});

			if (sg) return sg.some((x) => x?.salesGroup_number === value);
		}
		return false;
	};
};
export const getApplyFilterFnPriceBand = (searchTerm, data) => {
	if (!searchTerm) {
		return null;
	}
	return (value) => {
		if (value) {
			const sg = data?.priceband?.filter((c) => {
				return c?.priceband_name?.toLowerCase().includes(searchTerm);
			});

			// console.log(sg);
			if (sg) return sg.some((x) => x?.priceband_number === value);
		}
		return false;
	};
};

export const tagsSortComparator = (tags1, tags2) => {
	return tags1.length - tags2.length;
};
export const setTagValue = (params, item, rows) => {
	// get new value of cell
	const value = params?.value;
	//find row index
	let objIndex = rows?.findIndex((obj) => obj?.uid === params?.row?.uid);
	// replace old cell value with new cell value
	if (typeof value !== "string") {
		rows[objIndex][item?.masterField][item?.dataField2] = value;
	} else {
	}
	// return new value
	return { ...params?.row, value };
};

export const setTagValueNewGrid = (params, data, field1, field2) => {
	// get new value of cell
	const value = params?.value;
	// console.log(params);
	// const [fieldName, subFieldName, subFieldName2] = params?.field
	// 	?.toString()
	// 	.split(".");
	// const field = params?.row[fieldName];
	// console.log(field);
	// const val = field[subFieldName];
	// console.log(val);
	//find row index
	let objIndex = data?.findIndex((obj) => obj?.uid === params?.row?.uid);
	console.log(data[objIndex][field1][field2]);
	// replace old cell value with new cell value
	// if (typeof value !== "string") {
	// 	data[objIndex][field1][field2] = value;
	// } else {
	// }
	// return new value
	return { ...params?.row, value };
};

// export const getTagValue = (params, data, page) => {
// 	// get original object by row id
// 	// console.log(params);
// 	const obj = data[page]?.find((obj) => obj?.uid === params?.row?.uid);
// 	// console.log(obj);
// 	if (obj !== undefined) {
// 		const value = obj[params?.colDef?.masterField][params?.colDef?.field];
// 		const arr = [];
// 		value?.map((tagNumber) => {
// 			tagNumber = data.tags?.filter((newTag) => newTag?.tag_ref === tagNumber);
// 			if (tagNumber[0] !== undefined) {
// 				arr.push(tagNumber[0]);
// 			}
// 		});
// 		// console.log(arr);
// 		return arr;
// 	}
// };

export const getTagValue = (params, item, data, page) => {
	// get original object by row id
	const obj = data[page].find((obj) => obj?.uid === params?.row?.uid);
	// console.log(obj);
	if (obj !== undefined) {
		const value = obj[params?.colDef?.masterField][params?.colDef?.field];
		const arr = [];
		value?.map((tagNumber) => {
			tagNumber = data.tags?.filter((newTag) => newTag?.tag_ref === tagNumber);
			if (tagNumber[0] !== undefined) {
				arr.push(tagNumber[0]);
			}
		});
		return arr;
	}
};
export const getTagValueNewGrid = (params, data) => {
	const [fieldName, subFieldName, subFieldName2] = params?.field
		?.toString()
		?.split(".");
	const field = params?.row[fieldName];

	const val = field[subFieldName];
	if (val !== undefined) {
		const arr = [];
		val?.map((tagNumber) => {
			tagNumber = data?.tags?.filter((newTag) => newTag?.tag_ref === tagNumber);
			if (tagNumber[0] !== undefined) {
				arr.push(tagNumber[0]);
			}
		});
		// console.log(arr);
		return arr;
	}
};

export const getPriceBandValueNewGrid = (params, data, fieldToMatch) => {
	const [fieldName, subFieldName, subFieldName2] = params?.field
		?.toString()
		.split(".");
	const field = params?.row[fieldName];
	// console.log(data);
	const val = field[subFieldName];
	// console.log(val);
	if (val !== undefined) {
		// const value = obj[fieldName][subFieldName];
		const arr = [];
		if (Array.isArray(val)) {
			// console.log(val);
			val?.map((x) => {
				let obj = data?.find((i) => parseInt(i[fieldToMatch]) === parseInt(x));
				// console.log(obj);
				if (val !== undefined) {
					arr.push(obj);
				}
			});
			// console.log(arr);
			return arr;
		} else
			return data?.find((i) => parseInt(i[fieldToMatch]) === parseInt(val));
	}
};

export const getTagColour = (params, data, fieldToMatch) => {
	const [fieldName, subFieldName, subFieldName2] = params?.field
		?.toString()
		.split(".");
	const field = params?.row[fieldName];
	// console.log(data);
	const val = field[subFieldName][subFieldName2];
	// console.log(val);
	if (val !== undefined) {
		console.log(data?.find((i) => i[fieldToMatch] === val));
		return data?.find((i) => i[fieldToMatch] === val);
	} else return data[0];
};
export const getListIssues = (params, item, data) => {
	let value;

	const obj = data.screens?.find(
		(obj) => obj?.list_number === params?.row?.list_number
	);

	if (obj !== undefined) {
		if (obj.list_details.ignoreThisList === true) {
			return false;
		}
		const buttons = obj[item?.masterField][item?.dataField2];

		value = buttons?.some(function (button) {
			if (button?.functionType === 1 && button.mx !== 0 && button.my !== 0) {
				return !data?.function?.find(
					(item) => parseInt(item?.function_number) === button?.functionValue
				);
			} else if (
				button?.functionType === 12 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.screens?.find(
					(item) => parseInt(item?.list_number) === button?.functionValue
				);
			} else if (
				button?.functionType === 21 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.items?.find(
					(item) => parseInt(item?.plu_number) === button?.functionValue
				);
			} else if (
				button?.functionType === 3 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.modifier?.find(
					(item) => parseInt(item?.modifier_number) === button?.functionValue
				);
			} else if (
				button?.functionType === 2 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.paymentMethod?.find(
					(item) =>
						parseInt(item.paymentMethod_number) === button?.functionValue
				);
			}
			//  else if (
			// 	button?.functionType === 1 &&
			// 	button.mx !== 0 &&
			// 	button.my !== 0
			// ) {
			// 	return !data?.functions?.find(
			// 		(item) => parseInt(item?.number) === button?.functionValue
			// 	);
			// }
			else if (
				button?.functionType === 20 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.macro?.find(
					(item) => parseInt(item?.macro_number) === button?.functionValue
				);
			} else if (
				button?.functionType === 13 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.presetServers?.find(
					(item) => item?.value === button?.functionValue
				);
			} else {
				return null;
			}
		});
	}
	return value;
};

export const getVariantCount = (value, row, data) => {
	const count = data?.screens?.filter((i)=> i.list_isDeleted === 0 && parseInt(i.list_number) === parseInt(row.list_number) && i.list_variant !== 0).length
	return count;
}
export const getListIssuesNewDataGrid = (value, row, data) => {
	// let value;
	// console.log(row);
	// const [fieldName, subFieldName, subFieldName2] = params?.field
	// 	?.toString()
	// 	.split(".");

	// const field = params?.row[fieldName];

	if (row !== undefined) {
		// console.log("here");
		if (row.ignoreThisList === true) {
			// console.log("ignoreThisList");

			return false;
		}
		value = row.list_details.buttons?.some(function (button) {
			if (button?.functionType === 1 && button.mx !== 0 && button.my !== 0) {
				return !data?.function?.find(
					(item) => parseInt(item?.function_number) === button?.functionValue 
				);
			} else if (
				button?.functionType === 12 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.screens?.find(
					(item) => parseInt(item?.list_number) === button?.functionValue && item.list_isDeleted === 0
				);
			} else if (
				button?.functionType === 21 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.items?.find(
					(item) => parseInt(item?.plu_number) === button?.functionValue && item.plu_isDeleted === 0
				);
			} else if (
				button?.functionType === 3 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.modifier?.find(
					(item) => parseInt(item?.modifier_number) === button?.functionValue && item.modifier_isDeleted === 0
				);
			} else if (
				button?.functionType === 2 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.paymentMethod?.find(
					(item) =>
						parseInt(item.paymentMethod_number) === button?.functionValue && item.paymentMethod_isDeleted === 0
				);
			}
			//  else if (
			// 	button?.functionType === 1 &&
			// 	button.mx !== 0 &&
			// 	button.my !== 0
			// ) {
			// 	return !data?.functions?.find(
			// 		(item) => parseInt(item?.number) === button?.functionValue
			// 	);
			// }
			else if (
				button?.functionType === 20 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.macro?.find(
					(item) => parseInt(item?.macro_number) === button?.functionValue && item.macro_isDeleted === 0
				);
			} else if (
				button?.functionType === 13 &&
				button.mx !== 0 &&
				button.my !== 0
			) {
				return !data?.presetServers?.find(
					(item) => item?.value === button?.functionValue 
				);
			} else {
				return null;
			}
		});
	}

	return value;
};

export const getUserLevel = (params, roles) => {
	const [fieldName, subFieldName] = params?.field?.toString().split(".");
	const field = params?.row[fieldName];
	const val = field[subFieldName];
	if (val !== undefined) {
		return roles.find((i) => parseInt(i["server_details.userLevel"]) === val);
	}
};
export const listIssuesFilterOperator = [
	{
		label: "has issues",
		value: "isEmpty",
		getApplyFilterFn: (filterItem) => {
			// if (!filterItem.field || !filterItem.value || !filterItem.operator) {
			// 	return null;
			// }
			// console.log(filterItem);
			return (params) => {
				console.log(params);
				return params === true;
			};
		},
		// : IsEmptyInput,
		requiresFilterValue: false,
	},
];

export const renderTagModules = (tagModuleData, data) => {
	if (!tagModuleData || !Array.isArray(tagModuleData)) {
		return "No data available";
	}
	const formattedText = tagModuleData.map((item, index) => {
		const moduleLookUp = TagConfig(item.behaviour, data);

		if (item.arguments && typeof item.arguments === "object") {
			const keys = Object.keys(item.arguments);
			const formattedArguments = keys.map((key, arrayIndex) => {
				let moduleLookUpArg = "";
				if (moduleLookUp) {
					moduleLookUpArg = moduleLookUp.moduleOptions.find(
						(arg) => arg.option_tag === key
					);
				}
				const value = item.arguments[key];
				let moduleLookUpArgValue = value;
				if (moduleLookUpArg) {
					if (moduleLookUpArg.option_type === "list") {
						moduleLookUpArgValue = moduleLookUpArg.option_values.find(
							(option) => option.value === value
						);
						moduleLookUpArgValue = moduleLookUpArgValue
							? moduleLookUpArgValue.text
							: "n/a";
					}
				}
				// moduleLookUpArg.option_type === "list"
				// 	? moduleLookUpArg.option_values.find(
				// 			(option) => option.value === value
				// 	  )
				// 	: value;

				return `${
					moduleLookUpArg ? moduleLookUpArg.option_name : "n/a"
				} - ${moduleLookUpArgValue}`;
			});

			return `${index + 1}. ${
				moduleLookUp ? moduleLookUp.module_name : "n/a"
			} (${formattedArguments.join(", ")})`;
		} else {
			return `${index + 1}. ${item.behaviour} (No arguments)`;
		}
	});

	return formattedText.join("\n");
};

export function getLastKey(obj) {
	return Object.keys(obj)[Object.keys(obj).length - 1];
}

export const setObjectValue = (params, item, rows) => {
	// get new value of cell

	//console.log("Setting...", rowModesModel, params, item);
	const value = params?.value;
	//find row index

	let objIndex = rows?.findIndex((obj) => obj?.uid === params?.row?.uid);

	// replace old cell value with new cell value
	// console.log(rows[objIndex][item?.masterField][item?.dataField2], value);
	switch (typeof value) {
		case "number":
		case "boolean":
			if (!item?.masterField) {
				rows[objIndex][item?.field] = Boolean(value);
			} else {
				rows[objIndex][item?.masterField][item?.dataField2] = Boolean(value);
			}
			break;
		case "string":
			if (!item?.masterField) {
				rows[objIndex][item?.field] = value;
			} else {
				rows[objIndex][item?.masterField][item?.dataField2] = value;
			}
			break;
		case "object":
			//console.log("Object", value);
			if (!item?.masterField) {
				if (value.length > 0) {
					rows[objIndex][item?.field] = JSON.parse(value);
				}
			} else {
				if (value.length > 0) {
					rows[objIndex][item?.masterField][item?.dataField2] =
						JSON.parse(value);
				}
			}
			break;
		default:
	}
	// console.log(params?.row, value);
	// return new value
	return { ...params?.row, value };
};

export const numberSort = (v1, v2) => parseInt(v1) - parseInt(v2);

export const getSelectDataSpreader = (params, item, data, page) => {
	//item.selectDataSpreadFields
	const obj = data[page]?.find((obj) => obj?.uid === params?.row?.uid);
	if (obj !== undefined) {
		const searchValues = {};

		item.selectDataSpreadFields.forEach((key) => {
			const nestedKeys = key.split(".");

			let currentObject = obj;
			for (const nestedKey of nestedKeys) {
				if (currentObject && typeof currentObject === "object") {
					currentObject = currentObject[nestedKey];
				}
			}

			if (currentObject !== undefined) {
				searchValues[key] = currentObject;
			}
		});

		// console.log("SearchValues:", searchValues, "dataRows:", item.dataRows);

		const returnValue = item.dataRows.findIndex((val) => {
			return Object.keys(searchValues).every((key) => {
				return val[key] == searchValues[key];
			});
		});

		// console.log("returnValue:", returnValue);

		return returnValue >= 0 ? (
			<Typography variant="datagrid">
				{item.dataRows[returnValue].text}
			</Typography>
		) : (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginLeft: 11,
				}}
			>
				<LightTooltip title="No role assigned to server!">
					<ErrorIcon sx={{ color: Colours.gsblue }} />
				</LightTooltip>
			</Box>
		);
	}
	return "";
};

export const lookupDataGrid = (
	dataSource,
	value,
	fieldSearch,
	fieldReturn,
	extractValueOnDash,
	displayType
) => {
	let searchValue = "";

	searchValue = value;
	// }

	let result = [];

	if (Array.isArray(searchValue)) {
		for (let i = 0; i < searchValue.length; i++) {
			// console.log(searchValue[i], fieldSearch);
			const resultReturn = dataSource.find(
				(item) => item[fieldSearch] == searchValue[i]
			);
			if (resultReturn) {
				result.push(resultReturn);
			}
		}
	} else {
		const resultReturn = dataSource.find(
			(item) => item[fieldSearch] == searchValue
		);
		if (resultReturn) {
			result.push(resultReturn);
		}
	}

	if (result.length > 0) {
		let values = result.map((item) => item[fieldReturn]);

		// Joining the values with comma
		let resultText = values.join(", ");

		if (displayType === "chip") {
			return values.map((text) => {
				return <StyledTag key={text} label={text} colour={Colours.gsblue} />;
			});
		} else {
			return <Typography variant="datagrid">{resultText}</Typography>;
		}
	} else {
		if (displayType === "chip") {
			return "";
		} else {
			return (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						margin: "auto", // Center the box horizontally
						height: "100%", // Ensure the container takes up the full height of the cell
					}}
				>
					<LightTooltip title={`Category does not exist`}>
						<ErrorIcon sx={{ color: Colours.gsblue }} />
					</LightTooltip>
				</Box>
			);
		}
	}
};
export const currencyFormatter = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
});

export const StyledGridOverlay = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
	"& .ant-empty-img-1": {
		fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
	},
	"& .ant-empty-img-2": {
		fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
	},
	"& .ant-empty-img-3": {
		fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
	},
	"& .ant-empty-img-4": {
		fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
	},
	"& .ant-empty-img-5": {
		fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
		fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
	},
}));

export function CustomNoRowsOverlay() {
	return (
		<StyledGridOverlay>
			<svg
				width="250"
				height="220"
				viewBox="0 0 184 152"
				aria-hidden
				focusable="false"
			>
				<g fill="none" fillRule="evenodd">
					<g transform="translate(24 31.67)">
						<ellipse
							className="ant-empty-img-5"
							cx="67.797"
							cy="106.89"
							rx="67.797"
							ry="12.668"
						/>
						<path
							className="ant-empty-img-1"
							d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
						/>
						<path
							className="ant-empty-img-2"
							d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
						/>
						<path
							className="ant-empty-img-3"
							d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
						/>
					</g>
					<path
						className="ant-empty-img-3"
						d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
					/>
					<g className="ant-empty-img-4" transform="translate(149.65 15.383)">
						<ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
						<path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
					</g>
				</g>
			</svg>
			<Typography sx={{ mt: 1 }}>No Rows</Typography>
		</StyledGridOverlay>
	);
}

export const StyledOverlay = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",

	alignItems: "center",
	justifyContent: "center",
	height: "100%",
}));

export function CustomOverlay() {
	return (
		<StyledOverlay>
			<Player
				autoplay
				loop
				src="https://lottie.host/9c9aa0aa-4590-4005-9036-ae1add04bb1b/Y0WC7l7CqL.json"
				style={{ height: "300px", width: "300px" }}
			>
				{/* <Box>
					<Typography sx={{ textAlign: "center" }}>No Results Found</Typography>
				</Box> */}
				<Controls
					visible={false}
					// buttons={["play", "repeat", "frame", "debug"]}
				/>
			</Player>
			<Box>
					<Typography sx={{ textAlign: "center" }}>No Results Found</Typography>
				</Box>
		</StyledOverlay>
	);
}

export const siteOrTag = (type, ref, data) => {
	let index = 0;
	let name = "";
	switch (type.toLowerCase()) {
		case "site":
		case "sites":
			index = data.sites.findIndex((item) => item.site_ref === ref);
			name = index !== -1 ? data.sites[index].site_name : "Unknown Site";

			return name;

			break;
		case "tag":
		case "tags":
			index = data.tags.findIndex((item) => item.tag_ref === ref);
			name = index !== -1 ? data.tags[index].tag_name : "Unknown Tag";
			return name + " Tag(" + ref + ")";
			break;
		default:
			return "Unknown type";
	}
};

export const modiferDataColumns = (
	priceModifierConfig,
	rows,
	data,
	classes,
	ref,
	priceBandColors,
	siteFilterValue,
	priceBandFilterValue,
	table
) => {
	// WARNING: this code only works with masterField and dataField2 Data Grid Config - will need refactoring to work
	// with the top level field value
	const priceModDebug = false;
	const modiferItems = {};
	const modiferItemsSite = {};
	const modiferItemsPriceBand = {};
	const priceBandFilterData = data?.priceband;

	function getPriceBandName(targetNumber) {
		const foundPriceBand = priceBandFilterData.find(
			(band) => band.priceband_number === targetNumber.toString()
		);

		return foundPriceBand
			? foundPriceBand.priceband_name
			: "no price band found";
	}

	const returnArray = [];
	// console.log(data["sites"]?.length);
	// build the columns based on sites so that is is sorted
	for (let i = 0; i < data["sites"]?.length; i++) {
		for (let p = 1; p <= 10; p++) {
			const columnName =
				"expandedfield:" +
				priceModifierConfig?.masterField +
				"/" +
				priceModifierConfig?.dataField2 +
				"," +
				'"type":"Site","priceBand":"' +
				p +
				'","ref":"' +
				data["sites"][i].site_ref +
				'"';
			modiferItems[columnName] =
				data["sites"][i].site_name + " " + "\n" + getPriceBandName(p);

			modiferItemsSite[columnName] = data["sites"][i].site_ref;
			modiferItemsPriceBand[columnName] = p.toString();
		}
	}
	// console.log(rows.length);
	// loop through all the data and see what has a modifer
	for (let i = 0; i < rows?.length; i++) {
		// does the initial nested object exist? if it doesn't then just exit and wait for the row data to update
		if (!hasNestedKey(rows[i], priceModifierConfig.dataField2)) {
			return [];
		}
		// console.log("here");
		const priceModLen =
			typeof rows[i][priceModifierConfig.masterField][
				priceModifierConfig.dataField2
			] === "object"
				? rows[i][priceModifierConfig?.masterField][
						priceModifierConfig?.dataField2
				  ].length
				: 0;
		// console.log(
		// 	rows[i][priceModifierConfig.masterField][priceModifierConfig.dataField2]
		// 		.length
		// );
		// loop through the price modifiers
		for (let j = 0; j < priceModLen; j++) {
			// check if there is a priceBand - but default regardless incase it doesn't exist
			let priceBand = 1; // the default if not price band present
			if (
				"priceBand" in
				rows[i][priceModifierConfig?.masterField][
					priceModifierConfig?.dataField2
				][j]
			) {
				priceBand =
					rows[i][priceModifierConfig?.masterField][
						priceModifierConfig?.dataField2
					][j]["priceBand"];
			}
			// update/add the type/ref so we know it is used
			const fieldRef =
				rows[i][priceModifierConfig?.masterField][
					priceModifierConfig?.dataField2
				][j]["ref"];
			const columnName =
				"expandedfield:" +
				priceModifierConfig?.masterField +
				"/" +
				priceModifierConfig?.dataField2 +
				"," +
				'"type":"' +
				rows[i][priceModifierConfig?.masterField][
					priceModifierConfig?.dataField2
				][j]["type"] +
				'",' +
				'"priceBand":"' +
				priceBand +
				'",' +
				'"ref":"' +
				rows[i][priceModifierConfig?.masterField][
					priceModifierConfig?.dataField2
				][j]["ref"] +
				'"';
			// create the fieldname as the key, and the fieldheads as the value
			const fieldType =
				rows[i][priceModifierConfig?.masterField][
					priceModifierConfig?.dataField2
				][j]["type"];
			// ----

			if (fieldType === "Site") {
				modiferItems[columnName] =
					siteOrTag(fieldType, fieldRef, data) +
					"\n" +
					getPriceBandName(priceBand);

				// update just in case undefined is returned
				if (typeof modiferItems[columnName] === "undefined") {
					modiferItems[columnName] = "Loading...";
				}
				// add the columnData to the data grid row
				rows[i][columnName] =
					rows[i][priceModifierConfig?.masterField][
						priceModifierConfig?.dataField2
					][j]["modifier"];
				modiferItemsSite[columnName] = fieldRef;
				modiferItemsPriceBand[columnName] = priceBand;
			}
		}
	}

	// Use the class in your component
	// console.log(siteFilterValue);
	for (let key in modiferItems) {
		if (
			siteFilterValue.indexOf(modiferItemsSite[key].toString()) > -1 &&
			priceBandFilterValue.indexOf(modiferItemsPriceBand[key].toString()) > -1
		) {
			// console.log("here");
			const selectedPriceBand = priceBandColors.find(
				(pb) =>
					parseInt(pb.priceband_number) === parseInt(modiferItemsPriceBand[key])
			);
			returnArray.push({
				field: key,
				type: "priceNested",
				headerName: modiferItems[key],
				// headerClassName: classes.headerWrap,
				// headerClassName: "pricingHeader",
				renderHeader: (params) => (
					<Typography
						sx={{
							textOverflow: "clip",
							fontSize: 14,
							fontWeight: "200",
							whiteSpace: 'normal !important',
							wordWrap: 'break-word !important',
							lineHeight: 'normal',
							// transform: table === 'history' && 'rotate(-90deg)',
							padding:0,
							// padding: 5,
							"&:hover": {
								whiteSpace: "normal",
							},
						}}
					>
						{params?.colDef?.headerName}
					</Typography>
				),
				editable: true,
				sortable: true,
				ref: ref,
				flex: 1,
				minWidth: 80,
				align: "center",
display:'flex',
				priceBand: modiferItemsPriceBand[key],
				priceBandBackGroundColor: selectedPriceBand
					? selectedPriceBand.color
					: "red",
				cellClassName: "priceMod",
				valueFormatter: (value) => value && currencyFormatter.format(value),

				renderCell: ({ value }) => (
					<>
				{table !== 'history' ?
						<Typography variant="datagrid" 	sx={{
							width: "100%",
							flexDirection: "column",
							height: "100%",
							display: "flex", // Ensures content takes full space
							justifyContent: "center",
							alignItems: "flex-end",
							pr: 2,
							// padding: "8px", // Add padding to the cell
							// Added in for colours in the price band grid abd the get contrast ratio function for the text
							color: selectedPriceBand
								? getContrastRatio(selectedPriceBand.color)
								: "black",
							backgroundColor: selectedPriceBand
								? selectedPriceBand.color
								: "none",
						}}
					>
							{value ? currencyFormatter.format(value) : "£"}
						</Typography>
						: value ? currencyFormatter.format(value) : "£"  }
						</>
				),
				renderEditCell: (params) => (
					<Box
						sx={{
							// width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "stretch",
						}}
					>
						<CustomTextField
							// defaultValue={params.value}
							// label="Ref"
							value={params.value}
							type="price"
							label={null}
							onChange={(e) => {
								const updatedValue = e.target.value;
								ref.current.setEditCellValue({
									id: params.id,
									field: params.field,
									value: updatedValue,
								});
							}}
							origin="datagrid"
						/>
					</Box>
				),
			});
		} else {
		}
	}

	// show the price mod as JSON?
	if (priceModDebug) {
		returnArray.push({
			field: "na",
			masterField: priceModifierConfig.masterField,
			dataField2: priceModifierConfig.dataField2,
			type: "JSON",
			headerName: "priceMod",
			// flex: 1,

			editable: false,
			sortable: false,
			minWidth: 80,

			//ref: sitesRef,
		});
	}
	console.log(returnArray);

	// --- make an empty field array
	if (returnArray.length === 0) {
		returnArray.push({
			field: "na",
			type: "string",
			headerName: "No Price Modifiers",
			flex: 1,
			// minWidth: 80,
			editable: false,
			sortable: false,
			//ref: sitesRef,
		});
	}
	// console.log(returnArray);

	return returnArray;
};

export function computeMutation(newRow, oldRow) {
	if (newRow !== oldRow) {
		return `Not equal'`;
	}

	return null;
}

export function setSubFieldPermissions(subFieldName1, subFieldName2) {
	// The setSubField function updates a nested field with a new value
	return (params) => {
		// console.log(params);
		const field = { ...params?.row[subFieldName1] };
		// console.log(field);
		// if (!field.hasOwnProperty(subFieldName2)) {
		// } else

		field[subFieldName2] = params?.value;
		// console.log(field[subFieldName2]);
		const field2 = { ...params?.row };
		// console.log(field2);
		field2[subFieldName1] = field;
		return { ...field2 };
	};
}

export function getSubFieldPermissions(params) {
	// The getSubField function extracts the value of a nested field
	const [fieldName, subFieldName] = params?.field?.toString().split(".");
	const field = params?.row[fieldName];
	// console.log(field);
	return field ? field[subFieldName] : null;
}
