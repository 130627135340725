import React from "react";
import { getTextColour } from "../../utils";
import {
	Autocomplete,
	Box,
	Chip,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridContext } from "../../../../context/DataGridContext";
import ArrayFields from "./ArrayFields";
import { Colours } from "../../../../assets/global/Theme-variable";

export default function NewPrinterForm({ methods, table }) {
	const { setValue, control, formState } = useFormContext();
	const { data } = React.useContext(DataGridContext);
	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="tag-name"
							label="Name"
							// value={
							// 	`modalConfig?.editRow?.row?.${table}_name` &&
							// 	`modalConfig?.editRow?.row?.${table}_name`
							// }
							// defaultValue={
							// 	`modalConfig?.editRow?.row?.${table}_name` &&
							// 	`modalConfig?.editRow?.row?.${table}_name`
							// }
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name="printer_name"
					// defaultValue=""
					rules={{
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Name cannot contain emoji's or special characters",
						},
						required: {
							value: true,
							message: "Printer name is required",
						},
						// 	validate: (value) => {
						// 		!data?.tags?.some((tag) => tag.tag_name === value) ||
						// 		"A tag with this name already exists",
						// }
					}}
				/>
				{formState.errors?.printer_name && (
					<Typography sx={{ color: Colours.gsblue, fontSize: 12 }}>
						{formState?.errors.printer_name?.message}
					</Typography>
				)}
			</Box>
			<ArrayFields />
		</>
	);
}
