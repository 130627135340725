import React from "react";
import ErrorDialog from "./ErrorDialog";
import FunctionBarContext from "../../../context/FunctionBarContext";
// import GridItem from "./GridItem";
import { Box, Typography } from "@mui/material";
import {
	SortableContext,
	useSortable,
	arrayMove,
	sortableKeyboardCoordinates,
	rectSwappingStrategy,
} from "@dnd-kit/sortable";
import { checkPermissions } from "../../AccessControl";
import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	useDndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import NewGridItem from "./NewGridItem";
import GridContext from "../../../context/GridContext";
import DragOverlayItem from "./DragOverlay";
import { CursorContext } from "../../../components/CursorProvider";
import { Colours } from "../../../assets/global/Theme-variable";

export default function FunctionBar({
	setOpen,
	open,
	// items,
	onDrop,
	activeId,
	editBar,
	colNo,
	rowNo,
	list,
	handleOpen,
	setItem,
	openItem,
	cellCount,
	draggingItem,
	listArea,
	setDraggingItem,
	...props
}) {
	const [style, setStyle] = React.useState({ display: "none" });

	const { variant, page, copyStyles } = React.useContext(GridContext);
	const { onCursor } = React.useContext(CursorContext);

	const [errorMessage, setErrorMessage] = React.useState(false);
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);
	// console.log(variant);
	// const listToShow =
	// 	variant?.list_details?.location === "F3" ? variant : sampleRibbon;

	return (
		<>
			{variant?.list_details?.location !== "F3" ? (
				<Box
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: Colours.gsgrey,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{/* <Typography
						sx={{ color: "white", userSelect: "none", textAlign: "center" }}
					>
						You are currently editing {variant?.list_name}. To edit this area,
						please change the list.
					</Typography> */}
				</Box>
			) : (
				<Box
					sx={{
						display: "grid",
						gridAutoFlow: "dense",
						gridTemplateColumns: `repeat(${colNo}, 1fr)`,
						gridTemplateRows: `repeat(${rowNo}, 1fr)`,
						cursor: copyStyles === true ? "none" : "pointer",
						width: "100%",
						minHeight: "100%",
						flex: 1,
						gridAutoRows: "0",
						overflowY: "hidden",
						opacity: listArea?.displayName !== "Bottom Bar" ? "1" : "1",
					}}
					onMouseEnter={() => (copyStyles === true ? onCursor("left") : null)}
					onMouseLeave={copyStyles === true ? onCursor : null}
					key={"fb"}
				>
					<SortableContext
						// disabled={checkPermissions(userPermission, {
						// 	page: "lists",
						// 	allow: false,
						// 	action: "edit",
						// })}
						disabled={variant?.list_details?.location !== "F3"}
						items={variant.list_details.buttons}
						strategy={rectSwappingStrategy}
					>
						{variant?.list_details?.buttons
							?.filter((button) => button.pageNo === page)
							// .sort(sortItems)
							.map((item, index) => (
								<NewGridItem
									item={item}
									key={item.id}
									id={item.id}
									activeId={activeId}
									{...props}
									handleOpen={handleOpen}
									disableSelection={variant?.list_details?.location !== "F3"}
								/>
							))}
					</SortableContext>
					<DragOverlay>
						{activeId ? <DragOverlayItem id={activeId} /> : null}
					</DragOverlay>
				</Box>
			)}
		</>
	);
}

const sortItems = (a, b) => a.index - b.index;
