import React from "react";
import {
	Autocomplete,
	Box,

	TextField,
	Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DataGridContext } from "../../../../context/DataGridContext";
import { Colours } from "../../../../assets/global/Theme-variable";
import ReactHookFormTagField from "../../../../components/ReactHookFormTagField";

export default function NewCategoryForm({ methods, gridList, table }) {
	const { setValue, control, formState } = useFormContext();
	const { data } = React.useContext(DataGridContext);
	// console.log(table, gridList.gridPage);
	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Controller
					control={control}
					render={({
						field: { name, onChange, defaultValue, onBlur, value, ref },
					}) => (
						<TextField
							id="category-name"
							label="Name"
							value={value}
							name={name}
							onChange={onChange}
							size="small"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					// name="plu_name"
					name={`${table}_name`}
					// defaultValue=""
					rules={{
						required: { value: true, message: "Name is required" },
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "no emoi's",
						},
						validate: (value) => {
							return (
								!data[`${gridList.gridPage}`]?.some(
									(printer) => printer[`${table}_name`] === value && printer[`${table}_isDeleted`] === 0
								) || "A category with this name already exists"
							);
						},
					}}
				/>

				{formState?.errors[`${table}_name`] && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors[`${table}_name`].message}
					</Typography>
				)}
			</Box>
			<Box sx={{ width: "100%" }}>
				{gridList.gridPage === "categories" && (
					<Controller
						render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
							<Autocomplete
								key={`autocomplete_`}
								options={data?.majorcategories?.filter(
									(i) => i?.majorGroup_isDeleted === 0
								)}
								value={
									value
										? data.majorcategories.find(
												(cat) =>
													parseInt(cat.majorGroup_number) === parseInt(value)
										  )
										: null
								}
								sx={{ width: "100%" }}
								size="small"
								disableClearable
								getOptionLabel={(o) => o.majorGroup_name}
								onChange={(e, newValue) => {
									setValue(
										`salesGroup_details.majorGroup`,
										parseInt(newValue?.majorGroup_number)
									);
								}}
								renderInput={(params) => (
									<TextField
										// required
										// error={fieldError[item.masterField + "." + item.dataField2]}
										// helperText={
										// 	fieldHelperText[item.masterField + "." + item.dataField2]
										// }
										sx={{ width: "100%", marginTop: 1 }}
										{...params}
										label={"Major Category"}
										variant={"outlined"}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								)}
							/>
						)}
						name={`salesGroup_details.majorGroup`}
						rules={{
							required: true,
						}}
					/>
				)}
				{formState?.errors?.salesGroup_details?.majorGroup &&
					formState?.errors?.salesGroup_details?.majorGroup?.type ===
						"required" && (
						<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
							Major Category is required
						</Typography>
					)}
			</Box>
		
					<ReactHookFormTagField
						id="tag_ref"
						name={`${table}_details.tags`}
						label="Tags"
						control={control}
						// defaultValue={tags}
						selectoptions={data?.tags?.filter(
							(i) => i.tag_isDeleted === 0
						)}
						fieldValueToSave="tag_ref"
					/>

		</>
	);
}
