import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";


export const NoPermissionDialog = ({open, setOpen}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open && isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{width:'100%',display:'flex', flexDirection:'row', justifyContent: 'space-between', alignItems:'center'}}>
        <DialogTitle id="alert-dialog-title">
        Unconfigured User
        </DialogTitle>
        <DialogActions>
          <IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={handleClose}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
        </DialogActions>
        </Box>
        <DialogContent sx={{p:'10px 24px 24px 24px'}}>
          <DialogContentText id="alert-dialog-description">
           You currently have no permissions set for your user. Please contact your administrator for additional access.
          </DialogContentText>
        </DialogContent>
      
      </Dialog>
    </React.Fragment>
  );
}