import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Colours } from "../assets/global/Theme-variable";
import S3 from "react-aws-s3";

import styled from "styled-components";
import { motion } from "framer-motion";
import { FiCamera, FiTrash } from "react-icons/fi";
import AccessControl from "../views/AccessControl";
import { DataGridContext } from "../context/DataGridContext";

export default function ImageUpload({
	image,
	item,
	handleFileDelete,
	handleFileInput,
	variant,
}) {
	const { data, setData, userPermission } = React.useContext(DataGridContext);
	console.log(image);
	const imageVariants = {
		beforeHover: {},
		onHover: { scale: 1.1 },
	};
	window.Buffer = window.Buffer || require("buffer").Buffer;

	const textVariants = {
		beforeHover: { opacity: 0 },
		onHover: { opacity: 1 },
	};

	const H1Variants = {
		initial: { y: -100 },
		animate: { y: 0, transition: { delay: 0.7 } },
	};

	const fileInput = React.useRef();
	return (
		<BoxContainer initial="beforeHover" whileHover="onHover">
			<ImageBox variants={imageVariants} image={image} variant={variant} />
			<AccessControl
				userPermissions={userPermission}
				unallowedPermissions={{
					page: "items",
					// allow: false,
					strict: false,

					action: "edit",
				}}
				// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
				renderNoAccess={() => <></>}
			>
				<TextBox variants={textVariants}>
					<input
						type="file"
						onChange={handleFileInput}
						onClick={(e) => (e.target.value = null)}
						hidden
						ref={fileInput}
						style={{ display: "none" }}
					/>
					<IconButton
						size="small"
						color="primary"
						type="button"
						aria-label="upload image"
						onClick={() => fileInput.current.click()}
					>
						<FiCamera />
					</IconButton>

					<IconButton
						size="small"
						color="primary"
						type="button"
						aria-label="delete image"
						onClick={handleFileDelete}
					>
						<FiTrash />
					</IconButton>
					{/* <IconButton
										size="small"
										color="primary"
										type="button"
										aria-label="delete image"
										onClick={() => setLargeImage(true)}
									>
										<FiZoomIn />
									</IconButton> */}
				</TextBox>
			</AccessControl>
		</BoxContainer>
	);
}

export const ImageBox = styled(motion.div)(
	(props) => `
	position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
	height: 100%;
	background: url(${
		props?.image
			? props?.image
			: "https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
	});
	background-size: cover;
	transition: transform 0.3s;
	background-position: center;
    // border-radius: ${props.variant === "round" && "50%"}
`
);

export const BoxContainer = styled(motion.div)`
	width: 100%;
	height: 200px;
	border-radius: 5px;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	position: relative;
`;

export const TextBox = styled(motion.div)`
	color: #fff;
	padding: 0.5rem;
	background: #f3f6f9;
	overflow: hidden;
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2;
`;
