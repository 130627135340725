import * as React from "react";
import {
	Box,
	Typography,
	TextField,
	ToggleButton,
	FormControlLabel,
	Checkbox,
	Button,
	ToggleButtonGroup,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import { motion } from "framer-motion";
import { Colours } from "../../../assets/global/Theme-variable";
import { fontSizeOptions } from "../data/fontSizes";
import { textAlignOptions } from "../data/textAlignOptions";
import GridContext from "../../../context/GridContext";
import { DataGridContext } from "../../../context/DataGridContext";
import { modifiers } from "../data/modifiers";
import funcType from "../data/funcType";
import IntegratedColourPicker from "./IntegratedColourPicker";
import OptionSelect from "./OptionSelect";
import AutoComplete from "./AutoComplete";
import { presetServers } from "../data/presetServers";
import listAreas from "../data/listAreas";
import PaymentScreenFuncType from "../data/PaymentScreenFuncType";
import LargeListFuncType from "../data/LargeListFuncType";
import controls from "../data/controls";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import { ArrowButton } from "../../../components/ArrowButton";

const style = {
	display: "grid",
	gridTemplateColumns: "1",
	gridTemplateRows: "0.3fr 100%",
	gap: 1,
	flex: 1,
	width: "100%",
	backgroundColor: "white",
	padding: 0,
};

export default function EditSide({ openEditBar, openItem }) {
	const { data } = React.useContext(DataGridContext);
	const {
		items,
		setNewChanges,
		setVariant,
		variant,
		setItem,
		layout,
		page,
		setCopyStyles,
		newItemModalOpen,
		setNewItemModalOpen,
		copyStyles,
	} = React.useContext(GridContext);
console.log(openItem)
	// const handleCopyFormat = React.useCallback(() => {
	// 	// setCursor(({ active }) => ({ active: !active }));
	// 	// setCursor(!cursor);
	// 	// setCopyStyles(!copyStyles);
	// });

	// const handleCopyFormat = () => {
	// 	// console.log(newFormats);
	// 	setCopyStyles(!copyStyles);
	// 	// setFormats(newFormats);
	// };

	// console.log(
	// 	variant.list_details.buttons.find(
	// 		(button) => parseInt(button?.id) === parseInt(openItem?.id)
	// 	)
	// );

	let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
		object
	) {
		return parseInt(object?.id) === parseInt(openItem?.id);
	});

	// console.log(variant.list_details.buttons);

	const [sizeOptions, setSizeOptions] = React.useState(
		// getButtonSizeOptions(buttonIndex)[
		[]
	);
	const handleFormat = (event) => {
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.font =
				event === true
					? draft.list_details.buttons[buttonIndex].style.font.match(/\d+/)[0] +
					  "B"
					: draft.list_details.buttons[buttonIndex].style.font.match(/\d+/)[0] +
					  "N";
			// setItem(draft.list_details.buttons[buttonIndex]);
		});

		setNewChanges(true);
	};
	const handleShowText = (event, newFormats) => {
		const val = newFormats ? 0 : 1;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].hideText = val;
		});
		setNewChanges(true);
	};

	React.useEffect(() => {
		if (variant) {
			const options = getButtonSizeOptions(buttonIndex);
			setSizeOptions(options);
		}
	}, [buttonIndex]);

	const handleChangeText = (event) => {
		setVariant((draft) => {
			const reg = new RegExp(
				/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/
			);

			if (reg.test(event.target.value)) {
				draft.list_details.buttons[buttonIndex].text =
					event.target.value.replace(/(?:\r\n|\r|\n)/g, "|");
			}
		});
		setNewChanges(true);
	};

	const handleChangePLU = (event, newValue) => {
		setVariant((draft) => {
			if (variant.list_details.buttons[buttonIndex].functionType === 21) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.plu_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.plu_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 12) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.list_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.list_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 1) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.function_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.function_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 3) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 20) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.macro_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.macro_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 2) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.paymentMethod_number
					);
					draft.list_details.buttons[buttonIndex].text =
						newValue.paymentMethod_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 6) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.value
					);
					draft.list_details.buttons[buttonIndex].text = newValue.name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 13) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.value
					);
					draft.list_details.buttons[
						buttonIndex
					].text = `Server ${newValue.value}`;
				}
			}
		});

		setNewChanges(true);
	};

	const handleButtonType = (event) => {
		let type = event.target.value;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].functionType = type.val;
			if (type.name === "Item") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Navigate") {
				draft.list_details.buttons[buttonIndex].functionType = 12;

				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Function") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Modifier") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Macro") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Payment Method") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Blank") {
				draft.list_details.buttons[buttonIndex].functionValue = 0;
				draft.list_details.buttons[buttonIndex].text = " ";
			} else if (type.name === "Control") {
			} else if (type.name === "Preset Server") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			}
		});
		setNewChanges(true);
	};

	const handleTextAlign = (event) => {
		const displayAlign = event.target.value;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.alignmentV =
				displayAlign.tillValue;
			draft.list_details.buttons[buttonIndex].style.alignmentH = 1;
		});
		setNewChanges(true);
	};

	// console.log(openItem);
	function getAround(ind, col, row) {
		let indexes = [];

		ind = Number(ind);
		if (isNaN(ind)) {
			throw new Error("Index should be a number.");
		}

		function range(start, end) {
			return Array(end - start + 1)
				.fill()
				.map((_, idx) => start + idx);
		}

		let until =
			ind + row * variant.list_details.cols > 0
				? ind + row * variant.list_details.cols
				: 0;

		for (let index = ind; index < until; index += variant.list_details.cols) {
			let start = index;
			let end = index + col - 1;
			var result = range(start, end);
			result.forEach((n) => n !== ind && indexes.push(n));
		}
		return indexes;
	}
	// console.log(variant.list_details.buttons);

	function getButtonSizeOptions(ind) {
		// console.log(ind, variant.list_details.rows);

		let indexes = [];
		let widths = [];
		let heights = [];
		let options = [];

		ind = Number(ind);
		if (isNaN(ind)) {
			throw new Error("Index should be a number.");
		}

		function range(start, end) {
			// console.log(start, end);
			return Array(end - start + 1)
				.fill()
				.map((_, idx) => start + idx);
		}

		const rowSpace = [];
		variant?.list_details?.buttons
			// .filter((button) => button.pageNo === page)
			.map((item, index) =>
				rowSpace.push({
					row: Math.floor(index / variant?.list_details.cols),
					mx: item.mx,
					my: item.my,
				})
			);

		let colInd = ind % variant?.list_details?.cols;
		// console.log("colind", colInd);
		// console.log("page", page);
		// console.log(
		// 	"row test",
		// 	Math.floor(ind / variant?.list_details?.rows) -
		// 		page * variant?.list_details?.rows +
		// 		variant?.list_details?.rows
		// );
		// let minusSpace = 0;
		// // before index cols
		// for (let index = 0; index < colInd; index++) {
		// 	if (rowSpace[index].mx > 1) {
		// 		// console.log("rs index", rowSpace[index]);
		// 		minusSpace += rowSpace[index].mx - 1;
		// 	}
		// }

		// console.log("rowspace", rowSpace);
		let rowInd = Math.floor(ind / variant?.list_details?.cols);
		// console.log(rowSpace.findIndex((row) => row.row === rowInd));
		let start = 1;
		let end = variant?.list_details?.cols - colInd;
		// console.log("end", end);
		let result = range(start, end);
		// console.log("col result", result);
		result.forEach((n) => widths.push(n));
		// }

		// console.log("rowind", rowInd);
		//after index
		// for (let index = rowInd; index < variant.list_details.cols; index++) {}

		let rowstart = 1;
		// let rowstart =
		// 	Math.floor(ind / variant?.list_details?.rows) -
		// 	page * variant?.list_details?.rows +
		// 	variant?.list_details?.rows;
		let rowend = variant?.list_details?.rows * page - rowInd;
		// console.log("rowstart", rowstart);
		// console.log("rowend", rowend);
		let maxrows = range(rowstart, rowend);
		// console.log(maxrows);
		maxrows?.forEach((n) => heights?.push(n));

		heights?.forEach((h) =>
			widths?.forEach((w) =>
				options?.push({
					value: `${w}x${h}`,
					name: `${w}x${h}`,
					col: w,
					row: h,
				})
			)
		);
		// console.log("options", options);

		// setSizeOptions(options);
		return options;

		// var grid = [];
		// var currentRow = 0;
		// var currentButton = 0;
		// var gridx = 5;
		// var gridy = 5;
		// // establish an empty grid
		// for (var y = 0; y <= gridy; y++) {
		// 	var newRow = [];
		// 	for (var x = 0; x < gridx; x++) {
		// 		newRow.push(null);
		// 	}
		// 	grid.push(newRow);
		// }
		// console.log("grid", grid);
		// while (currentButton < items.length) {
		// 	// first we want to see if and where this button fits on the current row.
		// 	var thisRow = grid[currentRow];
		// 	var button = items[currentButton];
		// 	var thisColumn = null; // put this into scope so it can be set below
		// 	// console.log("attempting to find button", button.text);
		// 	var foundPosition = false;
		// 	// we look for x positions in the current row
		// 	for (var x = 0; x < gridx; x++) {
		// 		var positionIsValid = true; // position starts valid, it is later invalidated by being too small or being occupied.
		// 		// this for loop with i is to check if the space is big enough (w = button.width, are the next w spaces clear (null))
		// 		for (var i = 0; i < button.width; i++) {
		// 			if (x + i >= gridx || grid[currentRow][x + i] !== null) {
		// 				positionIsValid = false;
		// 			}
		// 		}
		// 		// position is empty and big enough
		// 		if (positionIsValid) {
		// 			grid[currentRow][x] = button;
		// 			thisColumn = x; // whats the x position of this button?
		// 			foundPosition = true; // we found a position
		// 			break; // stop using the for x loop to look for this buttons position
		// 		}
		// 	}
		// 	// if this button can't find any positions on this row, we move to the next row
		// 	if (!foundPosition) {
		// 		console.log("no spaces found in this row moving to next row");
		// 		currentRow = currentRow + 1; // move to the next ro and try there.
		// 		continue; // this ends this while loop iteration and begins the next (on a new row)
		// 	}
		// 	console.log(
		// 		"found position",
		// 		thisColumn,
		// 		currentRow,
		// 		"size",
		// 		button.mx,
		// 		button.my
		// 	);
		// 	// button is in position x, now we use width and height to occupy nearby spaces for height and width etc.
		// 	for (var y = currentRow; y < gridy && y < currentRow + button.mx; y++) {
		// 		// start at current row, ending at current row + height
		// 		for (var x = thisColumn; x < gridx && x < thisColumn + button.my; x++) {
		// 			// start at current column, end at current column + width
		// 			if (grid[y][x] === null) {
		// 				grid[y][x] = false; // false means this cell is occupied/covered
		// 			}
		// 		}
		// 	}
		// 	currentButton = currentButton + 1; // this button is finished go onto the next button in the button array at the top.
		// }
	}
	const handleButtonSize = (event) => {
		setVariant((draft) => {
			let value = event.target.value;
			// console.log(value);
			const orig = getAround(
				buttonIndex,
				variant?.list_details?.buttons[buttonIndex].mx,
				variant?.list_details?.buttons[buttonIndex].my
			);

			// console.log(orig);
			const newarr = getAround(buttonIndex, value.col, value.row);
			// console.log(newarr);
			const toShow = orig.filter((x) => !newarr.includes(x));
			// console.log(toShow);
			const lastItem = [...newarr]?.pop();
			let lastItemColInd = lastItem % variant.list_details.cols;

			// if new button size is smaller than original button size
			if (
				value.col < variant?.list_details.buttons[buttonIndex].mx ||
				value.row < variant?.list_details.buttons[buttonIndex].my
			) {
				const variantCopy = JSON.parse(JSON.stringify(variant));
				const amendItems = variantCopy?.list_details.buttons.map((item) => {
					let index = variantCopy?.list_details.buttons.findIndex(function (
						object
					) {
						return object.id === item.id;
					});
					if (toShow.includes(index)) {
						console.log(item);
						item.my = 1;
						item.mx = 1;
					}
					return item;
				});
				draft.list_details.buttons = amendItems;
				setNewChanges(true);
			}

			// if new button size is bigger than old button size
			if (
				value.col > draft.list_details.buttons[buttonIndex].mx ||
				value.row > draft.list_details.buttons[buttonIndex].my
			) {
				const amendItems = draft.list_details.buttons.map((item) => {
					let index = draft.list_details.buttons.findIndex(function (object) {
						return object.id === item.id;
					});

					if (toShow.includes(index)) {
						item.my = 1;
						item.mx = 1;
					}

					let colInd = index % variant?.list_details.cols;

					if (newarr.includes(index)) {
						const getaroundbutton = getAround(index, item.mx, item.my);
						getaroundbutton.forEach((button) => {
							draft.list_details.buttons[button].mx = 1;
							draft.list_details.buttons[button].my = 1;
						});
						if (colInd === lastItemColInd && (item.my > 1 || item.mx > 1)) {
							draft.list_details.buttons[index + 1].mx = 1;
							draft.list_details.buttons[index + 1].my = 1;
							item.my = 0;
							item.mx = 0;
						}
						if (colInd === lastItemColInd && (item.my > 1 || item.mx > 1)) {
							draft.list_details.buttons[index + 1].mx = 1;
							draft.list_details.buttons[index + 1].my = 1;
							item.my = 0;
							item.mx = 0;
						}
						item.my = 0;
						item.mx = 0;
					}
					return item;
				});
				draft.list_details.buttons = amendItems;
			}
			draft.list_details.buttons[buttonIndex].mx = value.col;
			draft.list_details.buttons[buttonIndex].my = value.row;
		});
		setNewChanges(true);
	};
	// console.log(variant.list_details.buttons[buttonIndex]);
	const handleColourChange = (colour) => {
		let background = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.background =
				background.index;
		});
		setNewChanges(true);
	};

	const sortItems = (a, b) => a.list_number - b.list_number;

	const handleFontColour = (colour) => {
		let foreground = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.foreground =
				foreground.index;
		});
		setNewChanges(true);
	};

	// console.log(sizeOptions);

	const handleNewItem = () => {
		setNewItemModalOpen(true);
	};

	const handleFontSize = (event) => {
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.font =
				event.target.value.value;
		});
		setNewChanges(true);
	};

	return (
		<Box sx={style}>
			<Box
				sx={{
					// height: "40px",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					mb: 1,
				}}
			>
				<Typography
					variant="h4"
					sx={{
						color: Colours.gsblue,
					}}
				>
					Configure Button
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					gap: 2,
					minWidth: "100%",
				}}
			>
				<OptionSelect
					options={
						variant?.list_details?.location === "L" ||
						variant?.list_details?.location === "F"
							? PaymentScreenFuncType
							: variant?.list_details?.location === "L2" ||
							  variant?.list_details?.location === "F3"
							? LargeListFuncType
							: funcType
					}
					value={
						variant?.list_details?.location === "L" ||
						variant?.list_details?.location === "F"
							? PaymentScreenFuncType?.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
							: variant?.list_details?.location === "L2" ||
							  variant?.list_details?.location === "F3"
							? LargeListFuncType.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
							: funcType.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
					}
					label={"Button Type"}
					onChange={handleButtonType}
					type="buttonType"
					width={"100%"}
				/>
				<AutoComplete
					// freeSolo={true}
					options={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? data?.items?.filter((i) => i?.plu_isDeleted === 0)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? data?.screens
									.filter(
										(list) =>
											list?.list_details?.location !== "F3" &&
											list?.list_details?.ignoreThisList !== true &&
											list?.list_variant === 0 &&
											list?.list_isDeleted === 0
									)
									.sort(sortItems)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? modifiers(data?.modifier)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? data?.macro?.filter((i) => i?.macro_isDeleted === 0)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? data?.paymentMethod?.filter(
									(i) => i?.paymentMethod_isDeleted === 0
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? presetServers
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? controls
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? data?.function?.filter(
									(func) =>
										func?.function_number <= 200 &&
										func?.function_isDeleted === 0
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? [{ name: "None" }]
							: [{ name: "None" }]
					}
					value={
						// variant?.list_details?.buttons[buttonIndex]?.functionValue
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? data?.items?.find(
									(item) =>
										parseInt(item.plu_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? data?.screens?.find(
									(item) =>
										parseInt(item.list_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? modifiers(data?.modifier)?.find(
									(item) =>
										parseInt(item?.number) ===
										parseInt(
											variant?.list_details?.buttons[buttonIndex]?.functionValue
										)
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? data?.macro.find(
									(item) =>
										parseInt(item.macro_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? data?.paymentMethod.find(
									(item) =>
										parseInt(item.paymentMethod_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? presetServers.find(
									(item) =>
										item.value ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? controls.find(
									(item) =>
										item.value ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? { name: "None" }
							: data?.function?.find(
									(item) =>
										parseInt(item?.function_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
					}
					label={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? "Item"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? "Screen"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? "Modifier"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? "Macro"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? "Payment Method"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? "Server Number"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? "Control"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? "Function"
							: "Function"
					}
					onChange={handleChangePLU}
					type={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? "Item"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? "Navigate"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? "Modifier"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? "Macro"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? "Payment Method"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? "Preset Server"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? "Blank"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? "Control"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? "Function"
							: "Function"
					}
					width={"100%"}
					data={data}
					// notListedNode={
					// 	// <AccessControl
					// 	// 	userPermissions={userPermission}
					// 	// 	unallowedPermissions={{
					// 	// 		page: "lists",
					// 	// 		allow: false,
					// 	// 		action: "edit",
					// 	// 	}}
					// 	// 	// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					// 	// 	renderNoAccess={() => <></>}
					// 	// >
					// 	<Button
					// 		sx={{ width: "100%", colour: Colours.gsblue }}
					// 		onClick={() => setNewItemModalOpen(true)}
					// 	>
					// 		ADD NEW +
					// 	</Button>
					// 	// </AccessControl>}
					// }
					toggleOpen={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21 &&
						handleNewItem
					}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						// mb: 2,
					}}
				>
					<OptionSelect
						options={sizeOptions}
						value={sizeOptions?.find(
							(button) =>
								button?.value ===
								`${variant?.list_details?.buttons[buttonIndex]?.mx}x${variant?.list_details?.buttons[buttonIndex]?.my}`
						)}
						label={"Button Size"}
						onChange={handleButtonSize}
						type="buttonSize"
						width={"55%"}
					/>
					<IntegratedColourPicker
						selectedColour={
							data?.colours[0]?.palette_details?.colours?.find(
								(col) =>
									col?.index ===
									variant?.list_details?.buttons[buttonIndex]?.style?.background
							)?.colour
						}
						handleColourChange={handleColourChange}
						type="button"
						label="Colour"
					/>
				</Box>
				<FormControlLabel
					value={
						variant?.list_details?.buttons[buttonIndex]?.hideText === 0
							? true
							: false
					}
					control={
						<Checkbox
							checked={Boolean(
								variant?.list_details?.buttons[buttonIndex]?.hideText === 0
									? true
									: false
							)}
							onChange={handleShowText}
							inputProps={{ "aria-label": "show text" }}
							disableRipple
						/>
					}
					label={
						<Typography
							sx={{
								fontSize: 12,
							}}
						>
							Show Text
						</Typography>
					}
					labelPlacement="start"
				/>
				{variant?.list_details?.buttons[buttonIndex]?.hideText === 0 && (
					<motion.div
						initial={{ y: -30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 30, opacity: 1 }}
						transition={{ duration: 0.3 }}
						layout
						data-isopen={
							variant?.list_detail?.buttons[buttonIndex]?.hideText === 0
						}
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							gap: 2,
						}}
					>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								gap: 2,
							}}
						>
							<Box>
								<TextField
									id="outlined-controlled"
									label="Display Text"
									disabled={
										variant?.list_details?.buttons[buttonIndex]
											?.functionType === 13
									}
									rows={3}
									style={{ whiteSpace: "pre-line" }}
									multiline
									size={"small"}
									fullWidth
									value={
										variant?.list_details?.buttons[buttonIndex]
											?.functionType === 13
											? `Server ${variant?.list_details?.buttons[buttonIndex]?.functionValue}`
											: variant?.list_details?.buttons[
													buttonIndex
											  ]?.text?.replaceAll("|", "\n")
									}
									onChange={handleChangeText}
									inputProps={{
										style: { textAlign: "center" },
										// pattern: "/^[a-zA-Z0-9@s&+.!£$%^&*]+$/",
									}}
									// error={!valid}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<OptionSelect
									options={fontSizeOptions ? fontSizeOptions : []}
									value={fontSizeOptions.find(
										(size) =>
											size.name ===
											parseInt(
												variant?.list_details?.buttons[
													buttonIndex
												]?.style?.font?.match(/\d+/)[0]
											)
									)}
									label={"Text Size"}
									onChange={handleFontSize}
									width={"55%"}
									type="fontSize"
								/>
								<IntegratedColourPicker
									selectedColour={
										data?.colours[0]?.palette_details?.colours?.find(
											(col) =>
												col?.index ===
												variant?.list_details?.buttons[buttonIndex]?.style
													?.foreground
										)?.colour
									}
									handleColourChange={handleFontColour}
									type="text"
									label="Colour"
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
									gap: 2,
								}}
							>
								<OptionSelect
									options={textAlignOptions ? textAlignOptions : []}
									value={textAlignOptions.find(
										(align) =>
											align?.tillValue ===
											variant?.list_details?.buttons[buttonIndex]?.style
												?.alignmentV
									)}
									label={"Text Align"}
									onChange={handleTextAlign}
									width={"80%"}
									type="align"
								/>
								<ToggleButton
									size="small"
									selected={Boolean(
										variant.list_details.buttons[buttonIndex].style.font.charAt(
											variant.list_details.buttons[buttonIndex].style.font
												.length - 1
										) === "B"
									)}
									value={Boolean(
										variant.list_details.buttons[buttonIndex].style.font.charAt(
											variant.list_details.buttons[buttonIndex].style.font
												.length - 1
										) === "B"
									)}
									onChange={() => {
										handleFormat(
											!Boolean(
												variant?.list_details?.buttons[
													buttonIndex
												]?.style?.font?.charAt(
													variant?.list_details?.buttons[buttonIndex]?.style
														?.font?.length - 1
												) === "B"
											)
										);
									}}
									aria-label="bold"
								>
									<FormatBoldIcon />
								</ToggleButton>
							</Box>
							{/* <ArrowButton
								onClick={handleCopyFormat}
								colour={Colours.gsblue}
								icon={
									<FormatPaintIcon
										// sx={{position: "absolute", right: "1rem", top: "1rem"}}
										sx={{
											fontSize: 25,
											color: Colours.gsblue,
										}}
									/>
								}
							/> */}
							{/* <ToggleButtonGroup
								color="primary"
								value={copyStyles}
								// exclusive

								onChange={(event, newFormats) =>
									handleCopyFormat(event, newFormats)
								}
								aria-label="Platform"
							>
								<ToggleButton value="font">Font</ToggleButton>
								<ToggleButton value="colour">Colour</ToggleButton>
								<ToggleButton value="all">All</ToggleButton>
							</ToggleButtonGroup> */}
						</Box>
					</motion.div>
				)}
			</Box>
		</Box>
	);
}
