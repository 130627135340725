import { Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

import { Autocomplete, Chip, ListItemText, TextField, Typography } from "@mui/material";
import { getTextColour } from "../views/datagrids/utils";
import React from "react";
import { DataGridContext } from "../context/DataGridContext";

const ReactHookFormTagField = ({
	name,
	label,
	control,
	defaultValue,
	onChange,
	children,
	fieldValueToSave,
	selectoptions,
	nameToShow,
	...props
}) => {
    const { data, setData } = React.useContext(DataGridContext);
	return (
		<Controller
			render={({ field: { onChange, value, ref, onBlur } }) => (
				<Autocomplete
					multiple
					filterSelectedOptions
					options={selectoptions}
					getOptionLabel={(option) =>
						option.tag_name
					}
                    // value={value}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							label={label}
						/>
					)}
                    isOptionEqualToValue={(o,v)=> o.tag_ref === v.tag_ref}

					onChange={(e, data) => onChange(data.map((v) => v['tag_ref']))}
					// {...props}
					limitTags={5}
					size="small"
                    renderOption={(props, item) => {
                        console.log(item)
                    	// const option = data?.tags?.find((t) => t?.tag_ref === item);
                    	return (
                    		<li {...props} key={item.tag_ref}>
                    			<ListItemText>{item.tag_name}</ListItemText>
                    		</li>
                    	);
                    }}
                    renderTags={(value, getTagProps) =>
                        value?.map((tag, index) => {
                            const { key, ...rest } = getTagProps({ index });
                            // console.log(option)

                            // const tag = data?.tags?.find((t) => t?.tag_ref === option);
                            // console.log(tag)
                            return (
                                <Chip
                                    label={
                                        <Typography
                                            style={{
                                                whiteSpace: "normal",
                                                color:
                                                    getTextColour(tag?.tag_details?.styling?.colour)
                                                        ?.colour || "black",
                                                fontSize: 12,
                                            }}
                                        >
                                            {tag?.tag_name}
                                        </Typography>
                                    }
key={key}
{...rest}
                                    deleteIcon={
                                        <CloseIcon
                                            color={
                                                getTextColour(tag?.tag_details?.styling?.colour)
                                                    ?.colour || "white"
                                            }
                                        />
                                    }
                                    sx={{
                                        // height: "100%",
                                        backgroundColor: tag?.tag_details?.styling?.colour,
                                        padding: "2px 4px 2px 8px",
                                        borderRadius: 2,

                                        "& svg": {
                                            fontSize: "12px",
                                            cursor: "pointer",
                                            padding: "4px",
                                            color: `${
                                                getTextColour(tag?.tag_details?.styling?.colour)
                                                    ?.colour
                                            } !important`,
                                        },
                                    }}
                                />
                            );
                        })
                    }
				/>
			)}
			name={name}
			control={control}
			defaultValue={defaultValue}
		/>
	);
};
export default ReactHookFormTagField;
