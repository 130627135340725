import {
	Autocomplete,
	Box,
	Button,
	Collapse,
	Fade,
	FormControlLabel,
	IconButton,
	LinearProgress,
	MenuItem,
	Modal,
	Popover,
	Select,
	Stack,
	Switch,
	TextField,
	List,
	ListItem,
	Typography,

} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { dataGridStyle } from "../../styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Controller,
	FormProvider,
	useFieldArray,
	useForm,
} from "react-hook-form";
import { DataGridContext } from "../../../../context/DataGridContext";
import {
	DataGrid,
} from "@mui/x-data-grid";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { API_ENDPOINTS } from "../../../../API/apiConfig";
import format from "date-fns/format";

import DisplayGrid from "./DisplayGrid";
import { modiferDataColumns } from "../../utils";
import AccessControl from "../../../AccessControl";

export default function HistoryModal({
	modalConfig,
	setModalConfig,
	gridList,
	contextName,
	table,
	apiRef,
	config,
}) {
	const methods = useForm({
		defaultValues: modalConfig?.editRow?.row,
	});
	const { getAccessTokenSilently } = useAuth0();
	const [rows, setRows] = React.useState([]);
	const [loading, setIsLoading] = React.useState(true);
	const [selectedRow, setSelectedRow] = React.useState({});
	const [gridDisplay, setGridDisplay] = React.useState(null);
	const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

	const [cellModesModel, setCellModesModel] = React.useState({});
	const [open, setOpen] = React.useState(false);

		const { data, setData, siteFilterValue,
			priceBandFilterValue, priceBandColors } = React.useContext(DataGridContext);

	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].historyModalVisible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};
	const columns = getVisibleColumns();

	const [columnVisibilityModel, setColumnVisibilityModel] =
		React.useState(columns);
	// console.log(columns);

	const [coordinates, setCoordinates] = React.useState({
		rowIndex: 0,
		colIndex: 0,
	  });

	const handleCellFocus = React.useCallback((event) => {
		event.defaultMuiPrevented = true
		setSelectedRow(event.row);
	},
	[]);

	const handleCellKeyDown = React.useCallback((params, event) => {
			setRowSelectionModel([params.id]);
			setSelectedRow(rows.find((i) => i.uid === params.row.uid));
			},
		 []);

		 
		const getNew = (newObj, oldObj) => {
    if (Object?.keys(oldObj)?.length === 0 
        && Object?.keys(newObj)?.length > 0)
        return newObj;

    let diff = {};
    for (const key in oldObj) {
if(typeof oldObj[key] === 'object') {

		for (const key2 in oldObj[key]) {
			 if(Array.isArray(oldObj[key][key2])) {
								if (JSON.stringify(oldObj[key][key2]) !== JSON.stringify(newObj[key][key2])  || oldObj[key][key2].length !== newObj[key][key2].length) {
									diff[key2] = newObj[key][key2]; 
								}
						
					} else 
			if (newObj[key][key2] && oldObj[key][key2].toString() !== newObj[key][key2].toString() ) {
				diff[key2] = newObj[key][key2]; 
			}
	}
} else
        if (newObj[key]?.toString() && oldObj[key]?.toString() !== newObj[key]?.toString()) {
			diff[key] = newObj[key]
        }
    }
    return diff;
}

const getDiff = (newObj, oldObj) => {
    if (Object?.keys(oldObj)?.length === 0 
        && Object?.keys(newObj)?.length > 0)
        return newObj;
console.log(newObj, oldObj)
    let diff = {};
    for (const key in oldObj) {
		console.log(key)
if(typeof oldObj[key] === 'object') {

		for (const key2 in oldObj[key]) {
			console.log(key2)
			 if(Array.isArray(oldObj[key][key2])) {
								if (JSON.stringify(oldObj[key][key2]) !== JSON.stringify(newObj[key][key2])  || oldObj[key][key2].length !== newObj[key][key2].length) {
									// diff[key2] = newObj[key][key2]; 
									diff[key2] = { was: oldObj[key][key2],now: newObj[key][key2]}

								
								}
						
					} else 
			if (newObj[key][key2] && oldObj[key][key2].toString() !== newObj[key][key2].toString() ) {
// 				diff[key2] = newObj[key][key2]; 
// 				diff['was'] = 	oldObj[key][key2]
// diff['now'] = 	newObj[key][key2]
diff[key2] = { was: oldObj[key][key2],now: newObj[key][key2]}

			}
	}
} else
        if (newObj[key]?.toString() && oldObj[key]?.toString() !== newObj[key]?.toString()) {
			console.log( oldObj[key], newObj[key])
			diff[key] = { was: oldObj[key],now: newObj[key]}
// diff['was'] = oldObj[key]
// diff['now'] = newObj[key]
console.log(key, oldObj[key], newObj[key])


		}
    }

    return diff;
}


	const handleCellEditStop = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true;
	}, []);

	const getHistory = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS.getHistory}?table=${table}&ref=${parseInt(
					modalConfig.row.row[`${table}_ref`]
				)}`,
				config
			)
			.then(function (response) {
				console.log(
					"res",
					response.data.sort(
						(a, b) =>
							Date.parse(b[`${table}_auditTime`]) -
							Date.parse(a[`${table}_auditTime`])
					)
				);
				setRows(
					response.data.sort(
						(a, b) =>
							b.uid -
							a.uid
					)
				);
				setSelectedRow(response.data[0]);
				setRowSelectionModel([
					response.data.find((i) => i[`${table}_isCurrent`] === 1).uid,
				]);
				setCoordinates({
					rowIndex: 0,
					colIndex: 0,
				  })		
				setIsLoading(false);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};


	React.useEffect(() => {
		if (modalConfig?.row?.row) {
			getHistory();
		}
	}, [modalConfig.open]);

	React.useEffect(() => {
		setSelectedRow(rows.find((i) => i.uid === rowSelectionModel[0]));
	}, [rowSelectionModel]);

	const [rowModesModel, setRowModesModel] = React.useState({});

	// const handleEditClick = (id) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	// };

	const processRowUpdate = (newRow) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	};


	const dataColumnsBuilder = [...gridList.columns];


const useStyles = makeStyles(() => ({
	headerWrap: {
		whiteSpace: "break-spaces",
		wordWrap: "break-word",
		width: "900px", // adjust this value as needed
	},
}));
const classes = useStyles();

for (let i = 0; i < gridList?.columns?.length; i++) {
	if (gridList.columns[i].visible === true) {
		if (gridList.columns[i].type === "priceModifier" && open === true) {
			const newColumns = modiferDataColumns(
				gridList.columns[i],
				rows,
				data,
				classes,
				apiRef,
				priceBandColors,
				siteFilterValue,
				priceBandFilterValue,
				'history'
			);
			// console.log(newColumns);
			if (newColumns.length > 0) {
				dataColumnsBuilder.push(...newColumns);
			}
		} 
	}
}

	const namePairs = [{ dbName: 'server_name', displayName: 'Server Name' },{ dbName: 'userLevel', displayName: 'Role' },{ dbName: 'selMin', displayName: 'Min Selections' },{ dbName: 'selMax', displayName: 'Max Selections' }, { dbName: 'priceModifiers', displayName: 'Modifiers' }, { dbName: 'displayName', displayName: 'Display Name' }, { dbName:  `${table}_isDeleted` ,displayName: 'Deleted'}, { dbName:  `${table}_name` ,displayName: `${table} Name` },{ dbName:  `majorGroup` ,displayName: 'Major Group'}, { dbName:  `salesGroup` ,displayName: 'Sales Group'},{ dbName:  `screen_variants` ,displayName: 'Screen Variants'}]
	dataColumnsBuilder.push(
		{
			field: `${table}_isCurrent`,
			headerName: "Current",
			type: "boolean",
			flex: 1,
			defaultvalue: "", // what is inside modal fields
			editable: false,
			sortable: true,
			display: "flex",
			disableExport: true,
			valueGetter: (value) => {
				if (value === 1 || value === "1") {
					value = true;
				} else if (value === 0 || value === "0") {
					value = false;
				} else {
					value = null;
				}
				return value;
			},
		},
		{
			field: `${table}_auditTime`,
			headerName: "Last modified",
			type: "text",
			flex: 1.5,
			defaultvalue: "", // what is inside modal fields
			editable: false,
			sortable: true,
			disableExport: true,
			display: "flex",
			// ...GRID_DATETIME_COL_DEF,
			// resizable: false,

			// filterOperators: getGridDateOperators(true).map((item) => ({
			//   ...item,
			//   InputComponent: GridFilterDateInput,
			//   InputComponentProps: { showTime: true },
			// })),
			valueFormatter: (value) => {
				if (value) {
					return format(value, "h:mma do MMM yy");

					// return new Date(value);
				}
				return "";
			},
		
		},
		{
			field: `${table}_auditName`,
			headerName: "Modified by",
			type: "text",
			flex: 1.5,
			display: "flex",
			defaultvalue: "", // what is inside modal fields
			editable: false,
			sortable: true,
			disableExport: true,},
	
	);

dataColumnsBuilder.push(	{
	field: `change`,
	headerName: "Changed",
	type: "text",
	flex: 1,
	defaultvalue: "", // what is inside modal fields
	editable: false,
	sortable: true,
	disableExport: true,

	valueGetter: (value, row) => {
		const val1 = rows.findIndex((i)=> parseInt(i.uid) === parseInt(row.uid))

		
// if(rows[val1 + 1]) {
const prev = rows[val1 + 1]

		const latest = rows[val1]
		// console.log('original obj', obj2)
		const smallest = rows.reduce((prev, curr) => prev.uid < curr.uid ? prev : curr);
console.log(val1, smallest)
if (rows[val1].uid === smallest.uid) {
	return {'Created': true}
} else {
let dontMap = [`${table}_auditName`,`${table}_isCurrent`,'mmCat', 'cashDrawer', 'trainingServer', 'MMGuid',`${table}_auditTime`,`${table}_uid`, `${table}_ref`, `${table}_number`, 'uid']
let user
if (prev && latest) {
 user = getNew(latest, prev);
dontMap.map((i)=> user.hasOwnProperty(i)
&& delete user[i] )

		return user
}

// }
	} 
	// else if(rows.length === 1){
	// 	return {'Created': true}
	// }
},
valueFormatter: (value) => {

if (value && Object?.keys(value).length > 0 ) {
// console.log(value)

Object?.keys(value)?.map((key, index) => {
if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 1) {
	Object.defineProperty(value, 'Deleted',
		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
	delete value[key];
} else
if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 0) {
	Object.defineProperty(value, 'Reinstated',
		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
	delete value[key];
}else {
 const name = namePairs?.find((i)=> i?.dbName === key)?.displayName
if(name) {
 Object.defineProperty(value, name,
	Object.getOwnPropertyDescriptor(value, key));
delete value[key];
 }
}
})
}  

},
renderCell: (params) => {
if (params?.value && Object?.keys(params?.value).length > 0 ) {
return (
	<List >
		
	{Object?.keys(params?.value)?.map((key, index) => 
					 <ListItem disableGutters><Typography variant="datagrid">{key.toLowerCase().replace(/^./, str => str.toUpperCase())}
				  </Typography></ListItem> 
		)
	}
	</List>

);

} else return null;
},
})

	const { setNewData } = React.useContext(DataGridContext);

	const onSubmit = async () => {
		let form = methods.getValues();

		if (gridList.gridPage === "printers") {
			let obj = {};
			form.modalValues.map((val) => {
				obj[val.Site] = val.Printers;
			});
			form.printer_details.sites = obj;
		}
		if (gridList.gridPage === "items") {
			form = form.plus;
		}
		processRowUpdate(form, modalConfig.editRow.row, modalConfig.isNew);
		if (modalConfig.isNew) {
			setNewData(true);
		}
		// setModalVisible(false);
	};

	const makeCurrent = async (newRow) => {
		const token = await getAccessTokenSilently();

		// copy item history
		const rowsNew = [...rows];

		// find index of original current row
		const originalCurrent = rows.findIndex(
			(i) => i[`${table}_isCurrent`] === 1
		);
		const originalCurrentObj = rows.find((i) => i[`${table}_isCurrent`] === 1);
		// find index of new current row

		const newCurrent = rows.findIndex((i) => i.uid === newRow.uid);
		// change original to not current
		// rowsNew[originalCurrent][`${table}_isCurrent`] = 0;
		// // change new to  current
		// console.log(originalCurrentObj);
		// console.log(newCurrent);
		// rowsNew[newCurrent][`${table}_isCurrent`] = 1;
		newRow[`${table}_isCurrent`] = 1;
		newRow[`${table}_isDeleted`] = 0;
		// console.log(newRow);
		const newRowData = {
			updates: [newRow],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		let postStatus;
		axios
			.post(API_ENDPOINTS[table], newRowData, config)
			.then(function (response) {
				// postStatus = response.status;
				// console.log(isNew);
				if (response.status === 200) {


					const updatedRow = response?.data[table][0];
					updatedRow.uid = response?.data[table][0][`${table}_uid`];
					console.log(data[contextName]);
					const newData = [...data[contextName]];
					// .filter(
					// 	(i) => i.uid !== rowsNew[originalCurrent].uid
					// );
					const indexToReplace = newData.findIndex(
						(row) => row.uid === originalCurrentObj.uid
					);
					console.log(indexToReplace);
					console.log('1',newData[indexToReplace]);

					// newRow[`${table}_auditTime`] =
					// 	response?.data[table][0][`${table}_auditTime`];
					newData[indexToReplace] = updatedRow;
					console.log('2',newData[indexToReplace]);
					console.log('2',newData);

					const updatedData = {
						...data,
						[contextName]: [...newData],
					};
					// } else {
					// updatedData = {
					// 	...data,
					// 	[contextName]: data[contextName],
					// };
					// rowsNew[originalCurrent][`${table}_isCurrent`] = 0;
					// change new to  current
					// console.log(originalCurrent);
					// rowsNew[newCurrent][`${table}_isCurrent`] = 1;
					// setRows(rowsNew);
					setData(updatedData);

					// let updatedData;
					// if (isNew === true) {
					// console.log('orig current', originalCurrentObj)
					// const updatedRow = response?.data[table][0];
					// updatedRow.uid = response?.data[table][0][`${table}_uid`];
					// console.log(data[contextName]);
					// const newData = [...data[contextName]];
					// .filter(
					// 	(i) => i.uid !== rowsNew[originalCurrent].uid
					// );
					// console.log('new data 1', newData)
					// const indexToReplace = newData.findIndex(
					// 	(row) => parseInt(row.uid)=== parseInt(originalCurrentObj.uid)
					// );
					// console.log('index to replace',indexToReplace);
					// console.log('new data at index 1',newData[indexToReplace]);

					// newRow[`${table}_auditTime`] =
					// 	response?.data[table][0][`${table}_auditTime`];
					// newData[indexToReplace] = updatedRow;
					// console.log('new data at index 2',newData[indexToReplace]);

					// console.log('new data 2', newData);
					// const updatedData = {
					// 	...data,
					// 	[contextName]: [...newData],
					// };
					// } else {
					// updatedData = {
					// 	...data,
					// 	[contextName]: data[contextName],
					// };
					// rowsNew[originalCurrent][`${table}_isCurrent`] = 0;
					// change new to  current
					// console.log(originalCurrent);
					// rowsNew[newCurrent] = updatedRow;
					// setData(updatedData);

					// setRows(rowsNew.sort(
					// 	(a, b) =>
					// 		b.uid -
					// 		a.uid
					// ));

					getHistory();
					// setSnackbar({
					// 	children: "successfully saved",
					// 	severity: "success",
					// });
				// } else {
					// setSnackbar({
					// 	children: "Error, couldn't save data",
					// 	severity: "error",
					// });
				// }
	}})
			.catch(function (error) {
				console.log(error);
				postStatus = error.response.status;
				console.log(error.response.data.error);
				// setSnackbar({
				// 	children: error?.response?.data?.error
				// 		? error?.response?.data?.error + " - couldn't save data"
				// 		: "Error - couldn't save data",
				// 	severity: "error",
				// });
			});
	};

	const getChanges = (uid) => {
		const val1 = rows.findIndex((i)=> parseInt(i.uid) === parseInt(uid))

		
// if(rows[val1 + 1]) {
const prev = rows[val1 + 1]

		const latest = rows[val1]
		// console.log('original obj', obj2)
		const smallest = rows.reduce((prev, curr) => prev.uid < curr.uid ? prev : curr);

if (uid === smallest.uid) {
	return {}
} else {
let dontMap = [`${table}_auditName`,'Created',`${table}_isCurrent`,`${table}_isDeleted`,'mmCat', 'cashDrawer', 'trainingServer', 'MMGuid',`${table}_auditTime`,`${table}_uid`, `${table}_ref`, `${table}_number`, 'uid']
let user
if (prev && latest) {
	console.log(getDiff(latest, prev))

 user = getDiff(latest, prev);

dontMap.map((i)=> user.hasOwnProperty(i)
&& delete user[i] )
console.log(user)
// console.log(formatChanges(user))
		return formatChanges(user)
}

// }
	} 
	}


	const formatChanges = (value) => {
		console.log(value)
		if (value && Object?.keys(value).length > 0 ) {
// console.log(value)

Object?.keys(value)?.map((key, index) => {
if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 1) {
	Object.defineProperty(value, 'Deleted',
		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
	delete value[key];
} else
if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 0) {
	Object.defineProperty(value, 'Reinstated',
		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
	delete value[key];
}else {
 const name = namePairs?.find((i)=> i?.dbName === key)?.displayName
if(name) {
 Object.defineProperty(value, name,
	Object.getOwnPropertyDescriptor(value, key));
delete value[key];
 }
}
})
}  
return value
	}

	React.useEffect(() => {
		const { rowIndex, colIndex } = coordinates;
		if(apiRef && apiRef?.current &&  apiRef?.current?.scrollToIndexes && rowIndex === 0 && colIndex === 0) {
		apiRef?.current?.scrollToIndexes(coordinates);
		}
	  }, [coordinates]);

	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true;
	}, []);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [value, setValue] = React.useState('');
  
	const handlePopoverOpen = (event) => {

	  const field = event.currentTarget.dataset.field;
	//   console.log(event.currentTarget.dataset.field)
	  const uid = event.currentTarget.parentElement.dataset.id;
	//   console.log(event.currentTarget.parentElement.dataset)
	//   console.log(rows)
	//   const row = rows?.find((r) => parseInt(r?.uid) === parseInt(uid))
	//   const val = apiRef.current.getCellParams(uid,field).value;
let change = getChanges(uid)
console.log(change)

 

// valueFormatter: (value) => {

// if (value && Object?.keys(value).length > 0 ) {
// // console.log(value)

// Object?.keys(value)?.map((key, index) => {
// if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 1) {
// 	Object.defineProperty(value, 'Deleted',
// 		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
// 	delete value[key];
// } else
// if (key === `${table}_isDeleted` && value[`${table}_isDeleted`] === 0) {
// 	Object.defineProperty(value, 'Reinstated',
// 		Object.getOwnPropertyDescriptor(value,  `${table}_isDeleted`));
// 	delete value[key];
// }else {
//  const name = namePairs?.find((i)=> i?.dbName === key)?.displayName
// if(name) {
//  Object.defineProperty(value, name,
// 	Object.getOwnPropertyDescriptor(value, key));
// delete value[key];
//  }
// }
// })
// }  

// },
	//   console.log(val)
	if(Object.keys(change).length > 0) {
	  setValue(change);
	  setAnchorEl(event.currentTarget);}
	};
  
	const handlePopoverClose = () => {
	  setAnchorEl(null);
	};
  
	const popoverOpen = Boolean(anchorEl);

	return (
		<>
			<FormProvider {...methods}>
				<Modal
					open={modalConfig.open}
					sx={{ overflow: "hidden" }}
					onClose={() => {
						setModalConfig({ open: false, row: "" });
						setRows([]);
						setRowSelectionModel([]);
					}}
				>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box sx={style}>
							<Box sx={style2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										height: "10%",
										pb: 2,
									}}
								>
									<Typography variant="h3">
										{gridList.modalTitle} History
									</Typography>
									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => {
											setModalConfig({
												row: "",
												open: false,
											});
											setRows([]);
											setIsLoading(true);
											setSelectedRow(null);
											setOpen(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>
								{(table === "list" || gridList.gridPage === "pricing") &&(
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											height: "10%",
											pb: 2,
										}}
									>
										<FormControlLabel
											control={
												<Switch
													checked={open}
													onChange={() => setOpen(!open)}
												/>
											}
											label={<Typography variant="switch">{table === "list" ? 'Snapshot' : 'Show Modifiers'}</Typography>}
										/>
									</Box>
								)}
								{/* {gridList.gridPage === "pricing" && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											height: "10%",
											pb: 2,
										}}
									>
										<FormControlLabel
											control={
												<Switch
													checked={open}
													onChange={() => setOpen(!open)}
												/>
											}
											label={<Typography variant="switch">Show modifiers</Typography>}
										/>
									</Box>
								)} */}
								<Box
									sx={{
										// ...dataGridStyle,
										height: "70%",
										flex: 1,
										width: "100%",
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "flex-start",
										gap: open && 4,
									}}
								>
									<DataGrid
										columns={dataColumnsBuilder}
										rows={rows}
										apiRef={apiRef}
										editMode="row"
										getRowId={(row) => row?.uid}
										getRowHeight={() => "auto"}
										loading={loading}
										showCellVerticalBorder
										showCellHorizontalBorder
										onCellKeyDown={(params)=>handleCellKeyDown(params)}
										rowModesModel={cellModesModel}
										
										onRowEditStop={handleCellEditStop}
										onRowClick={(params) => handleCellFocus(params)}
										onRowModesModelChange={null}
										columnVisibilityModel={columnVisibilityModel}
										onColumnVisibilityModelChange={(newModel) =>
											setColumnVisibilityModel(newModel)
										}
										columnHeaderHeight={gridList.gridPage === "pricing" && open === true ? 130 : 56}

										onRowDoubleClick={handleDoubleCellClick}
										hideFooterSelectedRowCount
										onRowSelectionModelChange={(newRowSelectionModel) =>
											{ setRowSelectionModel(newRowSelectionModel) }
										}
										initialState={{
											sorting: {
												sortModel: [
													{ field: `${table}_isCurrent`, sort: "desc" },
												],
											},
										}}
										rowSelectionModel={rowSelectionModel}
										sx={{
											...dataGridStyle,
											"& .MuiDataGrid-root": {
												height: "100%",
												width:'100%',
											},
											'& .MuiDataGrid-columnHeaderTitleContainerContent': {
						whiteSpace: 'normal !important',
						wordWrap: 'break-word !important',
						lineHeight: 'normal',
				
						// p: (gridList.gridPage === 'pricing' && open === true) && 0,
						// m: (gridList.gridPage === 'pricing' && open === true) && 0,
						height: '100%',
						width:'100%',
		// display:(gridList.gridPage === 'pricing' && open === true) &&'flex',
		// alignItems: (gridList.gridPage === 'pricing' && open === true) &&'center',
		// justifyContent:(gridList.gridPage === 'pricing' && open === true) &&'center',
                    
                        // maxWidth: '180px',
						// transform: (gridList.gridPage === 'pricing' && open === true) && 'rotate(-90deg)',

						...(gridList.gridPage === 'pricing' && open === true && {
						  transform: 'rotate(-90deg)',
						//   minWidth: '180px',
						  display:'flex',
						  alignItems: 'center',
                          justifyContent: 'center',
						  p:0 ,
						  m: 0
						}),
					  },
					  '& .MuiDataGrid-columnHeader': {
						// p: (gridList.gridPage === 'pricing' && open === true) && 0,
						// m: (gridList.gridPage === 'pricing' && open === true) && 0,
						height:'100%',
						width:'100%',
						...(gridList.gridPage === 'pricing' && open === true && {
						
							p:0 ,
							m: 0
						  }),
					  },
					  
										}}
										slots={{
											// toolbar: EditToolbar,
											footer: CustomFooter,
											loadingOverlay: LinearProgress,
										}}
										slotProps={{
											footer: {
												// cellMode,
												selectedRow,
												gridList,
												// setSelectedRow,
												// cellModesModel,
												// setCellModesModel,
												rows,
												makeCurrent,
												table,
											},
											// row: {
											// 	onFocus: handleCellFocus,
											// },
											// row: {
											// 	onMouseEnter: handleRowOver,
											// 	onMouseLeave: handleRowLeave,
											// },
											// cell: {
											// 	onMouseEnter: handlePopoverOpen,
											// 	onMouseLeave: handlePopoverClose,
											//   },
										}}
									/>
									{/* <GridDrawer
										open={open}
										setOpen={setOpen}
										selectedRow={rows[index]}
									/>
                                     */}
									  {/* <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
		<>
{value && Object?.keys(value).length > 0 && 
	
		<>
		{Object?.keys(value)?.map((key, index) => <List >

		{key !== 'Deleted' && key !== 'Reinstated' && key !== 'buttons' &&
		<>{!Array.isArray(value[key]['was'])|| !Array.isArray(value[key]['now']) ?
						 <ListItem><Typography  sx={{fontWeight:'bold', fontSize: 13,
							letterSpacing: "0.2px",mr: 1}}>{key.toLowerCase().replace(/^./, str => str.toUpperCase())} 
					  </Typography><Typography  variant='datagrid'>{`Was: ${key === 'price' ? '£' + value[key]['was'] :  value[key]['was'] }, Now: ${key === 'price' ? '£' + value[key]['now'] : value[key]['now']}`}
					  </Typography></ListItem>

					   : value[key]['now']?.map((item,index)=> (<ListItem><Typography  sx={{fontWeight:'bold', fontSize: 13,
						letterSpacing: "0.2px",mr: 1}}>{`${data?.sites?.find((site) => site?.site_ref === item?.ref)?.site_name} - ${data?.priceband?.find(
						 			(band) => band.priceband_ref === item?.priceBand)?.priceband_name} `}</Typography>
									<Typography variant="datagrid">{`Was: ${value[key]['was'][index] ? '£' + value[key]['was'][index] : '£0'}, Now: £${item.modifier} `}</Typography></ListItem>))
}

					  </>
		}
		</List>
			)
		}
		</>
} 
			</>
      </Popover> */}
									{table === "list" && (
										<Collapse orientation="horizontal" in={open}>
											<Box
												sx={{
													width: "100%",
													height: "100%",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<DisplayGrid selectedRow={selectedRow} />
											</Box>
										</Collapse>
									)}
									
								</Box>
								{/* <CustomFooter
									makeCurrent={makeCurrent}
									selectedRow={selectedRow}
									table={table}
								/> */}
							</Box>
						</Box>
					</form>
				</Modal>
			</FormProvider>
		</>
	);
}

export const CustomFooter = (props) => {
	const { makeCurrent, selectedRow, table, rows,gridList } = props;
	const {userPermission} = useContext(DataGridContext)
	return (
		// <GridFooterContainer>
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "flex-end",
				// pr: 2,
				height: "10%",
				pt: 2,
			}}
		>
			{/* <Box> */}
			<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							// allow: false,
							action: "edit",
							strict: false,
						}}
						// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
						renderNoAccess={() => <></>}
					>
			<Button
				variant="contained"
				disabled={!selectedRow || (selectedRow[`${table}_isCurrent`] === 1 && selectedRow[`${table}_isDeleted`] === 0)}
				onClick={() => makeCurrent(selectedRow)}
				sx={{ justifySelf: "flex-end" }}
			>
			{selectedRow && rows && rows[0][`${table}_isDeleted`] === 1  ? 'Reinstate' : !selectedRow || (selectedRow[`${table}_isCurrent`] === 1 && selectedRow[`${table}_isDeleted`] === 0) ? 'Current Version' : 	'Make Current'}
			</Button>
			{/* <GridPagination {...paginationProps} ActionsComponent={Pagination} /> */}
			{/* </Box> */}
			</AccessControl>
		</Box>
		// </GridFooterContainer>
	);
};

const style2 = {
	height: "100%",
	width: "100%",

	p: 4,
	overflow: "hidden",
	display: "flex",
	justifyContent: "flex-start",
	// gap: 2,
	flexDirection: "column",

	overflowY: "scroll",

	"&::-webkit-scrollbar": {
		width: "3px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "#f5f5f5",
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// backgroundColor: "#f5f5f5",
	},
	"& .MuiDataGrid-root": {
		border: "none",
		height: "100%",
		// width: "100%",
		flex: 1,
		overflow: "auto",
		whiteSpace: "normal !important",
		wordWrap: "break-word !important",
		fontSize: 13,
		// maxWidth: "100%",
	},
	"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
		outline: "none !important",
	},
	"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
		{
			outline: "none !important",
		},
	// "& .MuiDataGrid-footerContainer": {
	// 	height: "30px",
	// 	minHeight: "30px",
	// },
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "10px",
	width: 1000,
	height: "80vh",
	// height: "100%",
	// minHeight:
	// 	// 100 +
	// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
	bgcolor: "white",
	boxShadow: 24,
	// p: 4,
	overflow: "auto",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	// padding: 0,
	gap: 2,
	flexDirection: "column",
};
