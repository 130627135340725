import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import { DataGridContext } from "../context/DataGridContext";
import { Snackbar } from "@mui/material";
import SnackbarContext from "../context/SnackbarContext";

export const CustomSnackBar = () => {
const {snackbar, setSnackbar} = React.useContext(SnackbarContext)
// console.log(snackbar)
	return (
			 <Snackbar
				open={snackbar?.open}
				autoHideDuration={snackbar?.duration} // Adjust the duration as needed
				onClose={() => setSnackbar({
					open: false,
					message: [],
					duration: 3000,
					severity: "success",
				})}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<MuiAlert
					onClose={() => setSnackbar({
						open: false,
						message: [],
						duration: 3000,
						severity: "success",
					})}
					severity={snackbar?.severity}
					elevation={6}
					variant="filled"
				>
					{snackbar?.message?.map((message, index) => (
						<div key={index}>{message}</div>
					))}
				</MuiAlert>
			</Snackbar> 
	);
};
