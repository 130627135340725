import {
	Autocomplete,
	Box,
	Button,
	IconButton,
	MenuItem,
	Modal,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Controller,
	FormProvider,
	useFieldArray,
	useForm,
} from "react-hook-form";
import { DataGridContext } from "../../../../context/DataGridContext";
import TagModules from "./TagModules";
import NewItemForm from "./NewItemForm";
import NewStaffForm from "./NewStaffForm";
import NewCategoryForm from "./NewCategoryForm";
import NewPrinterForm from "./NewPrinterForm";
import NewListForm from "./NewListForm";

import NewRoleModal from "../../../../components/NewRoleModal";
import NewMacroModal from "../../../../components/NewMacroModal";
import NewSiteForm from "./NewSiteForm";

export const resetFormByTouchedFields = (form) => {
	const fields = Object.keys(form.formState.touchedFields);

	fields.forEach((field) => form.resetField(field));
};

export default function GridModal({
	modalConfig,
	setModalConfig,
	gridList,
	modalVisible,
	setModalVisible,
	processRowUpdate,
	contextName,
	table,
}) {
	const methods = useForm({
		defaultValues: modalConfig?.editRow?.row,
	});

	React.useEffect(() => {
		let defaultValues;
		if (modalConfig.isNew === true) {
			defaultValues = gridList.defaultValues;
		} else defaultValues = modalConfig?.editRow?.row;
		methods.reset({ ...defaultValues });
	}, [
		modalConfig?.editRow?.row,
		setModalVisible,
		modalVisible,
		setModalConfig,
	]);

	const { setNewData } = React.useContext(DataGridContext);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		width: gridList.gridPage === "policies" ? 800 : 410,
		maxHeight: "90vh",
		// height: "100%",
		// minHeight:
		// 	// 100 +
		// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
		bgcolor: "white",
		boxShadow: 24,
		// p: 4,
		overflow: "auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// padding: 0,
		gap: 2,
		flexDirection: "column",
	};

	const onSubmit = async () => {
		let form = methods.getValues();

		if (gridList.gridPage === "printers") {
			let obj = {};
			form.modalValues.map((val) => {
				obj[val.Site] = val.Printers;
			});
			form.printer_details.sites = obj;
		}
		if (gridList.gridPage === "items") {
			form = form.plus;
		}
		processRowUpdate(form, modalConfig.editRow.row, modalConfig.isNew);
		if (modalConfig.isNew) {
			console.log("is new");
			setNewData(true);
		}
		setModalVisible(false);
	};

	// console.log(gridList.gridPage);
	return (
		<div>
			<FormProvider {...methods}>
				<Modal open={modalVisible} sx={{ overflow: "hidden" }}>
					{/* <FormProvider {...methods}> */}
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box sx={style}>
							<Box sx={style2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography variant="h3">
										{modalConfig.isNew === true ? "New" : "Edit"}{" "}
										{gridList.modalTitle}
									</Typography>

									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => {
											setModalConfig({
												focusField: "",
												editRow: null,
												open: false,
											});
											setModalVisible(false);
											methods.reset();
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>

								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={2}
									sx={{ mt: 2, mb: 2 }}
								>
									{gridList.gridPage === "screens" ? (
										<NewListForm methods={methods} modalConfig={modalConfig} />
									) : gridList.gridPage === "items" ? (
										<NewItemForm methods={methods} modalConfig={modalConfig} />
									) : gridList.gridPage === "tags" ? (
										<TagModules
											methods={methods}
											modalConfig={modalConfig}
											table={table}
										/>
									) : gridList.gridPage === "printers" ? (
										<NewPrinterForm
											methods={methods}
											modalConfig={modalConfig}
											table={table}
										/>
									) : gridList.gridPage === "categories" ||
									  gridList.gridPage === "majorcategories" ? (
										<NewCategoryForm
											methods={methods}
											table={table}
											gridList={gridList}
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "staff" ? (
										<NewStaffForm
											modalConfig={modalConfig}
											methods={methods}
											table={table}
										/>
									) : gridList.gridPage === "users" ? (
										<NewRoleModal
											methods={methods}
											table={table}
											modalConfig={modalConfig}
											type="users"
										/>
									) : gridList.gridPage === "userTypes" ? (
										<NewRoleModal
											methods={methods}
											table={table}
											type="userTypes"
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "policies" ? (
										<NewRoleModal
											methods={methods}
											table={table}
											type="policies"
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "macro" ? (
										<NewMacroModal
											methods={methods}
											table={table}
											context="macro"
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "paymentMethod" ? (
										<NewMacroModal
											methods={methods}
											table={table}
											context="paymentMethod"
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "analysisCategory" ? (
										<NewMacroModal
											methods={methods}
											table={table}
											context="analysisCategory"
											modalConfig={modalConfig}
										/>
									) : gridList.gridPage === "sites" ? (
										<NewSiteForm
											methods={methods}
											table={table}
											context="site"
											modalConfig={modalConfig}
										/>
									) : (
										// : gridList.gridPage === "priceBand" ? (
										// 	<NewMacroModal
										// 		methods={methods}
										// 		table={table}
										// 		context="priceBand"
										// 		modalConfig={modalConfig}
										// 	/>
										// )
										<NewMacroModal
											methods={methods}
											table={table}
											context={gridList.gridPage}
											modalConfig={modalConfig}
										/>
									)}
								</Stack>

								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<Button
										variant="outlined"
										size="medium"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => methods.reset()}
									>
										Reset form
									</Button>
									<Button
										variant="gradient"
										size="medium"
										sx={{ alignSelf: "flex-end" }}
										type="submit"
									>
										Submit
									</Button>
								</Box>
							</Box>
						</Box>
					</form>
				</Modal>
			</FormProvider>
		</div>
	);
}

// const style = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	transform: "translate(-50%, -50%)",
// 	borderRadius: "10px",
// 	width: gridList.gridPage === 'users' ? 700 :410,
// 	maxHeight: "90vh",
// 	// height: "100%",
// 	// minHeight:
// 	// 	// 100 +
// 	// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
// 	bgcolor: "white",
// 	boxShadow: 24,
// 	// p: 4,
// 	overflow: "auto",
// 	display: "flex",
// 	justifyContent: "center",
// 	alignItems: "center",
// 	// padding: 0,
// 	gap: 2,
// 	flexDirection: "column",
// };

const style2 = {
	// position: "absolute",
	// top: "50%",
	// left: "50%",
	// transform: "translate(-50%, -50%)",
	// borderRadius: "20px",
	// width: 450,
	// maxHeight: "90vh",
	height: "100%",
	width: "100%",

	p: 4,
	overflow: "hidden",
	display: "flex",
	justifyContent: "flex-start",
	gap: 2,
	flexDirection: "column",
	/* hide scrollbar but allow scrolling */
	// "*::-webkit-scrollbar": {
	// 	width: "6px",
	// },
	// "*::-webkit-scrollbar-track": {
	// 	backgroundColor: "#f5f5f5",
	// },
	// "-webkit-scrollbar-thumb": {
	// 	borderRadius: "10px",
	// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
	// 	// backgroundColor: "#f5f5f5",
	// },

	// -ms-overflow-style: 'none', /* for Internet Explorer, Edge */
	// scrollbarWidth: "none" /* for Firefox */,
	overflowY: "scroll",
	// "&-ms-overflow-style:": {
	// 	display: "none", // Hide the scrollbar for IE
	// },
	// "&-webkit-scrollbar": {
	// 	width: "6px",
	// },
	// "&-webkit-scrollbar-track": {
	// 	backgroundColor: "#f5f5f5",
	// },
	// "&-webkit-scrollbar-thumb": {
	// 	borderRadius: "10px",
	// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
	// 	// backgroundColor: "#f5f5f5",
	// },

	"&::-webkit-scrollbar": {
		width: "6px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "#f5f5f5",
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// backgroundColor: "#f5f5f5",
	},
};
