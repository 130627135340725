import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Typography,
} from "@mui/material";
import React from "react";
import { Colours } from "../assets/global/Theme-variable";
import { DataGridContext } from "../context/DataGridContext";
import { useNavigate } from "react-router-dom";
import MainButton from "./Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CiBeerMugFull } from "react-icons/ci";

export default function IssueCard({ list, plu }) {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				width: "100%",
				height: 30,
				// aspectRatio: 3 / 1,
				// backgroundColor: "#DFF2F8",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				borderRadius: 0,
				borderBottom: `1px solid  #f0f0f0`,
				// ml: 2,
				mr: 2,
				padding: 0,
				// pl: 2,
			}}
			elevation={0}
		>
			<CardContent
				sx={{
					padding: 0,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{
						fontSize: 14,
						textAlign: "left",
						lineHeight: 1,
						userSelect: "none",
					}}
					color={Colours.darkGrey}
				>
					{list ? list?.list_name : plu ? plu.plu_name : ""}
				</Typography>
			</CardContent>
			<CardActions
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					justifySelf: "flex-end",
				}}
			>
				<IconButton
					aria-label="navigate to list"
					size="small"
					onClick={() =>
						navigate(
							list ? "/designer" : plu ? `/items/${plu.plu_number}` : null,
							{
								state: { item: list ? list : plu ? plu : null },
							}
						)
					}
				>
					<ArrowForwardIcon sx={{ color: Colours.darkGrey, fontSize: 16 }} />
				</IconButton>
			</CardActions>
		</Box>
	);
}
